import { Component, Input } from '@angular/core';
import { RouterService, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { UserServiceService } from 'src/app/views/profile/user-service.service';

@Component({
  selector: 'app-missing-company-info',
  templateUrl: './missing-company-info.component.html',
  styleUrls: ['./missing-company-info.component.css']
})
export class MissingCompanyInfoComponent {

  warn = getWarnColor;
  primary = getPrimaryColor;

  @Input({ required: true }) model!: string;
  @Input({ required: true }) pageLoaded: boolean = false;
  
  constructor(public userS: UserServiceService, private routerS: RouterService) { }
  
  goCompany() { this.routerS.goTo(ViewPath.business) }
  
  get showComponent(){
    return this.userS.missingCompanyInfo && this.pageLoaded
  }

}
