import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService, getAccentColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
  selector: 'app-try-pro-dialog',
  templateUrl: './try-pro-dialog.component.html',
  styleUrls: ['./try-pro-dialog.component.css']
})
export class TryProDialogComponent implements OnInit {
  accent = getAccentColor;
  warn = getWarnColor;
  v = ViewPath;
  constructor(public dialogRef: MatDialogRef<TryProDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | undefined,
    public routerS: RouterService) {
  }
  ngOnInit(): void { }
}
