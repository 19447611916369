<div class="df jcc container">
    <app-card [contentLoaded]="u != undefined">
        
        <div class="parent" *ngIf="u != undefined">
            <app-circular-letter [imageUpload]="true" [too] [checkIsMe]="false" [big]="true" [user]="u!"
                [showFullName]="false" (onUploadImage)="uploadImage($event)"></app-circular-letter>
            <p class="fsl fw400 mt10" style="margin-top: 5px">{{u.name}}</p>
            <p>{{u.email}}</p>
            
            
            <button style="margin-top: 10px;" mat-stroked-button (click)="logOut()">
                Cerrar sesión
            </button>
            <div class="codeEmp">
            <p class="c_t2 p10">Código Empresa: <span >{{u.company_code}}</span></p> 
            </div>
        </div>
       
    </app-card>
    <app-card>
        <div>
            <app-card-title>Cambiar contraseña</app-card-title>
            <form>
                <app-password-input [label]="'Contraseña'" #pass [form]="form" formCName="pass1"></app-password-input>
                <app-repeat-passowrd-input [label]="'Repite la contraseña'" #pass2 class_="mt-20" [otherPass]="pass" [form]="form" formCName="pass2">
                </app-repeat-passowrd-input>
                <div class="df jcc">
                    <button color="warn" style="margin-top: 20px;" mat-flat-button
                        (click)="updatePass(pass.value)" [disabled]="pass2.getDisabled() || passChanged">
                        Cambiar contraseña
                    </button>
                </div>
            </form>
        </div>
    </app-card>
</div>


<!-- TRANSALTION EXAMPLE 
<div class="df jcc container">
    <app-card [contentLoaded]="u != undefined">
        <div class="parent" *ngIf="u != undefined">
            <app-circular-letter [imageUpload]="true" [checkIsMe]="false" [big]="true" [user]="u!"
                [showFullName]="false" (onUploadImage)="uploadImage($event)"></app-circular-letter>
            <p class="fsl fw400 mt10" style="margin-top: 5px">{{u.name}}</p>
            <p>{{u.email}}</p>
            <div *ngIf="feature.translations" class="df jcc fdc aic">
                <p class="c_r">{{ 'test' | translate}}</p>
                <app-language-selector *ngIf="feature.translations"></app-language-selector>
            </div>
            <button style="margin-top: 10px;" mat-stroked-button (click)="logOut()">
                {{ 'views.profile.logout' | translate }}
            </button>
        </div>
    </app-card>
    <app-card>
        <div>
            <app-card-title>{{ 'views.profile.change.password' | translate }}</app-card-title>
            <form>
                <app-password-input [label]="'general.password' | translate" #pass [form]="form" formCName="pass1"></app-password-input>
                <app-repeat-passowrd-input [label]="'general.repeat.password' | translate" #pass2 class_="mt-20" [otherPass]="pass" [form]="form" formCName="pass2">
                </app-repeat-passowrd-input>
                <div class="df jcc">
                    <button class="bc_s c_p" style="margin-top: 20px;" mat-raised-button
                        (click)="changePassword(pass.value)" [disabled]="pass2.getDisabled() || passChanged">
                        {{ 'views.profile.edit.password' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </app-card>
</div> -->