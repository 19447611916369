import { Component, Input, OnInit } from '@angular/core';
import { getAccentColor } from '@sinigual/angular-lib';
import { user_opinion_1, user_opinion_2, user_opinion_3 } from 'src/app/core/custom-classes/UserOpinion';

@Component({
  selector: 'app-trusted-users',
  templateUrl: './trusted-users.component.html',
  styleUrls: ['./trusted-users.component.css']
})
export class TrustedUsersComponent implements OnInit {

  accent = getAccentColor;

  @Input() isLandingPage = false;

  opinion1 = user_opinion_1;
  opinion2 = user_opinion_2;
  opinion3 = user_opinion_3;
  
  constructor() { }

  ngOnInit(): void {
  }

}
