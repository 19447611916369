import { Component, Input } from '@angular/core';
import { CustomFile, Endpoint, FileType, isMobile } from '@sinigual/angular-lib';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { DataUploadDialogComponent } from './data-upload-dialog/data-upload-dialog.component';

@Component({
  selector: 'app-data-upload-item',
  templateUrl: './data-upload-item.component.html',
  styleUrls: ['./data-upload-item.component.css']
})
export class DataUploadItemComponent {
  ft = FileType;
  @Input() title!: string;
  @Input() expectetFields: string[] = [];
  @Input() expectetResponse: string[] = [];
  @Input() formatNumber: boolean[] = [];
  @Input() layoutURL!: string;
  @Input() endpoint!: Endpoint;
  @Input() class_!: string;
  @Input() importclass_!: string;
  loadingPreview: boolean = false;
  errors: string[] = [];
  data: any = undefined;
  mobile = isMobile();

  constructor(private d: MatDialog) { }

  onExcelUpload(f: CustomFile) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(f.file!);
    this.loadingPreview = true;
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary',
        cellDates: true,
        cellNF: true,
        cellText: false,
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = (XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: "", raw: false, dateNF: 'dd/MM/yyyy' }));
      this.autoLength();
      this.errors = this.checkErrorFormat();
      this.loadingPreview = false;
    };
  }

  /** If the user includes more empty colums at the end of the excel, remove it */
  autoLength() {
    if (this.data[0] && this.data[0].length > this.expectetFields.length) {
      var diff = this.data[0].length - this.expectetFields.length;
      for (let i = 0; i < this.data.length; i++) {
        this.data[i] = this.data[i].slice(0, this.data[i].length - diff);
      }
    }
  }

  checkErrorFormat(): string[] {
    this.clearErrors();
    let columns = this.getColumnsLength();
    if (this.data) {
      if (this.getEntries() <= 1) {
        this.addError("El documento no puede estar vacío")
      }
      else {
        if (columns != this.expectetFields.length) {
          this.addError("Inesperado número de columnas");
          this.addError("Se esperaban " + this.expectetFields.length + " columna y se han detectado " + columns)
        }
        if (true) {
          this.getColumns().forEach(c => {
            if (!this.expectetFields.includes(c)) {
              this.addError("Nombre de columa inesperado : '" + c + "'")
            }
          })
        }
      }
    }
    return this.errors;
  }

  getColumns(): [] {
    if (this.data && Array.isArray(this.data) && this.data.length >= 1) {
      return this.data[0]; // <-- column length
    }
    return [];
  }

  getEntries() {
    if (this.data && Array.isArray(this.data)) {
      return this.data.length;
    }
    return 0;
  }

  /** Heade names length like ... -> [""DNI*", "Nombre*", "Apellidos*","Email"...] */
  getColumnsLength(): number {
    return this.getColumns().length;
  }

  addError(error: string) { this.errors.push(error); }
  clearErrors() { this.errors = [] }
  hasErrors() { return this.errors.length != 0; }

  resetCompoenent() {
    this.loadingPreview = false;
    this.clearErrors();
    this.data = undefined;
  }

  openDialog() {
    var dRef = this.d.open(DataUploadDialogComponent, { data: { endpoint: this.endpoint, values: this.data, expectetResponse: this.expectetResponse }, disableClose: true })
    /** On close the dialog, reset this component */
    dRef.afterClosed().subscribe(value => {
      if (value) {
        this.resetCompoenent();
      }
    })
  }

  isFormatNumber(i: number) {
    if (this.formatNumber[i] != undefined){
      return this.formatNumber[i];
    }
    return false;
  }

  formatVal(val: string, i: number) {
    if (val.isNumber() && this.isFormatNumber(i)) {
      return new Intl.NumberFormat('es-Es').format(val.getNumber());
    }
    return val;
  }
}
