<div class="item pd_di">

    <!-- AQUI FORMULARIO PARA CREAR CONCEPTO I IMPORTE  -->
    <form [formGroup]="form" class="df fdc">
        <app-card-subtitle [first]="true">Nuevo anticipo</app-card-subtitle>
        <mat-form-field [appearance]="'outline'" class="pricehour">
            <mat-label>Tipo de cobro</mat-label>

            <!-- <mat-select  #selectedValue formControlName="payment_id" (selectionChange)="stateChange($event)">
                    <mat-option *ngFor="let payment of typePayment" [value]="payment.id">
                       
                    </mat-option>
                    <mat-option   [value]="'personalizado'">Personalizado</mat-option>
                </mat-select> -->

            <mat-select #selectedValue formControlName="type_payment_id" (selectionChange)="stateChange($event)">
                <mat-select-trigger>
                    {{ selectedPayment ? selectedPayment.payment : 'Personalizado'}}
                </mat-select-trigger>
                <mat-option *ngFor="let payment of data.typePayment" [value]="payment.id">
                    <p class="nmb df aic">{{payment.payment}}
                        <mat-icon prevent *ngIf="payment.company_id != 0" class="vam mla delete_style"
                            (click)="deleteTypePayment(payment)">delete</mat-icon>
                    </p>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Importe</mat-label>
            <input formControlName="import" [min]="0.01" matInput type="number"
                DecimalInput>
            <mat-error *ngIf="form.get('import')?.hasError('error_import')">El importe ingresado supera el saldo pendiente por pagar.</mat-error>
            <mat-error *ngIf="this.form.get('import')?.hasError('min')">El valor debe ser mayor que 0.01</mat-error>
        </mat-form-field>

    </form>
    <!-- AQUI FORMULARIO PARA CREAR CONCEPTO I IMPORTE  -->
    <div mat-dialog-actions align="center">
        <button [mat-dialog-close]="false" mat-button>Cancelar</button>
        <button *ngIf="!this.data.editLastMovement" [disabled]="!form.valid" color="warn" mat-flat-button
            (click)="updateForm()">Guardar</button>
        <button *ngIf="this.data.editLastMovement" [disabled]="!form.valid" color="warn" mat-flat-button
            (click)="editForm()">Editar</button>
    </div>

</div>