<div class="df fdc jcfs">
    <div *ngIf="classSearcher.selectedText">
        <span>{{classSearcher.selectedText}}</span>
    </div>
    <div class="df aic jcc">
        <div *ngIf="classSearcher.selected" class="df aic" >
            <div>
                <div class="ribbon" *ngIf="classSearcher.selected?.discount && displayDiscount">
                    <span>{{classSearcher.selected.discount}}% DTO</span>
                </div>
                <app-client-info [client]="classSearcher.selected"></app-client-info>
            </div>
        </div>
        <div *ngIf="!classSearcher.selected">
            <p class="c_t2">{{classSearcher.noSelectionPlaceHolder}}</p>
        </div>
    </div>
</div>