<div class="advanced-details-container" [ngClass]="{'dn_i' : missingComponent.showComponent}">
    <div class="title" style="grid-area: title;">
        <app-view-title>
            CREAR FACTURA <span class="c_t2" *ngIf="recoveredDraft"> (BORRADOR) </span>
        </app-view-title>
    </div>


    <div class="client">
        <app-card [contentLoaded]="contentLoaded">
            <!-- CLIENTE -->
            <app-card-title>Cliente</app-card-title>
            <app-client-searcher-header [classSearcher]="csearcher"></app-client-searcher-header>

        </app-card>
    </div>


    <div class="products">
        <div>
            <app-card class="floating-action-bar mb20" [locked]="csearcher == undefined"
                [lockedText]="'Crea un presupuesto para poder realizar acciones'" [contentLoaded]="contentLoaded"
                [cornerMatMenu]="deleteButton">
                <div class="df jcc">
                    <div class="mr10">
                        <button class="onboarding-invoice-finish" appTrialbutton mat-flat-button color="warn" (click)="createBill()">Crear
                            factura</button>
                    </div>


                </div>
                <ng-template #deleteButton>
                    <button mat-icon-button class="mt10"
                        [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #deleteMenu="matMenu">
                        <ng-template></ng-template>
                        <button mat-menu-item [disabled]="!csearcher" (click)="disacrdDraft()">
                            <mat-icon>delete</mat-icon> Eliminar
                        </button>
                    </mat-menu>
                </ng-template>
                <ng-template #closedBudget>
                    <button mat-button>
                        <mat-icon class="c_p">done</mat-icon>
                        Cerrado
                    </button>
                </ng-template>
            </app-card>

        </div>
        <app-card [contentLoaded]="contentLoaded" class="pSection mt10">
            <!-- PRODUCTOS -->
            <app-card-title>Productos</app-card-title>
            <div #container>
                <app-products-line-header *ngIf="currentBill?.hasProducts()"></app-products-line-header>
                <!--Products container-->
            </div>
            <div class="table-line-bottom-acations bill">
                <button mat-stroked-button (click)="appendProduct(undefined, false, false, true)" color="warn"
                    class="onboarding-invoice-add-products">
                    <mat-icon [svgIcon]="'barcode'" class="c_r"></mat-icon>
                    Añadir producto
                </button>
                <button mat-stroked-button (click)="newCustomProduct()" color="accent"
                    class="onboarding-invoice-add-products-custom">
                    <mat-icon [svgIcon]="'barcode'" class="c_a"></mat-icon>
                    Personalizado
                </button>
            </div>
            <mat-divider></mat-divider>
            <span>{{refreshBillCalculations()}}</span>
            <table class="breakdown no-pointer" *ngIf="currentBill && currentBill.getTotalBreakdown() as totals">
                <tr>
                    <td class="fw400 total">
                        Subtotal
                        <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
                    </td>
                    <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
                </tr>
                <tr *ngFor="let row of totals.iva_rows">
                    <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
                    <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
                    <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
                </tr>
                <!-- change iva code-->
                <!--
                    <tr>
                        <td class="fsm fw500 total">
                        <span class="c_p fsxs mr5 cp" (click)="openChangeIva()">Modificar</span> IVA ({{iva.value}}%) :
                        </td>
                        <td><span class="c_p fw400">{{bc.iva}} €</span></td>
                    </tr>
                -->
                <tr>
                    <td class="fsm fw500 total">Total</td>
                    <td><span class="c_p fw600" money [val]="totals.total"></span></td>
                </tr>
            </table>


        </app-card>
    </div>
    <div class="ig onboarding-invoice-selec-user">
        <!-- INFORMACIÓN GENERAL -->
        <app-card [contentLoaded]="contentLoaded">
            <app-card-title>Información general</app-card-title>
            <app-class-searcher [showLeftNumber]="false" #csearcher class="onboarding-invoice-select-client"
                [masterClass]="client" [width100]="true" searchPlaceHolder="Buscar cliente"
                [specialRow]="'Nuevo cliente / empresa'" (onSpecialRow)="goCreateClient()" [extendedInfo]="true"
                (onSelect)="attachClient($event)"
                [required]="currentBill != undefined && currentBill.getTotalBreakdown().total >= 400">
            </app-class-searcher>
            <form [formGroup]="generalInfo">
                <div>
                    <app-simple-input class="w100" #observations (focusout)="updateGeneralInfo()"
                        [label]="'Comentarios'" [requerido]="false" [textArea]="true" [form]="generalInfo"
                        [formCName]="'comments'">
                    </app-simple-input>
                </div>
            </form>

        </app-card>
    </div>
</div>


<!-- MISSING COMPANY INFO COMPONENT-->
<app-missing-company-info #missingComponent [pageLoaded]="contentLoaded"
    [model]="'facturas'"></app-missing-company-info>