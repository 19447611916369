<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Pendiente de cobrar</p>

<div mat-dialog-content>
    <div *ngIf="data.paymentPendingArray.length; else noData">
        <div *ngFor="let invoice of data.paymentPendingArray" class="clickable-item" (click)="goInvoice(invoice)">
            <p class="nmb">
                <span class="fw500 c_r">{{invoice.company_scope_id}} </span>
                <span class="c_t2 ml5 mr5">| </span>
                <!-- To do : Refactor totals -->
                <span class="c_p" money [val]="invoice.total"></span>
                <mat-icon class="vam c_t2">chevron_right</mat-icon>
            </p>
            <p class="fss c_t2">{{invoice.created_at?.dayMonthYearFormat}}</p>
        </div>
    </div>
    <ng-template #noData>
        <p *ngIf="!data.orPendingArray.length" class="c_t2">Sin facturas pendientes de cobrar</p>
    </ng-template>
</div>