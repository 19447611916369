<app-page-structure [masterClass]="'budget'" pageTitle="PRESUPUESTOS" [autocompleteID]="'budget'"
    searchLabel="Buscar presupuesto" circleIcon="budget_add" circleIconClass="onboarding-create-budget"
    [addNewView]="v.createEditBudget" noDataCreateNew="Crear nuevo presupuesto" [data]="[]"
    [displayedHeader]=" ['Número',          'Cliente',      'Fecha creación',     'Total',          'Estado',       'Tipo']"
    [displayedColumns]="['title_id',          'client',       'created_at',         'total',          'status',       'type']"
    [specialText]="     [undefined,     client,         undefined,            'money',            undefined,      undefined]"
    [specialClass]="    [undefined,     undefined,      undefined,            undefined,        undefined,      undefined]"
    [card]="card" [filters]="filters" [lordIcon]="lordIcon" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()">
    <ng-template #lordIcon>
        <script src="https://cdn.lordicon.com/xdjxvujz.js"></script>
        <lord-icon src="https://cdn.lordicon.com/ajcfyhfk.json" trigger="in"
            colors="primary:#121331,secondary:#ebe6ef,tertiary:{{warn()}},quaternary:{{accent()}}"
            class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <button [matTooltip]="'Ir al presupuesto'" mat-flat-button color="warn"
                (click)="handleClick($event)" (contextmenu)="handleContextMenu($event)">
                Presupuesto Nº {{ps.selected!.title_id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>
            <button *ngIf="ps.selected!.groups.length" mat-stroked-button style="margin-top: 10px;" (click)="previsualize(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Vista previa
            </button>

            <app-card-subtitle>Cliente y vehículo</app-card-subtitle>
            <app-vehicle-row [item]="ps.selected!.vehicle!"></app-vehicle-row>
            <app-client-row [item]="ps.selected!.client!"></app-client-row>

            <app-card-footer>
                <app-whatsapp [phone]="ps.selected!.client?.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.client?.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.client?.phone"></app-phone-button>
            </app-card-footer>

            <div>
                <app-layout-send-email *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted"
                    [temaplte]="'budget'" [endpoint]="reSendBudget.bind(this)"
                    [disabled]="ps.selected.client.email == undefined"></app-layout-send-email>
            </div>

        </app-card>
    </ng-template>
</app-page-structure>