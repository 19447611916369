import { Component } from '@angular/core';

@Component({
  selector: 'app-demo-video',
  templateUrl: './demo-video.component.html',
  styleUrls: ['./demo-video.component.css']
})
export class DemoVideoComponent {

}
