<form [formGroup]="form">
    <div class="form">
        <app-card-subtitle>Datos obligatorios</app-card-subtitle>
        <div class="onboarding-client-required">
            <div class="df">
                <mat-select-country class="w66" [label]="'Seleccionar país'" [placeHolder]="'Seleccionar país'"
                    [required]="true" [formControlName]="'country'" (onCountrySelected)="onCountrySelected($event)">
                </mat-select-country>
                <div class="w33 df jcc aic p10 bsbb">
                    <mat-slide-toggle formControlName="tax_free"
                        [matTooltip]="responsiveS.w < 900 ? 'Por defecto en los clientes fuera de España' : ''">
                        <p class="nmb">Exento IVA</p>
                        <mat-hint *ngIf="responsiveS.w >= 900">Por defecto en los clientes fuera de España</mat-hint>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="df fww">
                <!--
                <app-dni-input [strict]="false" (onFocusOut)="checkDNI()"
                    [label]="ch_company.checked ? 'CIF / NIF' : 'DNI / NIF'"
                    [placeHolder]="ch_company.checked ? 'Introduce el CIF / NIF' : 'Introduce el DNI / NIF'" class="w66"
                    [formCName]="'nif'" [form]="form"
                    [error]="isCompany? ' ' : 'El DNI / NIF introducido no es correcto'">
                </app-dni-input>
                -->

                <mat-form-field class="w66"  appearance="outline" color="primary">
                    <mat-label>{{getIdentifierPlaceholder}}</mat-label>
                    <input matInput formControlName="nif" (blur)="onBlur($event)" [disabled]="!isEditing">
                    <button *ngIf="edit" mat-icon-button matSuffix type="button"  (click)="enableEditing()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('nif')?.hasError('nif-exists')">
                        El NIF ya existe.
                      </mat-error>
                </mat-form-field>

                <div class="w33 df jcc aic p10 bsbb">
                    <mat-slide-toggle (change)="updateNifValidators()" #ch_company formControlName="is_company"
                        [matTooltip]="responsiveS.w < 900 ? 'Determina si el cliente es una empresa' : ''">
                        <p class="nmb">Empresa</p>
                        <mat-hint *ngIf="responsiveS.w >= 900">Determina si el cliente es una empresa</mat-hint>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="df fww">
                <mat-form-field [ngClass]="ch_company.checked ? 'w66' : 'w50'" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Nombre" formControlName="name">
                </mat-form-field>
                <mat-form-field [ngClass]="ch_company.checked ? 'dn' : 'w50'" appearance="outline">
                    <mat-label>Apellidos</mat-label>
                    <input matInput placeholder="Apellidos" formControlName="surname">
                </mat-form-field>
            </div>
        </div>
        <app-card-subtitle>Datos opcionales</app-card-subtitle>

        <div class="df">
            <app-email-input class="w66" [formCName]="'email'" [form]="form" [required]="toggle.checked">
            </app-email-input>
            <div class="w33 df jcc aic p10 bsbb">
                <mat-slide-toggle #toggle formControlName="email_always">
                    <p class="nmb">Recibir facturas</p>
                </mat-slide-toggle>
            </div>
        </div>
        <div class="df">
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Nombre y número de la calle" formControlName="address">
            </mat-form-field>
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Complemento dirección</mat-label>
                <input matInput placeholder="Apartamento, suite, unidad, edificio o piso" formControlName="address2">
            </mat-form-field>
        </div>
        <div class="w33p">
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Código Postal</mat-label>
                <input matInput placeholder="Código Postal" formControlName="zip">
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Población</mat-label>
                <mat-select #selectedValue formControlName="city" (selectionChange)="stateChange($event)" [disabled]="!this.form.get('zip')?.value?.length">
                    <!-- <mat-select-trigger>
                        {{ selectedPayment ? selectedPayment.poblacio : form.get('city')?.value}}
                      </mat-select-trigger> -->
                    <mat-option *ngFor="let zip of this.arrayZips" [value]="zip.id">
                        <p class="nmb df aic">{{zip.poblacio}}
                        </p>
                    </mat-option>
                  </mat-select>
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Provincia</mat-label>
                <input matInput placeholder="Provincia" formControlName="province">
            </mat-form-field>
        </div>
        <div class="w33p">
            <app-phone-input class="w33" [formCName]="'phone'" [form]="form" [required]="false"></app-phone-input>
            <app-phone-input class="w33" [label]="'Teléfono 2'" [placeHolder]="'Teléfono 2'" [name]
                [formCName]="'phone2'" [form]="form" [required]="false"></app-phone-input>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Fecha de nacimiento</mat-label>

                <!-- On change update picker input -->
                <input #pickerInput matInput [matDatepicker]="picker" [max]="today" placeholder="dd/mm/aaaa"
                    formControlName="dob">

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="showDateHint">Fecha incorrecta. El valor no se guardará</mat-hint>
            </mat-form-field>
        </div>
        <div class="df">
            <app-simple-input class="w100" #observations [label]="'Observaciones'" [requerido]="false" [textArea]="true"
                [form]="form" [formCName]="'observations'"></app-simple-input>
        </div>

        <app-card-subtitle>Descuento cliente</app-card-subtitle>
        <p>El descuento para el cliente se aplicará en las facturas de recambios.</p>
        <mat-form-field class="w33" appearance="outline">
            <mat-label>Descuento</mat-label>
            <input #input type="number" matInput placeholder="5%, 10% ,20%, 30%, 40% .." formControlName="discount"
                type="number" (keypress)="decimalFilter($event)">
            <mat-icon matSuffix>percent</mat-icon>
            <mat-error *ngIf="form.get('discount')?.touched && form.get('discount')?.errors">Valor máx. :
                100</mat-error>
        </mat-form-field>
        <!--
        <app-card-subtitle>Consentimentos del cliente</app-card-subtitle>
        <p>Recuerda que es obligatorio disponer del documento firmado conforme el cliente otorga el consentimiento
            explícito</p>
        <div class="consents">
            <mat-checkbox formControlName="marketing">Marketing</mat-checkbox>
            <mat-checkbox formControlName="profile">Perfil</mat-checkbox>
            <mat-checkbox formControlName="newsletter">Newslatter</mat-checkbox>
        </div>-->
        <div class="m10"></div>
        <div class="dg">
            <div class="ml10 df bsbb">
                <mat-slide-toggle #toggle2 formControlName="cli_is_invoice_e">
                    <p class="nmb">Factura electrónica</p>
                </mat-slide-toggle>
            </div>
            <div class="m10"></div>
            <div class="w33p"  
            [formGroup]="getClientAdminCenter(i)" [ngClass]="toggle2.checked ? 'df' : 'dn'"
             
                style="gap: 0.2rem;" *ngFor="let cadmincenter of subForm.controls; let i = index">
                <div>
                    <mat-form-field class="" appearance="outline">
                        <mat-label>Rol</mat-label>
                        <input matInput placeholder="Rol" formControlName="post">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="" appearance="outline">
                        <mat-label>Código</mat-label>
                        <input matInput placeholder="Código" formControlName="code">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <input matInput placeholder="Descripción" formControlName="name2">
                    </mat-form-field>
                </div>
                <div class="df aic jcc">
                    <div *ngIf="getClientAdminCenter(i).value.id" class="df">
                        <button (click)="changeClientAdmincenter(getClientAdminCenter(i).value)" type="button" mat-menu-item matTooltip="Modificar">
                            <mat-icon>update</mat-icon>
                        </button>
                        <button (click)="removeAdminCenter(getClientAdminCenter(i).value.id)" type="button" mat-menu-item matTooltip="Eliminar">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!getClientAdminCenter(i).value.id">
                        <button (click)="createClientAdmincenter(getClientAdminCenter(i).value); changeIcon()" type="button" mat-menu-item>
                            <mat-icon *ngIf="iconName === 'add'" [matTooltip]="'Agregar'">add</mat-icon>
                            <mat-icon *ngIf="iconName === 'check'" [matTooltip]="'Agregado'">check</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div [ngClass]="toggle2.checked ? 'df aic jcc' : 'dn'">
                <button (click)="pushNewAdminCenter(undefined)" mat-menu-item >
                    <mat-icon style="font-size: xx-large; width: fit-content;" matTooltip="Agregar centros administrativos">fiber_new</mat-icon>
                </button>
            </div>

        </div>

    </div>
</form>