<div class="df aic jcc mb20 mt15">
    <div>
        <app-view-title>{{isLandingPage ? 'Con la confianza de nuestros clientes' : 'Opinión de nuestros clientes'}}</app-view-title>
    </div>
    <div *ngIf="!isLandingPage">
        <lord-icon src="https://cdn.lordicon.com/zqlhniux.json" trigger="loop" delay="9999" class="lord-small"
        colors="primary:#121331,secondary:#ffc738,tertiary:#f9c9c0,quaternary:{{accent()}}">
        </lord-icon>
    </div>
</div>

<div class="trusted-users">
    <app-trusted-user [userOpinion]="opinion1"></app-trusted-user>
    <app-trusted-user [userOpinion]="opinion2"></app-trusted-user>
    <app-trusted-user [userOpinion]="opinion3"></app-trusted-user>
</div>