import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Views } from '@sinigual/angular-lib';
import { ParamsService } from './params.service';

export interface AlreadyExistInterface {
  title: string;
  message: string;
  message2: string;
  value: string;
  view: Views | undefined;
  param_id: number | undefined;
  accept_text: string;
}

@Injectable({
  providedIn: "root"
})

@Component({
  selector: 'already-exist-dialog',
  template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
      <p>{{data.message}} <span class="fw500">{{data.value}}</span></p>
    </div>
    <div mat-dialog-actions style="justify-content: center;">
      <button mat-button [mat-dialog-close]="false">Cancelar</button>
      <button color="warn" mat-flat-button [mat-dialog-close]="true" (click)="onOk()">{{data.accept_text}}</button>
    </div>`
})
export class AlreadyExistsService {
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: AlreadyExistInterface, private paramS: ParamsService) { }

  /**Muestra un diálogo con el error HTTP */
  show(data: AlreadyExistInterface) {
    return this.dialog.open(AlreadyExistsService, { data, autoFocus: false });
  }

  onOk() {
    if (this.data.view && this.data.param_id) {
      this.paramS.go(this.data.view, this.data.param_id)
    }
  }

  ngOnInit(): void { }

}
