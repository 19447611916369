import { Component, Inject } from '@angular/core';
import { AddProductComponent } from '../add-product.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateProductComponent, I_ProductEdit } from 'src/app/views/create-product/create-product.component';
import { M_Product } from 'src/app/core/models/M_Product';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { CreateCustomProductComponent } from '../create-custom-product/create-custom-product.component';
import { CustomProductData } from 'src/app/core/interfaces/CustomProductData';
import { DragGroupComponent } from 'src/app/core/components/drag/drag-group/drag-group.component';
import { CreateBillComponent } from '../../create-bill.component';
import { AddCommentComponent } from 'src/app/core/components/add-comment/add-comment.component';

@Component({
  selector: 'app-product-line-title',
  templateUrl: './product-line-title.component.html',
  styleUrls: ['./product-line-title.component.css']
})
export class ProductLineTitleComponent {
  constructor(@Inject(AddProductComponent) public parent: AddProductComponent, private d: MatDialog) { }
  editProductDialog() {

    const product = this.parent.p;

    if (!this.parent.canModify || product == undefined) { return; }

    /** Product */
    if (product instanceof M_Product) {
      console.log("here!");
      this.d.open<CreateProductComponent, I_ProductEdit>(CreateProductComponent, {
        data: {
          product: product,
          action_id : this.parent.parent instanceof DragGroupComponent ? this.parent.parent.group.action_id : undefined,
          draft_id : this.parent.parent instanceof CreateBillComponent ? this.parent.parent.currentBill?.id : undefined
        }, autoFocus: false
      }).afterClosed().subscribe(res => {
        if (res instanceof M_Product) {
          this.setNewProductOnParent(res, product);
        }
      });
    }
    else if (product instanceof M_CustomProduct) {
      /** Custom Porduct */
      console.log(product);
      if (product.type == 'customproduct') {

        this.d.open<CreateCustomProductComponent, CustomProductData>
          (CreateCustomProductComponent,
            {
              autoFocus: false,
              data: {
                inputValue: undefined,
                interno: this.parent.isInterno,
                product: product,
              }
            }).afterClosed().subscribe(res => {
              if (res instanceof M_CustomProduct) {
                this.setNewProductOnParent(res, product);
              }
            });
      }
      else if(product.type == "comment"){
        this.parent.addRemove?.openCommentDialog();
      }
      /** Time */
      else {
        this.parent.addRemove?.openTimeDialog();
      }
    }
  }

  setNewProductOnParent(dialogPorduct: M_Product | M_CustomProduct, beforeProduct: M_Product | M_CustomProduct) {

    /** We put the previous quantity because it cannot be modified in the 'edit product'*/
    if (dialogPorduct instanceof M_Product) { dialogPorduct.quantity = beforeProduct.quantity; }
    /** We put the previous discount because it cannot be modified in the dialogues */
    dialogPorduct.discount = beforeProduct.discount;

    /** Sync the product on the screen */
    if (dialogPorduct instanceof M_Product) {
      this.parent.syncAllRealProducts(dialogPorduct);
    }
    else {
      this.parent.syncCustomProducts(dialogPorduct);
    }
  }
}