import { VehicleType } from "../enums/VehicleType";

export class M_CompanyWelcome {
    name: string;
    places: number;
    default_vehicle: VehicleType;
    constructor(name: string, places: number, default_vehicle: VehicleType) {
        this.name = name;
        this.places = places;
        this.default_vehicle = default_vehicle;
    }
}