import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { UserformComponent } from 'src/app/core/components/userform/userform.component';
import { M_User } from 'src/app/core/models/M_User';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  @ViewChild("form") form! : UserformComponent;

  constructor(private apiS : ApiService, private formS : FormService, public dialogRef: MatDialogRef<UserformComponent>) { }

  ngOnInit(): void {
  }

  create() {
    let u = new M_User(this.form.form.value);
    if (this.formS.isOk(this.form.form)){
      this.apiS.createUser(u).then(_resp =>{
        this.dialogRef.close(true);
      })
    }
  }
}
