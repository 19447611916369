import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { HARDCODED_TAX, max_facturable_product, max_price, min_price } from 'src/app/core/constants/constants';
import { CustomProductData } from 'src/app/core/interfaces/CustomProductData';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';


@Component({
  selector: 'app-create-custom-product',
  templateUrl: './create-custom-product.component.html',
  styleUrls: ['./create-custom-product.component.css']
})
export class CreateCustomProductComponent implements OnInit {
  custom: M_CustomProduct | undefined;
  form: UntypedFormGroup;
  isEdit = false;
  v = ViewPath;

  constructor(public routerS: RouterService, private fs: FormService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateCustomProductComponent>, @Inject(MAT_DIALOG_DATA) public data: CustomProductData) {
    this.dialogRef.disableClose = true;
    this.form = this.fb.group({
      name: ['', Validators.required],
      price: ['', [Validators.required, Validators.min(min_price), Validators.max(max_price)]],
      buy_price: ['', [Validators.min(0), Validators.max(max_price)]],
      quantity: ['', [Validators.min(0), Validators.max(max_facturable_product)]],
      reference: [''],
      tax: [21, Validators.required]
    })

    if (this.data.product) {
      this.isEdit = true
      console.log(this.data);
      this.form.patchValue(this.data.product);
    };

    if (this.data.interno){
      this.form.get('buy_price')?.addValidators(Validators.required);
    }

  }

  ngOnInit(): void {
    if (this.data.inputValue) {
      this.form.patchValue({ name: this.data.inputValue })
    }
  }

  create() {
    console.log(this.form.value);
    if (this.fs.isOk(this.form)) {
      let c = new M_CustomProduct(this.form.value);
      c.tax = this.form.get('tax')?.value;
      if (!c.quantity) { c.setQuantity(1); }
      if (this.data.product) { c.line_id = this.data.product.line_id; }
      this.dialogRef.close(c);
    }
  }

}
