import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogService, CustomDate, Endpoint, SnackService, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';

class DataImportResult {
  constructor(public success: string[] = [], public fail: string[] = []) { }

  get hasErrors() {
    return this.fail.length != 0;
  }

  get hasSucces() {
    return this.success.length != 0;
  }

  get isEmpty() {
    return !this.hasSucces && !this.hasErrors;
  }
}

@Component({
  selector: 'app-data-upload-dialog',
  templateUrl: './data-upload-dialog.component.html',
  styleUrls: ['./data-upload-dialog.component.css']
})
export class DataUploadDialogComponent implements OnInit {

  primary = getPrimaryColor;
  warn = getWarnColor;
  
  public loaded = false;
  result: DataImportResult | undefined;
  constructor(public dialogRef: MatDialogRef<DataUploadDialogComponent>, @Inject(MAT_DIALOG_DATA)
  public data: { endpoint: Endpoint, values: any, expectetResponse: string[]}, private snackS: SnackService,
    private http: HttpClient, private chdRef: ChangeDetectorRef, private confirmD : ConfirmDialogService) {
    this.getAndProcessData();
  }

  ngOnInit(): void { }

  copyClippboard() {
    if (this.result) {
      navigator.clipboard.writeText(this.formatResultsString());
      this.snackS.show("Contenidio copiado en el portapapeles")
    }
  }

  formatResultsString(): string {
    if (this.result) {
      return "IMPORTACIONES CORRECTAS : \n" +
        (this.result.hasSucces ? this.formatResultArray(this.result.success) : "(Sin importacaiones correctas) \n") +
        "\n" +
        "ERRORES : " + "\n" +
        (this.result.hasErrors ? this.formatResultArray(this.result.fail) : "(Sin errores)")
    }
    return "";
  }

  formatResultArray(array: string[]) {
    let formatted = "";
    array.forEach(value => {
      formatted += "- " + value + "\n"
    })
    return formatted;
  }

  downloadFile() {
    if (this.result) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.formatResultsString()));
      element.setAttribute('download', new CustomDate().dayMonthYearFormat);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  getAndProcessData() {
    /** Once the dialog is open, make the upload data call */
    this.callEndpoint().then(res => {
      if (res != undefined) { //Response OK
        this.result = res;
        this.loaded = true;
        this.chdRef.detectChanges();
      }
      else { //Something goes wrong
        this.dialogRef.close(false);
        this.confirmD.showError("No se pudieron importar los datos", "No se obtuvo respuesta del servidor")
      }
    });
  }

  /** Get the response entry by the expected response array. By default 0 == 'succes', 1 == 'fail' */
  getEntry(index: number): string {
    if (this.data.expectetResponse.length == 0) {
      return index == 0 ? "success" : "error"
    }
    return this.data.expectetResponse[index];
  }

  callEndpoint() {
    return new Promise<DataImportResult | undefined>(resolve => {
      var names : string [] = this.data.values[0];
      for (let i =0; i< names.length; i++){
        names[i] = names[i].replace('*', '');
        names[i] = names[i].replace(' (dd/mm/aaaa)','')
        names[i] = names[i].replace(' (sport, touring, trail, scooter, offroad, custom, naked)', '')
        names[i] = names[i].replace(' (S/N)', '')
      }
      var dataSend = this.data.values.slice(1);
      this.http.post<any>(this.data.endpoint.url, { "names": names, "data": dataSend }).subscribe(
        data => {
          if (data) {
            resolve(new DataImportResult(data[this.getEntry(0)], (data[this.getEntry(1)])));
          }
          resolve(undefined)
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }
}
