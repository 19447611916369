<div [ngClass]="{'dn' : missingComponent.showComponent}">
    <div class="container" *ngIf="!actionFailedToLoad; else notfound">
        <!-- ACTION GENERAL INFO -->
        <div class="title" *ngIf="actionLoaded">
            <app-view-title *ngIf="action == undefined">CREAR PRESUPUESTO</app-view-title>
            <app-view-title *ngIf="action != undefined  && !action.isClosed()">MODIFICAR PRESUPUESTO</app-view-title>
            <app-go-back class="title" *ngIf="action != undefined && action.isClosed()" [text]="'Listado presupuestos'"
                [v]="v.budget" [show]="action != undefined"></app-go-back>
        </div>


        <div class="general_info">
            <app-card [contentLoaded]="actionLoaded" [locked]="!subS.hasProPermissions"
                [lockedText]="'Se requiere el plan PRO'">
                <div class="df jcsb fww">
                    <app-card-title>Información general</app-card-title>

                    <!-- DOCUMENTATION !-->
                    <app-documentation-pdf-menu *ngIf="action?.token" class="mla"
                        [action]="action"></app-documentation-pdf-menu>
                </div>

                <div *ngIf="action?.action_id" class="df">
                    <app-card-subtitle [first]="true">
                        <div class="df aic fww" style="line-height: 40px;">
                            {{action?.title_id}}
                            <app-or-status-label [action]="action" class="ml5" [showText]="false"
                                [status]="action? action.status : undefined"></app-or-status-label>
                            <app-or-type-label [wrapOn]="responsiveS.w < 400 ? 1 : 2" class="ml5" [showText]="false"
                                [tipo]="action? action.type : undefined"></app-or-type-label>
                        </div>
                    </app-card-subtitle>
                </div>
                <app-class-searcher class="w100 mla mra onboarding-budget-select-vehicle" #vehicleSearcher
                    [masterClass]="vehicle" searchPlaceHolder="Buscar vehículo o propietario" (onSelect)="createBudget()"
                    [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" [specialRow]="'Nuevo vehículo'"
                    (onSpecialRow)="routerS.goTo(v.createvehicle)" [required]="true"
                    [canRemove]="this.action == undefined">
                </app-class-searcher>
                <!-- CREATE BUTTON, NOT SHOW IF ACTIONA HAS ID-->
                <div class="cv-details">
                    <div class="df jcc">
                        <app-client-vehicle-in-dialog [t]="'Ver más información'"
                            [c]="vehicleSearcher.selected?.client "
                            [v]="vehicleSearcher.selected"></app-client-vehicle-in-dialog>
                    </div>
                </div>
            </app-card>
        </div>

        <div class="budget_vehicle">
            <app-card [contentLoaded]="actionLoaded">
                <app-vehicle-info [small]="true" [vehicle]="vehicleSearcher.selected" [extended]="false">
                </app-vehicle-info>
            </app-card>
        </div>

        <div class="budget_client">
            <app-card [contentLoaded]="actionLoaded">
                <div class="ribbon" *ngIf="vehicleSearcher.selected?.client?.discount">
                    <span>{{vehicleSearcher.selected?.client?.discount}}% DTO</span>
                </div>
                <app-client-info [small]="true" [client]="vehicleSearcher.selected?.client" [extended]="true">


                </app-client-info>
            </app-card>
        </div>
    </div>

    <div class="actions-and-tasks" [ngClass]="{'dn' : actionFailedToLoad}">
        <!-- ACTIONS -->
        <div class="actions floating-action-bar no-border">
            <app-card class="normal-padding-top" [locked]="action == undefined"
                [lockedText]="'Crea un presupuesto para poder realizar acciones'" [contentLoaded]="actionLoaded"
                [cornerMatMenu]="deleteButton">
                <div class="df jcc" style="gap: 10px;">
                    <div>
                        <div class="df jcc" style="gap: 10px;">
                            <button *ngIf="!isClosed()" appTrialbutton mat-raised-button
                                [disabled]="missingCompanyInfo || !drag.hasChanges()" (click)="saveBudget()"
                                color="warn">
                                Guardar
                            </button>
                            <button appTrialbutton mat-raised-button
                                [disabled]="missingCompanyInfo || !action?.isBudgetClosable()"
                                (click)="showTemplateFields()" color="orange" class="onboarding-budget-finish"
                                *ngIf="!isClosed()">
                                <mat-icon>lock</mat-icon>
                                Cerrar
                            </button>
                        </div>
                        <div class="mt10" *ngIf="missingCompanyInfo" >
                            <p class="tac c_r" (click)="goCompany()"
                                style="cursor: pointer;">
                                Faltan rellenar la información de empresa</p>
                        </div>
                    </div>


                    <ng-template #deleteButton>
                        <button mat-icon-button [disabled]="action == undefined"
                            *ngIf="!action && subS.hasProPermissions" class="mt10"
                            [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                        <button mat-icon-button *ngIf="action && subS.hasProPermissions"
                            [ngClass]="action && isClosed() ? 'close_budget':'open_budget'"
                            [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #deleteMenu="matMenu">
                            <ng-template></ng-template>
                            <button mat-menu-item (click)="openTransferTo('budget')"
                                [matTooltip]="'Se creará un presupuesto a partir de este'"
                                [matTooltipPosition]="'left'">
                                <mat-icon>request_quote</mat-icon>
                                Nuevo presupuesto
                            </button>
                            <button mat-menu-item [disabled]="action == undefined" (click)="deleteAction()">
                                <mat-icon>delete</mat-icon> Eliminar
                            </button>
                        </mat-menu>
                    </ng-template>
                    <div *ngIf="isClosed()">
                        <button appTrialbutton mat-raised-button [disabled]="!isClosed()" (click)="openTransferTo('or')"
                            color="primary" class="w100">
                            {{action?.has_or ? 'Generar otra OR' : 'Pasar a OR'}}
                        </button>
                    </div>
                </div>

                <!-- Budget info steps-->
                <ng-container *ngIf="!missingCompanyInfo">
                    <p *ngIf="action" class="nmb tac" style="margin-top: 5px;">
                        <mat-icon class="vam mr5" [ngClass]="action.has_or ? 'c_b' : 'c_b'">{{action.has_or ? 'build' : 'info'}}</mat-icon>
                        <span *ngIf="!action.hasGroups()">
                            Debes crear una intervención.
                        </span>
                        <span *ngIf="action.hasGroups() && !action.isBudgetClosable() && !isClosed() && !action.has_or">
                            Introduce productos o tiempos a presupuestar.
                        </span>
                        <span *ngIf="action.hasGroups() && action.isBudgetClosable() && !isClosed() && !action.has_or">
                            Al cerrar un presupuesto, los precios en él ya no variarán.
                        </span>
                        <span *ngIf="action.hasGroups() && isClosed() && !action.has_or">
                            Presupuesto cerrado.
                        </span>
                        <span class="c_p3 fw500 tac" *ngIf="action.has_or">
                            Ya se ha pasado a OR
                            <span class="ml5 c_b c_p underline_hover" (click)="goOr(action.has_or)">Ver</span>
                        </span>
                    </p>
                    <span *ngIf="action && !isClosed() && action.isBudgetClosable()" class="c_b fw500 tac fss">
                        Cierra el presupuesto para poder pasarlo a OR
                    </span>
    
                    <span *ngIf="action && action.hasGroups() && isClosed() && !action.has_or" class="c_b fw500 tac fss">
                        Ya puedes pasarlo a OR
                    </span>
                </ng-container>


            </app-card>
        </div>

        <!-- GROUP TASKS -->
        <div class="group_tasks" [ngClass]="{'dn' : actionFailedToLoad}">
            <app-card class="bc_b0" [locked]="action == undefined || subS.isBasic" [contentLoaded]="actionLoaded"
                [lockedText]="action == undefined ? 'Crea un presupuesto para poder asignar intervenciones' : subS.isBasic ? 'Se requieere el plan PRO' : ''">
                <app-card-title>Intervenciones</app-card-title>
                <app-drag #drag class="onboarding-budget-tasks" [action]="action" [isBudgetPage]="true" [locked]="subS.isBasic"
                    (onRemoveChildGroupTask)="deleteGroupTask($event)"></app-drag>
            </app-card>
        </div>

    </div>
</div>


<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el presupuesto'" [goText]="'Ir al listado de presupuestos'"
        [view]="v.budget">
    </app-model-not-found>
</ng-template>

<!-- MISSING COMPANY INFO COMPONENT-->
<app-missing-company-info #missingComponent [pageLoaded]="actionLoaded" [model]="'presupuestos'">
</app-missing-company-info>