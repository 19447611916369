import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ClassSearcherComponent, ConfirmDialogService, CustomDate, FormService, ParameterStateComponent, RouterService, SnackService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { AddProductInterface } from 'src/app/core/interfaces/AddProductParent';
import { M_Invoice as M_Invoice } from 'src/app/core/models/M_Invoice';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { M_Breakdown } from 'src/app/core/models/M_Breakdown';
import { M_Product } from 'src/app/core/models/M_Product';
import { UserServiceService } from '../profile/user-service.service';
import { AddProductComponent } from './add-product/add-product.component';
import { IvachangerComponent } from './ivachanger/ivachanger.component';
import { ViewPath } from 'src/app/app-routing.module';
import { MASTER_CLIENT_MINIFIY } from 'src/app/core/constants/masters';
import { PreviewService } from 'src/app/core/services/preview.service';
import { CreateInvoiceDialogComponent } from './create-invoice-dialog/create-invoice-dialog.component';
import { CreateCustomProductComponent } from './add-product/create-custom-product/create-custom-product.component';
import { calculateTotalsBreakdown } from 'src/app/core/services/totals-calculator.service';
import { CustomProductData } from 'src/app/core/interfaces/CustomProductData';
import { AddCommentComponent } from 'src/app/core/components/add-comment/add-comment.component';
import { M_Task } from 'src/app/core/models/M_Task';

@Component({
  selector: 'app-create-bill',
  templateUrl: './create-bill.component.html',
  styleUrls: ['./create-bill.component.css']
})
export class CreateBillComponent extends ParameterStateComponent implements OnInit, AddProductInterface {

  client = MASTER_CLIENT_MINIFIY;

  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild(ClassSearcherComponent) searchUser?: ClassSearcherComponent<M_Client>;
  e = endpoints;
  contentLoaded = false;
  v = ViewPath;
  productsComponent: AddProductComponent[] = [];
  public generalInfo: UntypedFormGroup;
  id = 0;
  iva: UntypedFormControl;
  currentBill: M_Invoice | undefined;
  bill: M_Invoice | undefined;
  recoveredDraft = false;
  onboardinSearchStepPassed = false;
  productIdOnLoad: number | undefined = undefined;
  updatingLine: Promise<any> | undefined;

  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService, routerS: RouterService, public userS: UserServiceService,
    private apiS: ApiService, private dialog: MatDialog,
    private snackS: SnackService, route: ActivatedRoute, private confirmD: ConfirmDialogService, private previewS: PreviewService, private chdRef: ChangeDetectorRef) {
    super(routerS, route, ['newproductid']);
    this.generalInfo = this.formBuilder.group({
      comments: [''],
    });
    this.iva = new UntypedFormControl(21);
  }

  getClientDiscount(): number | null {
    let disc = this.searchUser?.selected?.discount;
    return disc ? disc : null;
  }

  /** Shortcut CTRL + SPACE */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === ' ') {
      this.appendProduct(undefined, true, false, true);
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  override onParam(k: string, v: string) {
    if (k == 'newproductid') {
      this.productIdOnLoad = Number(v);
    }
  }

  initPage() {
    this.apiS.createBill().then(res => {
      if (!this.isDraft(res)) {
        this.currentBill = new M_Invoice({ id: res.new });
        this.recoveredDraft = false;
      }
      else {
        this.currentBill = new M_Invoice(res.draft);
        this.generalInfo.patchValue({ 'comments': this.currentBill.comment });
        if (this.currentBill.breakdown) {
          this.setDraft(this.currentBill.breakdown);
          this.recoveredDraft = true;
          this.snackS.show("Borrador de factura recuperado")
        }
      }
      /** If a product Id is on Params (productIdOnLoad) and the id is not on the current Invoice, add the product on the invoice */
      if (this.productIdOnLoad != undefined && this.productsComponent.filter(comp => comp.p instanceof M_Product && comp.p.product_id == this.productIdOnLoad).length == 0) {
        this.apiS.getProductById(this.productIdOnLoad).then(product => {
          product!.quantity = 1;
          product!.discount = this.getClientDiscount();
          this.appendProduct(product, true, true);
          this.contentLoaded = true;
        })
      }
      else {
        this.contentLoaded = true;
      }
    })
  }

  disacrdDraft() {
    if (this.currentBill?.id) {
      this.apiS.discardDraft(this.currentBill!.id!).then(_res => {
        this.routerS.refresh();
      })
    }
  }

  setDraft(draft: M_Breakdown) {
    this.searchUser?.setMasterById(this.currentBill!.client_id);
    draft.products.forEach(p => { this.appendProduct(p); })
    draft.customs.forEach(c => { this.appendProduct(c); })
  }

  attachClient(c: M_Client) {
    if (c) {
      this.apiS.addClientBill(this.currentBill!.id!, c.client_id).then(res => {
        this.currentBill!.client = c;
      });
    }
  }

  isDraft(res: any) {
    return res.draft != undefined;
  }

  refreshBillCalculations() {
    let products: (M_Product | M_CustomProduct)[] = []
    this.productsComponent.forEach(comp => {
      if (comp.p != undefined) {
        products.push(comp.p);
      }
    })
  }

  updateGeneralInfo() {
    this.apiS.extraInvoice(
      this.currentBill?.id!,
      this.generalInfo.get('comments')?.value,
    )
  }

  newCustomProduct() {
    let product = this.appendProduct();
    if (product) {
      let dRef = this.dialog.open<CreateCustomProductComponent, CustomProductData>
        (CreateCustomProductComponent,
          {
            autoFocus: false,
            data: {
              inputValue: undefined,
              interno: false,
              product: undefined,
            }
          });
          
      dRef.afterClosed().subscribe(res => {
        if (res instanceof M_CustomProduct) {
          res.discount = this.getClientDiscount();
          this.setProductOnDraft(product.instance, res);
        }
        else {
          product.instance.destroy();
        }
      })
    }
  }
appendComment(){
  let ref = this.container.createComponent(AddProductComponent);
  let dRef = this.dialog.open(AddCommentComponent,{data:{ ct: undefined}});
  dRef.afterClosed().subscribe(res => {
    console.log(res);
    if (res instanceof M_CustomProduct) {
      console.log(res);
      let t = new M_Task({ changes: true });
      res.tax = 21;
      res.discount = this.getClientDiscount();
      t.setProduct(res);
      ref.instance.setProduct(res);
      this.refreshBillCalculations();
      this.chdRef.detectChanges();
    }
  });
}
  appendProduct(p?: M_Product | M_CustomProduct, makeCall: boolean = false, addedByParam: boolean = false, focus = false) {
    let ref = this.container.createComponent(AddProductComponent);
    ref.instance.currentFase = "search"
    ref.instance.id = this.id;
    ref.instance.showSearchStock = false;
    ref.instance.addedByParam = addedByParam;
    ref.instance.onChangeDiscount.subscribe(v => { this.updateDraftProduct(v, false); })
    ref.instance.onChangePrice.subscribe(v => { this.updateDraftProduct(v, false); })
    ref.instance.onDestroy.subscribe(val => { this.remove(val) })
    ref.instance.onModifyStock.subscribe(val => { this.onModifyStock(val) })
    ref.instance.onSelectProduct.subscribe(val => { this.setProductOnDraft(ref.instance, val); })

    this.id++;
    this.productsComponent.push(ref.instance);
    if (p) {
      if (makeCall) {
        this.setProductOnDraft(ref.instance, p);
      }
      else {
        ref.instance.setProduct(p);
        ref.instance.initialValue = p.quantity;
      }
    }
    this.refreshBillCalculations();
    this.chdRef.detectChanges();

    /** Focus product searcher */
    if (focus) {
      ref.instance.focusSearcher();
    }

    return ref;

  }

  /** Set a product on draft */
  setProductOnDraft(apc: AddProductComponent, p: M_Product | M_CustomProduct) {
    if (!p.quantity) {
      p.setQuantity(1);
      apc.initialValue = p.quantity;
    }
    this.apiS.addProductBill(this.currentBill!.id!, p).then(res => {
      p.setLineId(res)
      apc.setProduct(p);
      apc.initialValue = p.quantity;
      this.currentBill?.pushNewProduct(p);
    })
  }

  remove(val: { componentId: number | undefined, producLine: number | undefined, product: M_Product | M_CustomProduct | undefined }) {
    if (val.producLine) {
      this.apiS.delProductBill(val.producLine).then(_res => {
        for (let i = 0; i < this.productsComponent.length; i++) {
          if (this.productsComponent[i].id == val.componentId) {
            this.productsComponent.removeIndex(i);
            if (val.product) {
              this.currentBill?.removeProduct(val.product);
            }
          }
        }
        this.refreshBillCalculations();
      })
    }
  }


  onModifyStock(product: M_Product | M_CustomProduct) {
    let pComponent = this.productsComponent.find(component => component.p?.line_id == product.line_id);
    if (pComponent && product.line_id) {
      this.updatingLine = this.apiS.updateProdBill(product).then(res => {
        this.updatingLine = undefined;
      });
    }
  }

  updateDraftProduct(product: M_Product | M_CustomProduct, modifyStock: boolean = true) {
    this.updatingLine = this.apiS.updateProdBill(product, modifyStock).then(res => {
      this.updatingLine = undefined;
    });
  }

  createBill() {
    if (!this.userS.missingCompanyInfo) {
      if (!this.fs.isOk(this.searchUser!.form)) {
        this.confirmD.showError("Usuario requerido", "Se requiere como mínimo un usuario associado a la factura")
      }
      else if (!this.isProductsOk()) {
        this.confirmD.showError("No se pudo crear la factura", "Se requiere como mínimo un producto")
      }
      else if (this.currentBill && this.currentBill?.getTotalBreakdown().total < 0) {
        this.confirmD.showError("No se pudo crear la factura", "El total de la factura es negativo")
      }
      else if (this.fs.isOk(this.generalInfo)) {
        this.dialog.open(CreateInvoiceDialogComponent, {data : this.searchUser?.selected}).afterClosed().subscribe(res => {
          if (res.date) {
            
            let date = new CustomDate(res.date);
            let expedient = res.expedient;

            if (this.updatingLine) {
              this.updatingLine.then(res => {
                this.confirmInvoice(date, expedient);
              })
            }
            else {
              this.confirmInvoice(date, expedient);
            }
          }
        })
      }
    }
    else {
      this.userS.showMissingCompany();
    }
  }

  confirmInvoice(date: CustomDate, expedient : string | undefined) {
    this.apiS.confirmInvoice(this.currentBill!.id!, this.currentBill!.getTotalBreakdown().total, date, expedient).then(_res => {
      if (_res.token) {
        this.apiS.getInvoiceId(this.currentBill!.id!).then(res => {
          if (res) {
            this.bill = new M_Invoice(res);
            this.previewS.showPreview("I", _res.token, undefined, undefined, undefined, undefined, this.bill);
          }
        });

      }
      this.routerS.goToWithState(this.v.facturas, this.currentBill!.id)
    });
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createClient);
  }

  isProductsOk() {
    let leastOneProduct = false;
    for (let i = 0; i < this.productsComponent.length; i++) {
      if (this.productsComponent[i].p != undefined) {
        leastOneProduct = true;
      }
      else {
        if (this.productsComponent[i].searchForm) {
          this.fs.isOk(this.productsComponent[i].searchForm.form)
        }
      }
    }
    return leastOneProduct;
  }


  getInProcessProducts() {
    let filtred = this.productsComponent.filter(p => p.p instanceof M_Product)
    let toReturn: M_Product[] = [];
    filtred.forEach(p => {
      if (p.p) {
        toReturn.push(p.p as M_Product)
      }
    })
    return toReturn;
  }

  getProducts() {
    let filtred = this.productsComponent.filter(p => p.p instanceof M_Product)
    let toReturn: M_Product[] = [];
    filtred.forEach(p => {
      let product = (p!.p as M_Product);
      product.setQuantity(p.addRemove?.getValue());
      toReturn.push(product);
    })

    return toReturn;
  }

  getCustoms() {
    let filtred = this.productsComponent.filter(p => p.p instanceof M_CustomProduct)
    let toReturn: M_CustomProduct[] = [];
    filtred.forEach(p => {
      let product = (p!.p as M_CustomProduct);
      product.setQuantity(p.addRemove?.getValue());
      toReturn.push(product);
    })
    return toReturn;
  }

  openChangeIva() {
    this.dialog.open(IvachangerComponent).afterClosed().subscribe(v => {
      if (typeof v == "number")
        this.iva.setValue(v);
    })
  }


}
