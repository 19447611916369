<div class="general-info-parent">
    <app-card [contentLoaded]="parent.loaded" [cornerMatMenu]="deleteButton">
        <app-card-title>
            <div class="df div_btn">
            <span>Tu empresa
                <span style="margin-left: 10px" [matBadge]="userS.missingCompanyInfo? '!' : ''"></span>
            </span>
            <p *ngIf="userS.missingCompanyInfo" class="missing-company-info">
                Rellena la información de tu empresa para poder realizar facturas
            </p>
            <ng-template #deleteButton>
                <button mat-icon-button
                  [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #deleteMenu="matMenu">
                  <button mat-menu-item [disabled]="!userS.isAdmin" (click)="deleteCompany()">
                    <mat-icon>delete</mat-icon> Eliminar empresa
                  </button>
                </mat-menu>
              </ng-template>
        </div>
        </app-card-title>
        <div class="df fww aic" style="justify-content: space-evenly;">
            <div>
                <p *ngIf="!imgSaved" class="fss c_t2 tac fsi" style="margin-bottom: -15px;">
                    <mat-icon class="unsavedimg c_r">fiber_manual_record</mat-icon>
                    Guarda para aplicar los cambios
                </p>
                <lib-file-upload [crop]="true" [maintainAspectRatio]="false" [maxSize]="6"
                    (stateChanged)="onFileupload($event)"></lib-file-upload>
            </div>
        </div>
        <form [formGroup]="form" (ngSubmit)="makeCall()">
            <div class="form onboarding-company-genera-tab-info">
                <app-card-subtitle [first]="true">Información básica</app-card-subtitle>
                <div class="df">
                    <app-cif class="w50" [form]="form" [formCNmae]="'cif'"></app-cif>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Nombre empesa</mat-label>
                        <input #input matInput placeholder="Nombre empresa" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="df">
                    <app-email-input class="w50" [form]="form" [formCName]="'email'"></app-email-input>
                    <app-phone-input class="w50" [form]="form" [formCName]="'phone'"></app-phone-input>
                </div>
                <app-card-subtitle>Taller</app-card-subtitle>
                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Plazas taller</mat-label>
                        <input type="number" matInput placeholder="Plazas taller" formControlName="places">
                        <button prevent type="button" mat-icon-button matSuffix color="blue"
                            [matTooltip]="'Indica la cantidad de espacios físicos libres en su taller. De esta manera, podrás saber si hay capacidad para aceptar más vehículos en su agenda.'">
                            <mat-icon class="c_b">info</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Vehículo por defecto</mat-label>
                        <mat-select formControlName="default_vehicle" #typeInput  (selectionChange)="vehicleChange($event)" >
                            <mat-option [value]="1">Moto</mat-option>
                            <mat-option [value]="2">Coche</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Precio por hora</mat-label>
                        <input #input [type]="'number'" matInput placeholder="Precio por hora trabajada" formControlName="price_hour">
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>IVA</mat-label>
                        <mat-select formControlName="iva">
                            <mat-option [value]="0">Sin IVA</mat-option>
                            <mat-option [value]="10">10%</mat-option>
                            <mat-option [value]="21">21%</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <app-card-subtitle>Ubicación</app-card-subtitle>
                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Dirección</mat-label>
                        <input matInput placeholder="Nombre y número de la calle" formControlName="address">
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Complemento dirección</mat-label>
                        <input matInput placeholder="Apartamento, suite, unidad, edificio o piso"
                            formControlName="address2">
                    </mat-form-field>
                </div>
                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Código Postal</mat-label>
                        <input #zipInput type="tel" pattern="[0-9]*" matInput
                            placeholder="Código Postal" formControlName="zip">
                        <div matPrefix *ngIf="loadingZip">
                            <mat-spinner color="primary" style="margin-right: 5px;" [strokeWidth]="6" [diameter]="25"
                                mode="indeterminate"></mat-spinner>
                        </div>
                        <mat-error *ngIf="form.get('zip')?.hasError('notfound')">Código postal no encontrado</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Comunidad autónoma</mat-label>
                        <input type="text" [value]="" matInput placeholder="Comunidad autónoma" formControlName="ccaaObject">
                        <mat-hint>Se deduce del código postal</mat-hint>
                    </mat-form-field>
                   
                </div>
                <div class="df">
                    <!-- <mat-form-field class="w50" appearance="outline">
                        <mat-label>Población</mat-label>
                        <input matInput placeholder="Ciudad" formControlName="city">
                        <mat-hint>Se deduce del código postal</mat-hint>
                    </mat-form-field> -->

                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Población</mat-label>
                        <mat-select #selectedValue formControlName="city" (selectionChange)="stateChange($event)" [disabled]="!this.form.get('zip')?.value?.length">
                            <!-- <mat-select-trigger>
                                {{ selectedPayment ? selectedPayment.poblacio : c?.city}}
                              </mat-select-trigger> -->
                            <mat-option *ngFor="let zip of this.arrayZips" [value]="zip.id">
                                <p class="nmb df aic">{{zip.poblacio}}
                                </p>
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Provincia</mat-label>
                        <input type="text" matInput placeholder="Provincia" formControlName="province">
                        <mat-hint>Se deduce del código postal</mat-hint>
                    </mat-form-field>
                   
                </div>
                <!-- FALTA EL PERSONALIZADO -->
                <!-- <div class="df union">
                    <mat-form-field *ngIf="selectedValue.value =='personalizado'"  appearance="outline">
                        <mat-label>Personalizado Población</mat-label>
                        <input formControlName="city" matInput type="text">
                    </mat-form-field>
                </div> -->
                
            </div>

            <div class="df jcc form" style="margin-top: 20px;">
                <button [disabled]="!form.valid || this.form.get('zip')?.value.length !=5" appTrialbutton color="warn" class="onboarding-company-genera-tab-finish" mat-flat-button
                    type="submit">Guardar</button>
            </div>
        </form>
    </app-card>
</div>