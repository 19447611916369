import { templateTypedoc } from "./M_TemplateField";

export class M_doc  {
    token: string | undefined;
    type: templateTypedoc;

    constructor(d: any){
        this.token = d.token;
        this.type = d.type;
    }
    get docName(){
        
        if(this.type=="B"){

            return "Presupuesto"; 
        }
        else{
            return "Orden de Reparación";
        }

    }
}