<app-card [contentLoaded]="dragLoaded" [noStyle]="true" >
    <div cdkDropListGroup *ngIf="dragLoaded">
        <app-drag-group #dragGroup
                        [product_list]="product_list" 
                        [price_hour]="action!.price_hour"
                        [type]="action!.type_id"
                        [abono]="abono"
                        [locked]="locked"
                        [canModifyAction]="canModifyAction()"
                        (onRemoveGroup)="onRemoveGroup($event)" 
                        (onStatusChange)="onStatusChange($event)"
                        (onTypeChangeEvent)="onTypeChange($event)"
                        (onInvoiceTask)="onInvoiceTask($event)"
                        (onDropEvent)="onDrop($event)" 
                        (onGroupChanges)="onSomeGroupChanges.emit()"
                        *ngFor="let g of action?.groups"[group]="g"></app-drag-group>
        <div class="mt20 tac">
            <p *ngIf="action?.groups?.length == 0 || action == undefined" class="c_t2">Todavía no has añadido ninguna intervención</p>
            <div>
                <button color="accent" mat-flat-button (click)="addNew()" *ngIf="!action?.isDone() && abono == false" [disabled]="locked">
                    <mat-icon>account_tree</mat-icon>
                    <span class="fw500 nmb">Nueva intervención</span>
                </button>
            </div>
        </div>
    </div>
</app-card>