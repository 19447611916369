import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarService, ConfirmDialogService, CustomDate, FileUploadComponent, FormService, RouterService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { ClientInfoComponent } from 'src/app/core/components/class-searcher/client-info/client-info.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { ParamsService } from 'src/app/core/services/params.service';
import { M_ActiveReception } from 'src/app/core/models/M_ActiveReception';
import { ActionCreateEditParentComponent } from '../or-create-edit-parent/or-create-edit-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { OrFormComponent } from 'src/app/core/components/or-form/or-form.component';
import { TabbedCommentsComponent } from './tabbed-comments/tabbed-comments.component';
import { PreviewService } from 'src/app/core/services/preview.service';
import { TemplateFieldsService } from 'src/app/core/services/template-fields.service';
import { M_TemplateField } from 'src/app/core/models/M_TemplateField';
import { forkJoin } from 'rxjs';
import { RaGroupsComponent } from './ra-groups/ra-groups.component';
import { UserServiceService } from '../../profile/user-service.service';
import { OrNotesAndImagesComponent } from '../or-notes-and-images/or-notes-and-images.component';

@Component({
  selector: 'app-or',
  templateUrl: './create-or.component.html',
  styleUrls: ['./create-or.component.css', '../create-edit-or-styeles.css']
})
export class CreateOr extends ActionCreateEditParentComponent implements OnInit {
  @ViewChild(ClientInfoComponent) clientDetails!: ClientInfoComponent;
  @ViewChild(OrFormComponent, { static: true }) formComponent!: OrFormComponent;
  @ViewChild(RaGroupsComponent) raGroups!: RaGroupsComponent;
  @ViewChild(OrNotesAndImagesComponent) notesAndImages!: OrNotesAndImagesComponent;
  e = endpoints;
  resizeSignatureSize = 530;

  constructor(routerS: RouterService, public cs: CalendarService, private formS: FormService, apiS: ApiService, d: MatDialog,
    private paramS: ParamsService, route: ActivatedRoute, private templateService: TemplateFieldsService, public userS: UserServiceService,
    private chdRef: ChangeDetectorRef, private previewS: PreviewService, private confirmDialogS: ConfirmDialogService) {
    super(apiS, routerS, route, ["or", "startDate", 'vehicle'], d, confirmDialogS);
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
    this.fillSchedule(new CustomDate());
  }

  override onParam(k: string, v: number | any) {
    if (k == "vehicle") {
      this.formComponent.fillFormWithVehicle(v);
    }
    else if (k == "startDate") {
      this.fillSchedule(new CustomDate(v));
    }
  }

  get minimumRequiredFilled() {
    return this.hasVehicle && this.formComponent.form.valid
  }

  get hasKilometers() {
    return this.formComponent.form.get('km')?.valid;
  }

  get hasVehicle() {
    return this.formComponent.vehicleSearcher?.selected != undefined;
  }

  get missingCompanyInfo() {

    return this.userS.missingCompanyInfo;
  }

  /** Attention!!! If you modify this function, it is likely that you will also have to modify
  *  the 'startRA' function of the 'transfer-action.component.ts'*/
  createOr() {
    if(this.userS.missingCompanyInfo == false){
    if (this.formS.isOk(this.formComponent.form, this.formComponent.vehicleSearcher!.form)) {
      this.formComponent.form.patchValue({ "assigned_to": this.formComponent.workerSearcher?.selected?.id })
      let ra = new M_ActiveReception(
        this.formComponent!.vehicleSearcher!.selected!.client!.client_id!,
        this.formComponent!.clientInvoice!.selected?.client_id,
        this.formComponent!.vehicleSearcher!.selected!.vehicle_id,
        this.formComponent.form.value,
        this.raGroups.groups
      )
      this.templateService.showTemplateSteps("RA", undefined, undefined, this.formComponent.vehicleSearcher?.selected?.type, ra).afterClosed().subscribe
        ((dialog_res: { models: M_TemplateField[], files: M_TemplateField[] } | false) => {
          if (dialog_res != false) {
            this.apiS.createOR(ra).then(res_create_or => {
              if (res_create_or.or_id) {
                ra.id = res_create_or.or_id;
                let files = this.notesAndImages.fileupload.getUploadedFiles();
                if (files) { this.uploadImage(ra, ...files) };
                const a = this.apiS.saveTemplateFields(res_create_or.action_id!, dialog_res.models);
                const b = this.apiS.saveTemplateFiles(res_create_or.action_id!, dialog_res.files);
                forkJoin([a, b]).subscribe(res => {
                  this.previewS.showPreview("RA", res_create_or.token, undefined, ra.id, false, true);
                })
              }
            });
          }
        });
    }
    }
    else {
      this.confirmD.showError("Error en el formulario", "Se requiere como mínimo introducir un vehículo, sus kilómetros y los datos de Empresa")
    }
  }

  override fillSchedule(v: CustomDate) {
    this.formComponent.form.patchValue({ "schedule": v.datePickerFormat })
  }

  disableNoVehicles(v: M_Client) {
    return !v.hasVehicles();
  }

  goCompany() {
    this.routerS.goTo(this.v.business);
  }
}


