import { CustomDate, CustomFile, match, M_Parser, parseOBJ, SearchFiltrable } from "@sinigual/angular-lib";
import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { M_Client } from "./M_Client";
import { I_ClassSearcher } from "../interfaces/I_ClassSearcher";
import { endpoints } from "../api/Enpoints";
import { VehicleType } from "../enums/VehicleType";
import { environment } from "src/environments/environment";

export class M_Vehicle implements M_Parser, SearchFiltrable, I_ClassSearcher<M_Vehicle> {
    needsToBeParsed = true;
    vehicle_id: number;
    type: VehicleType;
    brand: string | undefined;
    model: string | undefined;
    description: string;
    license: string;
    serie: number | null;
    chassis: string;
    color: string;
    insurance: string;
    cc: string;
    engine_type: string;
    license_date: CustomDate | undefined;
    hire_date: CustomDate | undefined;
    km: string;
    client: M_Client | undefined = undefined;
    client_id: number | undefined;
    electric: boolean;
    last_itv: CustomDate | undefined;
    next_itv: CustomDate | undefined;
    documentation: CustomFile[] = [];
    vehicleImages: CustomFile[] = [];
    battery_1_no: string | undefined;
    battery_2_no: string | undefined;
    actions: M_Action[] = [];
    invoices: M_Invoice[] = [];
    last_reception: CustomDate | undefined;
    deleted: boolean = false;
    clientInvoice: M_Client | undefined;
    clientInvoice_id: number | undefined;
    minify = false;
    id_electric: number | undefined;
    constructor(d: any) {
        this.vehicle_id = d.id;
        this.type = d.type;
        this.brand = d.brand;
        this.model = d.model;
        this.description = d.description;
        this.license = d.license;
        this.serie = this.getSerieFromString(d.serie);
        this.chassis = d.chassis;
        this.color = d.color;
        this.insurance = d.insurance;
        this.cc = d.cc;
        this.engine_type = d.engine_type;
        this.deleted = d.deleted;
        this.license_date = d.license_date ? new CustomDate(d.license_date) : undefined;
        this.hire_date = d.hire_date ? new CustomDate(d.hire_date) : undefined;
        this.km = d.km;
        this.clientInvoice = d.clientInvoice ? new M_Client(d.clientInvoice) : undefined;
        this.clientInvoice_id = d.clientInvoice_id;
        this.id_electric = d.id_electric;
        if (d.client) {
            this.client = new M_Client(d.client)
        }

        this.last_itv = d.last_itv ? new CustomDate(d.last_itv) : undefined;
        this.next_itv = d.next_itv ? new CustomDate(d.next_itv) : undefined;

        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name == "docu_img") {
                    var cf = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    this.documentation.push(cf)
                }
                else if (d.images[i].name == "vehicle_img") {
                    this.vehicleImages.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id))
                }
            }
        }


        this.battery_1_no = d.battery_1_no ? d.battery_1_no : undefined;
        this.battery_2_no = d.battery_2_no ? d.battery_2_no : undefined;


        this.electric = this.id_electric != 0 || this.engine_type == "1";


        if (d.actions) {
            this.actions = [];
            for (let i = 0; i < d.actions.length; i++) {
                this.actions.push(new M_Action(d.actions[i]));
            }
        }
        if (d.invoices) {
            for (let i = 0; i < d.invoices.length; i++) {
                this.invoices.push(new M_Invoice(d.invoices[i]));
            }
        }

        this.last_reception = this.getLastReceptionByActions();
    }

    get brandAndModel() {
        return this.brand && this.model;
    }

    getSerieFromString(serie: any) {
        if (typeof serie == "number") {
            return serie;
        }
        else if (serie && typeof serie == "string" && serie.isNumber()) {
            return serie.getNumber();
        }
        return null;
    }

    get lastOr(){
        return this.actions.filter(action => action.isOr())
        .sort((a, b) => a.created_at.value > b.created_at.value ? -1 : 1)[0];
    }


    /** TODO : Esto se deberia poner en un enum... */
    get getSerieName(): string | undefined {
        if (!this.serie) { return undefined }
        if (this.isCar) {
            if (this.serie == 0) { return "Microcoche" }
            if (this.serie == 1) { return "Urbano" }
            if (this.serie == 2) { return "Sedan" }
            if (this.serie == 3) { return "Descapotable" }
            if (this.serie == 4) { return "Coupé" }
            if (this.serie == 5) { return "Deportivo" }
            if (this.serie == 6) { return "Monovolúmen" }
            if (this.serie == 7) { return "Todo Terreno" }
            if (this.serie == 8) { return "SUV" }
            if (this.serie == 9) { return "Camioneta" }
        }
        else {
            if (this.serie == 0) { return "Sport" }
            if (this.serie == 1) { return "Touring" }
            if (this.serie == 2) { return "Trail" }
            if (this.serie == 3) { return "Scooter" }
            if (this.serie == 4) { return "Offroad" }
            if (this.serie == 5) { return "Custom" }
            if (this.serie == 6) { return "Naked" }
        }
        return undefined;
    }

    generateRandomInteger(min: number, max: number) {
        return Math.floor(min + Math.random() * (max - min + 1))
    }

    parse() {
        let obj: any = parseOBJ(this);
        if (this.license_date) {
            obj['license_date'] = new CustomDate(obj['license_date']).dataBaseFormat;
        }
        if (this.hire_date) {
            obj['hire_date'] = new CustomDate(obj['hire_date']).dataBaseFormat;
        }
        if (this.last_itv) {
            obj['last_itv'] = new CustomDate(obj['last_itv']).dataBaseFormat;
        }
        if (this.next_itv) {
            obj['next_itv'] = new CustomDate(obj['next_itv']).dataBaseFormat;
        }
        if (this.km) {
            this.km += '';
            let test = this.km.replace(/\./g, '');
            obj['km'] = test;
        }
        return obj;
    }

    /** Concat brand and name. If there is no brand and no name, return license.
     * If the parameter licenseDefault is set to 'false', return empty string if no brand, no model and no license.
     */
    getName(licenseDefault = true) {
        return this.brand && this.model ? this.brand + " " + this.model :
            this.brand || this.model || (licenseDefault ? this.license : '');
    }

    get engineType() {
        let v = this.engine_type;
        return v == "0" ? "Gasolina" : v == "1" ? "Eléctrico" : ""
    }

    get isCar() {
        return this.type == VehicleType.car;
    }

    get isBike() {
        return this.type == VehicleType.bike;
    }

    get typeName() {
        return this.isCar ? "Coche" : "Moto";
    }

    getLastReceptionByActions() {
        if (!this.actions) { return undefined; }
        return this.actions
            .filter(a => a.type_id.isOr)
            .sort((a, b) => a.created_at.value > b.created_at.value ? -1 : 1)[0]?.created_at;
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.getName(),
            this.license,
            this.brand,
            this.model,
            this.chassis,
            this.license_date ? this.license_date.dayMonthYearFormat : '',
            this.client ? this.client.getName() : '');
    }

    /** Vehicle brand image */
    getBrandImageUrl() {
        return environment.apiUrl + "/" + this.getVehicleFolder() + "/" + this.getBrandFormatted() + ".png"
    }

    private getBrandFormatted() {
        if (this.brand) {
            /** Remove spaces and dashes */
            return this.brand.toLocaleLowerCase().replace(/\-/g, "").replace(/\ /g, "");
        }
        return "";
    }

    private getVehicleFolder() {
        return this.isCar ? "car-logos" : "bike-logos";
    }

    /** CLASS SEARCHER INTERFACE */
    get endpoint() { return !this.minify ? endpoints.vehicles : endpoints.vehiclesminify }
    get cs_id() { return this.vehicle_id; }
    get icon() { return this.isCar ? "directions_car_filled" : "two_wheeler"; }
    get typeof() { return M_Vehicle }
    get extraOptionText() { return this.client?.getName() }
    createNew(d: any) { return new M_Vehicle(d) }
    getInputText(): string {
        return this.getName();
    }
    getOptionText(): [string, string] {
        if (!this.brand && !this.model) {
            return ([this.license, ""]);
        }
        return ([this.getName(), this.license])
    }
}