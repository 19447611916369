<a [routerLink]="['/' + data.view.path]" #item *ngIf="isShowing"
    [class]="'menuItem ' + dataCalssPhoneDesktop() + ' ' + (class_ ? class_ : '')"
    [ngClass]="{'selected' : bS.isSelected(data), 'lordTrigger' : lordIcon, 'closed' : !mc.opened}"
    (click)="bS.goToPage(data, mc)">

    <mat-icon *ngIf="!svgIcon && !lordIcon"
        class="c_w"
        [fontSet]="bS.isSelected(data) ? 'material-icons' : 'material-icons-outlined'">{{data.icon}}</mat-icon>


    <mat-icon *ngIf="svgIcon && !lordIcon" [svgIcon]="bS.isSelected(data) ? data.icon :  data.icon == 'wheel' ? (data.icon + '_outline') : data.icon"
        [ngClass]="{'filled' :bS.isSelected(data), 'c_w' : !bS.isSelected(data)}"></mat-icon>


    <div *ngIf="lordIcon">
        <ng-content></ng-content>
    </div>
    <span *ngIf="mc.opened" [ngClass]="{'mr20' : matmenu, 'c_w' : true}">
        {{data.name}}
    </span>
    <span class="span-icon" style="margin-right: 0px;">
        <mat-icon *ngIf="icon && mc.opened" [style]="'color : ' + icon.color + ';'">{{icon.icon}}</mat-icon>
    </span>
    <div class="expansionPanel" [ngClass]="{'closed' : !mc.opened}"></div>
    <div *ngIf="badge" [ngClass]="!mc.opened ? 'badge-opened' : 'badge end-badge'" [matBadge]="badge ? badge : null">
    </div>
</a>