<app-page-structure [masterClass]="'vehicle'" pageTitle="VEHÍCULOS" [autocompleteID]="'vehicle'"
    searchLabel="Buscar vehículo" circleIcon="two_wheeler" circleIconClass="onboarding-create-vehicle"
    [addNewView]="v.createvehicle" noDataCreateNew="Crear un nuevo vehículo" [data]="vehicles"
    [displayedHeader]=" ['Matrícula',   'Marca',    'Modelo', 'Propietario', 'Última reparación']"
    [displayedColumns]="['license',     'brand',    'model',  'client', 'last_reception' ]"
    [specialText]="     [undefined,      undefined,  undefined,  getOwner,  undefined]"
    [specialClass]="    [undefined,      undefined,  undefined,  undefined,  undefined]" [preIcon]="[preIcon]"
    [card]="card" [lordIcon]="lordIcon" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()">
    <ng-template #lordIcon>
        <script src="https://cdn.lordicon.com/lordicon.js"></script>
        <lord-icon src="https://cdn.lordicon.com/gizjnixp.json" trigger="in" class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al vehículo'" mat-flat-button color="warn"
                (click)="handleClick($event,'V')" (contextmenu)="handleContextMenu($event,'V')">
                {{ps.selected!.getName()}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <app-card-subtitle>Propietario</app-card-subtitle>
            <div *ngIf="ps.selected!.client; else nodata">
                <app-client-row [item]="ps!.selected!.client"></app-client-row>
            </div>
            <ng-template #nodata>
                <p class="c_t2">Sin propietario</p>
            </ng-template>
            <app-card-subtitle>Última reparación</app-card-subtitle>
            <div>
                <p *ngIf="ps.selected!.last_reception">
                    <mat-icon class="vam">calendar_month</mat-icon>
                    Día
                    <span>{{ps.selected!.last_reception.shortFormat}}</span>
                </p>

                <p *ngIf="!ps.selected!.last_reception" class="c_t2">
                    Todavía no se han efectuado recepciones.
                </p>
            </div>
            <button style="margin-bottom: 20px;" mat-stroked-button
                (click)="handleClick($event,'OR')" (contextmenu)="handleContextMenu($event,'OR')">
                <mat-icon>add</mat-icon>
                Abrir OR
            </button>
        </app-card>
    </ng-template>
</app-page-structure>