import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_New } from 'src/app/core/api/responses/R_Dashboard';

@Component({
  selector: 'app-dialog-whats-news',
  templateUrl: './dialog-whats-news.component.html',
  styleUrls: ['./dialog-whats-news.component.css']
})
export class DialogWhatsNewsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_New[] ) { }
  ngOnInit(): void {}

}
