import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { M_Product_Invoice } from '../../../core/models/M_Product';
import { MovementProduct } from '../product-details.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PreviewService } from 'src/app/core/services/preview.service';


@Component({
  selector: 'app-productinvoice',
  templateUrl: './productinvoice.component.html',
  styleUrls: ['./productinvoice.component.css', "../product-details.component.css"],
  animations: [

    trigger('appearAnim', [
      state('toAppear', style({
        transform: 'scale(0.5)',
        opacity: '0'
      })),
      state('appear', style({
        transform: 'scale(1)',
        opacity: '1'
      })),
      transition('toAppear => appear', animate(300))
    ])
  ]
})
export class ProductinvoiceComponent implements OnInit, MovementProduct {

  @Input() pi!: M_Product_Invoice;
  appear: "appear" | "toAppear" = "toAppear";
  constructor(private chdRef: ChangeDetectorRef, private previewS : PreviewService) { }

  anim(): void {
    this.appear = "toAppear";
    this.chdRef.detectChanges();
    this.appear = "appear";
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.appear = "appear"
    this.chdRef.detectChanges();
  }

  billPreview(b: M_Product_Invoice) {
    this.previewS.showPreview("I", b.token, undefined, b.invoice_id)
  }

}
