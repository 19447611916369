import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassSearcherComponent, ConfirmDialogService, or_status_close, ResponsiveService, RouterService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ActionCreateEditParentComponent } from '../or-create-edit/or-create-edit-parent/or-create-edit-parent.component';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ParamsService } from 'src/app/core/services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { M_GroupTask } from 'src/app/core/models/M_GroupTask';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { DragComponent } from 'src/app/core/components/drag/drag.component';
import { ExitSaveChanges } from 'src/app/core/interfaces/ExitSaveChanges';
import { PreviewService } from 'src/app/core/services/preview.service';
import { MASTER_VECHILE_MINIFY } from 'src/app/core/constants/masters';
import { TemplateFieldsService } from 'src/app/core/services/template-fields.service';
import { TrasnferActionComponent, transferData } from 'src/app/core/components/trasnfer-action/trasnfer-action.component';
import { AddProductComponent } from '../create-bill/add-product/add-product.component';
import { UserServiceService } from '../profile/user-service.service';
import { M_doc } from 'src/app/core/models/M_doc';

@Component({
  selector: 'app-create-edit-budget',
  templateUrl: './create-edit-budget.component.html',
  styleUrls: ['./create-edit-budget.component.css']
})
export class CreateEditBudgetComponent extends ActionCreateEditParentComponent implements OnInit, ExitSaveChanges {
  @ViewChild("vehicleSearcher") vehicleSearcher!: ClassSearcherComponent<M_Vehicle>;
  @ViewChild(DragComponent) dragComponent!: DragComponent;
  vehicle = MASTER_VECHILE_MINIFY;
  e = endpoints;

  /** Dont modify */
  forceExit = false;

  constructor(routerS: RouterService, apiS: ApiService, route: ActivatedRoute, private location: Location, d: MatDialog,
    public router: Router, confirmD: ConfirmDialogService, private paramsS: ParamsService, public subS: SubscriptionService,
    public previewS: PreviewService, public userS: UserServiceService, private templateService: TemplateFieldsService,
    public responsiveS :  ResponsiveService,) {
    super(apiS, routerS, route, ["budget"], d, confirmD);
  }

  saveChangesBeforeExit(): void {
    this.dragComponent.saveAll();
  }

  showSaveExitDialog(): boolean {
    if (this.dragComponent && this.subS.hasProPermissions) {
      return this.dragComponent.hasChanges();
    }
    return false;
  }

  override noParams() {
    this.actionLoaded = true;
  }

  ngOnInit(): void { }

  get missingCompanyInfo() {
    return this.userS.missingCompanyInfo;
  }

  createBudget() {
    let v: M_Vehicle | undefined = this.vehicleSearcher.selected;
    if (v != undefined) {
      this.apiS.createBudget(v.client!.client_id, v.vehicle_id, '').then(res => {
        this.action = res;
        this.action!.vehicle = v;
        this.action!.client = v!.client;
        this.fillViewWithAction(this.action!);
        this.location.replaceState("/createeditbudget?budget=" + this.action?.id);
      })
    }
  }

  updateTitle(v: string) {
    if (this.action != undefined) {
      if (v != this.action!.title) {
        this.apiS.editTitle(this.action.id, v).then(_res => {
          this.action!.title = v;
        });
      }
    }
  }

  override fillViewWithAction(a: M_Action) {
    if (a.vehicle) {
      this.vehicleSearcher.setMasterById(a.vehicle_id);
      this.vehicleSearcher.canRemove = false
    }
  }


  saveBudget() {
    this.dragComponent.saveAll();
  }

  showTemplateFields() {
    
    if (this.action && this.action.calculateTotal() < 0) {
      this.confirmD.show({
        title : "¡No tan deprisa!",
        body : "No puedes cerrar un presupuesto con un total negativo.",
        type : "danger",
        showCancel : false,
        confirmTxt : "OK"
      })
      return;
    }

    this.dragComponent.saveAll().then(res => {
      this.templateService.showTemplateSteps("B", this.action!.action_id, this.action!.token, this.vehicleSearcher.selected?.type).afterClosed().subscribe(res => {
        if (res) {
          this.closeBudget();
        }
      })
    });
  }

  closeBudget() {
    if (this.haveBudget) {
      this.dragComponent.saveAll().then(res => {
        this.action?.groups.forEach(g => {
          if (!g.hasTasks()) {
            this.deleteGroupTask(g);
          }
        })
        this.apiS.closeBudget(this.action!.id).then(res => {
          this.action!.status = or_status_close;

          this.action!.urlpdf.push(new M_doc(res.urlpdf[0]));

          //console.log("Que token Guardo?",res.urlpdf[0].token);
          this.previewS.showPreview('B', this.action!.urlpdf[0].token, undefined, this.action?.action_id);
        });
      });
    }
  }


  isClosed() {
    return this.action?.isClosed();

  }

  deleteGroupTask(gt: M_GroupTask) {
    this.confirmD.show({
      title: "Eliminar intervención",
      body: "¿Está seguro de que quiere eliminar la intervención?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.rmGroup(gt.id).then(_res => {
          this.action?.groups?.removeElement(gt);
          this.action?.refreshType();
        })
      }
    });

  }

  openTransferTo(to: "or" | "budget") {
    this.d.open<TrasnferActionComponent, transferData, boolean>
      (TrasnferActionComponent,
        {
          autoFocus: false,
          disableClose: true,
          data: {
            action: this.action!,
            from: "budget",
            to: to
          }
        }
      )
  }

  goOr(id: number) {
    this.paramsS.go(ViewPath.editOr, id);
  }

  goCompany() {
    this.routerS.goTo(this.v.business);
  }

  get haveBudget() {
    return this.action != undefined;
  }
}
