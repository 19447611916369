
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { templateTypedoc } from '../models/M_TemplateField';
import { PDF_DATA } from '../components/pdf/pdf.component';
import { TEMPLATE_DIALOG_DATA, TemplateFieldsDialog } from '../components/template-fields-dialog/template-fields-dialog.component';
import { TemplateSteps } from '../enums/TemplateSteps';
import { VehicleType } from '../enums/VehicleType';
import { M_Action } from '../models/M_Action';
import { M_ActiveReception } from '../models/M_ActiveReception';

/**
 * Service for showing the template fields of a document
 */
@Injectable({
  providedIn: 'root'
})
export class TemplateFieldsService {

  constructor(private d: MatDialog) { }

  /** Show the steps and template fields before closing a invoice, or, abono...
   * @param preview_type "B" | "RA" | "OR" | "I" | "A"
   * @param token Object token.
  */
  showTemplateSteps(preview_type: templateTypedoc, id?: number, token?: string, vehicle? : VehicleType, ra? : M_ActiveReception ) {
    console.log("Preview type: ", preview_type)
    console.log("Token of object:", token)
    console.log("ID:", id)
    let dialogRef = this.d.open<TemplateFieldsDialog, { pdf: PDF_DATA, dialog: TEMPLATE_DIALOG_DATA }>(TemplateFieldsDialog, {
      maxWidth: '800px',
      maxHeight: '90vh',
      height: '90%',
      width: '90%',
      data: {
        pdf: {
          preview_type: preview_type,
          id: id,
          token: token,
          noIdObject : ra
        },
        dialog :{
          steps : this.getStepsByDocument(preview_type),
          vehicleType : vehicle
        },
      },
      autoFocus: false,
      disableClose: true,
    });

    return dialogRef;
  }

  getStepsByDocument(preview_type : templateTypedoc) : TemplateSteps[]{
    let templateSteps: TemplateSteps[] = [];
    switch(preview_type){
      case 'B':
        templateSteps = [TemplateSteps.FIELDSWORKSHOP, TemplateSteps.PREVIEW, TemplateSteps.FIELDSCLIENT]
        break;
      case 'RA':
        templateSteps = [TemplateSteps.FIELDSWORKSHOP, TemplateSteps.PREVIEW, TemplateSteps.FIELDSCLIENT]
        break;
      case 'OR':
        break;
      case 'I':
        break;
      case 'A':
    }
    return templateSteps;
  }
}