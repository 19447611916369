<app-card [noStyle]="true" (click)="switchSelected($event, false)" [matTooltipPosition]="'above'">
    <div  [ngClass]="{'selectedColor' : selected, 'cp' : true && !disabled, 'task-card' : true, 'disabled-card' : disabled}">
        <div class="group-container" *ngIf="this.dataMostrada; else noData">
            <div>
                <app-card-subtitle [noMarginBottom]="true" [first]="true">
                    <mat-checkbox [disabled]="disabled" (click)="switchSelected($event, true)"></mat-checkbox>
                    <mat-icon class="mr5 vam scaled-icon" *ngIf="!dataMostrada.type_payment.isBizum">
                        {{dataMostrada.type_payment.icon}}
                    </mat-icon>
                    <mat-icon class="mr5 scaled-icon vam" *ngIf="dataMostrada.type_payment.isBizum" [svgIcon]="'bizum'"></mat-icon>
                    ANTICIPO
                    <span class="fw500 c_t2 fss " money [val]="this.dataMostrada.import"></span>
                </app-card-subtitle>
            </div>
        </div>
        <ng-template #noData>
            <p class="c_t2 tac" style="padding: 0px 10px 0px 10px">No hay anticipos</p>
        </ng-template>
    </div>
</app-card>
