
<div [ngClass]="!isEdit? 'double-card' : 'create-item'">
    <app-go-back *ngIf=" c != undefined" [show]="isEdit" [text]="c!.getName()" (click)="goBackClient()"></app-go-back>
    <app-card [contentLoaded]="loaded" class="section">
        <app-view-title>{{isEdit? "EDITAR CLIENTE" : "CREAR CLIENTE"}}</app-view-title>
        <app-clientform [edit]="isEdit"></app-clientform>
        <div *ngIf="isEdit" [ngTemplateOutlet]="submitButton"></div>
    </app-card>
    <app-card class="section" *ngIf="!isEdit">
        <div *ngIf="!isEdit">
            <app-view-title>AÑADIR VEHÍCULO
                <mat-slide-toggle class="onboarding-client-vinculate" #toggle></mat-slide-toggle>
            </app-view-title>
            <app-vehicleform *ngIf="!isEdit" [ngClass]="{'dn' : !toggle.checked}"></app-vehicleform>
            <p *ngIf="!toggle.checked && !isEdit" class="fw400 c_o"> Se creará el cliente sin ningún vehículo asociado </p>
        </div>
        <div [ngTemplateOutlet]="submitButton"></div>
    </app-card>
</div>
<ng-template #submitButton>
        <div class="df jcc">
            <button appTrialbutton mat-flat-button color="warn" class="onboarding-client-finish" (click)="create()" style="margin-top: 20px;" [disabled]="clientForm.onSave">
                <span>{{isEdit? 'Guardar' : toggle?.checked? 'Crear cliente y vehículo' :  'Crear cliente sin vehículo'}}</span>
            </button>
        </div>
</ng-template>