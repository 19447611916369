import cli from '@angular/cli';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { FormService, ParameterStateComponent, RouterService, SnackService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { ClientformComponent } from 'src/app/core/components/clientform/clientform.component';
import { DialogConfirmPlateComponent } from 'src/app/core/components/vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { VehicleformComponent } from 'src/app/core/components/vehicleform/vehicleform.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent extends ParameterStateComponent {
  loaded: boolean = false;
  v = ViewPath;
  public isEdit: boolean = false;
  isClient:boolean = false;
  isVehicle:boolean = false;
  responseC : M_Client | undefined;
  responseV : M_Vehicle | undefined;
  public c: M_Client | undefined;
  @ViewChild('toggle') toggle?: MatSlideToggle;
  @ViewChild(ClientformComponent, { static: true }) clientForm!: ClientformComponent;
  @ViewChild(VehicleformComponent) formVehicle!: VehicleformComponent;

  constructor(private fs: FormService, private apiS: ApiService,
    private d: MatDialog,
    routerS: RouterService, route: ActivatedRoute, private paramS: ParamsService,
    private params: ParamsService, private snackS: SnackService) {
    super(routerS, route, ["client"]);
  }


  override noParams() {
    this.loaded = true;
  }

  override onParam(_k: string, v: any) {
    if (_k == "client") {
      this.isEdit = true;
      this.apiS.getClientById(v).then(res => {
        if (res) {
          this.fillDataWithClient(res);
        }
      })
    }
  }

  fillDataWithClient(client: M_Client) {
    this.loaded = true;
    this.clientForm.form.patchValue(client);
    this.clientForm.form.patchValue({ "dob": client.dob ? client.dob.value : null });
    client.clients_admincenters.forEach(ac => {
      this.clientForm.pushNewAdminCenter(ac);
    })
    this.c = client;
  }
  create() {
    if (this.isEdit) {
      this.clientForm.form.updateValueAndValidity();
      let client = new M_Client (this.clientForm.form.getRawValue())
      console.log(client);
      console.log(this.clientForm.form.get('nif')?.value);
        this.apiS.checkDni(this.clientForm.form.get('nif')?.value).then(res=>
         {
          if(res instanceof M_Client){
            this.isClient = true;
            console.log('es cliente');
            let fieldName = !this.clientForm.isSpanish ? 'identificador' : this.clientForm.isCompany ? "CIF" : "DNI"
            if(client.client_id != res.client_id){
              this.d.open(DialogConfirmPlateComponent,{data:{
                title: "¡Atención!",
                message: "Ya existe un cliente con el ",
                param: this.clientForm.form.get('nif')?.value,
                message2: "Recordamos que no se pueden crear dos clientes con el mismo DNI",
                error:true,
              }}).afterClosed().subscribe(res=>{
                this.clientForm.form.get('nif')?.setErrors({found:true});
              }
              )
            }else{
             
                let client = new M_Client(this.clientForm.form.getRawValue());
                client.client_id = this.c!.client_id;
                client.vehicles = this.c!.vehicles;
                this.apiS.updateClient(client).then(_resp => {
                  this.params.go(this.v.clientDetails, client.client_id);
                  this.snackS.show("¡Cliente editado con éxito!")
                })
            }
        
          }else{   
              let client = new M_Client(this.clientForm.form.getRawValue());
              client.client_id = this.c!.client_id;
              client.vehicles = this.c!.vehicles;
              this.apiS.updateClient(client).then(_resp => {
                this.params.go(this.v.clientDetails, client.client_id);
                this.snackS.show("¡Cliente editado con éxito!")
              })
          }
         } 
        );
     
    }
    else {
      if (this.toggle?.checked) {
        
          let c = new M_Client(this.clientForm.form.value);
          let v = new M_Vehicle(this.formVehicle.form.value);
          c.vehicles = [new M_Vehicle(this.formVehicle.form.value)];
          if (this.fs.isOk(this.formVehicle.form, this.clientForm.form)) {
          this.apiS.checkDni(this.clientForm.form.get('nif')?.value).then(res=>
            {
             if(res instanceof M_Client){
               this.isClient = true;
               console.log('es cliente');
               let fieldName = !this.clientForm.isSpanish ? 'identificador' : this.clientForm.isCompany ? "CIF" : "DNI"
                 this.d.open(DialogConfirmPlateComponent,{data:{
                   title: "¡Atención!",
                   message: "Ya existe un cliente con el ",
                   param: this.clientForm.form.get('nif')?.value,
                   message2: "Recordamos que no se pueden crear dos clientes con el mismo DNI",
                   error:true,
                 }}).afterClosed().subscribe(res=>{
                   this.clientForm.form.get('nif')?.setErrors({found:true});
                 }
                 )
             }else{
              this.apiS.checkPlate(this.formVehicle.form.get('license')?.value).then(resV=>
                {
                if(resV instanceof M_Vehicle){
                  this.responseV = resV
                  this.isVehicle = true;
                  console.log('es cliente');
                  if(v.vehicle_id != resV.vehicle_id){
    
                    this.d.open(DialogConfirmPlateComponent,{data:{
                      title: "¡Atención!",
                      message: "Ya existe un vehiculo con el ",
                      param: this.formVehicle.form.get('license')?.value,
                      message2: "Recordamos que no se pueden crear dos vehiculos con la misma matrícula",
                      error:true,
                    }}).afterClosed().subscribe(res=>{
                      this.formVehicle.form.get('license')?.setErrors({found:true});
                    }
                    )
                  }
                  
                }else{
                  this.apiS.createClient(c).then(resp => {
                    this.paramS.go(this.v.clientDetails, resp.client_id)
                    this.snackS.show("¡Cliente y vehículo creados con éxito!")
                  })
                   this.clientForm.form.updateValueAndValidity();
                }
                } 
              );
             }
          });
        }
      }
      else {
          let client = new M_Client (this.clientForm.form.getRawValue())
            this.apiS.checkDni(this.clientForm.form.get('nif')?.value).then(res=>
             {
              if(res instanceof M_Client){
                this.isClient = true;
                console.log('es cliente');
                let fieldName = !this.clientForm.isSpanish ? 'identificador' : this.clientForm.isCompany ? "CIF" : "DNI"
                if(client.client_id != res.client_id){
                  this.d.open(DialogConfirmPlateComponent,{data:{
                    title: "¡Atención!",
                    message: "Ya existe un cliente con el ",
                    param: this.clientForm.form.get('nif')?.value,
                    message2: "Recordamos que no se pueden crear dos clientes con el mismo DNI",
                    error:true,
                  }}).afterClosed().subscribe(res=>{
                    this.clientForm.form.get('nif')?.setErrors({found:true});
                  }
                  )
                }else{
                 
                  let c = new M_Client(this.clientForm.form.value);
                  this.apiS.createClient(c).then(resp => {
                    this.paramS.go(this.v.clientDetails, resp.client_id)
                    this.snackS.show("¡Cliente creado con éxito!")
                  })
                  this.clientForm.form.updateValueAndValidity();
                }
            
              }else{   
                let c = new M_Client(this.clientForm.form.value);
                this.apiS.createClient(c).then(resp => {
                  this.paramS.go(this.v.clientDetails, resp.client_id)
                  this.snackS.show("¡Cliente creado con éxito!")
                })
                this.clientForm.form.updateValueAndValidity();
              }
             } 
            );
      }
    }
  }

  goBackClient() {
    if (!this.c){return;}
    this.paramS.go(ViewPath.clientDetails, this.c.client_id);
  }

}
