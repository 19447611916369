import { Component, EventEmitter, Input, Output } from '@angular/core';
import { M_LastMovements } from '../../models/M_LastMovement';

@Component({
  selector: 'app-invoice-movements',
  templateUrl: './invoice-movements.component.html',
  styleUrls: ['./invoice-movements.component.css']
})
export class InvoiceMovementsComponent {
  @Input() movements: M_LastMovements[] = [];
  @Input() isTotalPay: boolean = false;
  @Input() showLastCircle: boolean = true;
  @Input() canDelete: boolean = true;
  @Output() onDeleteMovement: EventEmitter<M_LastMovements> = new EventEmitter();
}
