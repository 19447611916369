import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTicketDialog, RouterService } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
  selector: 'app-contact-us-text',
  templateUrl: './contact-us-text.component.html',
  styleUrls: ['./contact-us-text.component.css']
})
export class ContactUsTextComponent {

  @Input() text = "Contacta con nosotros";
  sended = false;
  constructor(private d: MatDialog, private apiS : ApiService, private routerS : RouterService) { }

  openContactUsDialog() {
    if (!this.sended) {
      this.d.open(CreateTicketDialog,  {autoFocus : false, data: { view: undefined, showGoIssues : false }}).afterClosed().subscribe(res => {
        if (res != undefined) {
          this.text = "¡Gracias por su aportación!"
          this.sended = true;
          this.apiS.createCloudTicket(res).then(res => {
            this.routerS.goTo(ViewPath.issues);
          })
        }
      });
    }
  }
}
