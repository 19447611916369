import { Component, Input, OnInit, Optional } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { getColorByVehicle } from 'src/app/core/custom-classes/Colors';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.css']
})
export class VehicleInfoComponent implements OnInit {

  v = ViewPath;
  getColorByVehicle = getColorByVehicle;

  @Input() vehicle: M_Vehicle | undefined;
  @Input() small: boolean = false;
  @Input() lord: boolean = true;
  @Input() noDataText?: string;

  constructor(public paramsS: ParamsService) { }

  ngOnInit(): void { }

  hasValue(v: any) {
    return v != undefined;
  }

  goVehicle() {
    if (!this.vehicle?.deleted) {
      this.paramsS.go(ViewPath.vehicleDetails, this.vehicle!.vehicle_id, 'vehicle', true)
    }
  }
}
