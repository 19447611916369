<div class="whats-new mla mra" *ngIf="!isDimissed()">
    <app-card-subtitle [first]="true" class="title tac">
        <span>¡Tenemos 
            <span class="underline">novedades</span>!
        </span>
    </app-card-subtitle>
    <div class="df jcc">
        <button mat-button class="c_t" (click)="dimissNews()">Cerrar</button>
        <button mat-flat-button type="button" class="view_button" (click)="openDialog()">Ver</button>
    </div>
</div>