import { Component, Input} from '@angular/core';
import { M_Client } from '../../models/M_Client';

@Component({
  selector: 'app-client-consents',
  templateUrl: './client-consents.component.html'
})
export class ClientConsentsComponent {
  @Input() client! : M_Client | undefined;
  constructor() { }

}
