<app-page-structure [masterClass]="'client'" pageTitle="CLIENTES" [autocompleteID]="'clients'"
    searchLabel="Buscar cliente" searchOnboardingClass="onboarding-search-client" circleIcon="person_add"
    circleIconClass="onboarding-create-client" [addNewView]="v.createClient" noDataCreateNew="Crear un nuevo cliente"
    [data]="[]" [displayedHeader]=" ['Nombre',     'DNI',     'Email',      'Teléfono', 'Última reparación']"
    [displayedColumns]="['name',       'nif',     'email',      'phone',    'last_reception']"
    [specialText]="     [completeName, undefined,  undefined,   undefined,     undefined]"
    [specialClass]="    [undefined,    undefined,   undefined,   undefined,     undefined]"
    [cellZize]="        ['big',    undefined,   'big',   undefined,     undefined]" [card]="card" [lordIcon]="lordIcon"
    [filter]="f" [preIcon]="[preIcon]" [dataUpload]="features.dataUpload ? v.dataupload : undefined" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()">

    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/yucreboj.json" trigger="in"
            colors="primary:#121331,secondary:#ebe6ef,tertiary:#faddd1,quaternary:#faddd1,quinary:#faddd1,senary:{{warn()}},septenary:{{primary()}},octonary:#eee966,nonary:#913710,denary:{{accent()}},undenary:#3a3347"
            class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al cliente'" mat-flat-button color="warn"
                (click)=" handleClick($event,'C')" (contextmenu)="handleContextMenu($event,'C')">
                {{ps.selected!.getName()}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <p *ngIf="ps.selected!.email != undefined" class="mt5_i c_t2">{{ps.selected!.email}}</p>
            <app-card-subtitle>Vehículos</app-card-subtitle>

            <app-vehicle-row-container *ngIf="ps.selected!.hasVehicles(); else noData"
                [vehicles]="ps.selected!.vehicles"></app-vehicle-row-container>

            <button mat-stroked-button *ngIf="!ps.selected?.hasVehicles()"
                (click)="params.go(v.createvehicle, ps.selected!.client_id, 'client')">
                <mat-icon>add</mat-icon>
                Dar de alta vehículo
            </button>

            <ng-template #noData>
                <p class="c_t2">Sin vehículo asignado</p>
            </ng-template>

            <app-card-subtitle>Última reparación</app-card-subtitle>
            <div>
                <p *ngIf="ps.selected!.last_reception">
                    <mat-icon class="vam">calendar_month</mat-icon>
                    Día <span>{{ps.selected!.last_reception.shortFormat}}</span>
                </p>

                <p *ngIf="!ps.selected!.last_reception" class="c_t2">
                    Todavía no se han efectuado recepciones.
                </p>
            </div>

            <div *ngIf="ps.selected && ps.selected.hasVehicles()">
                <div *ngIf="ps.selected.vehicles.length == 1" class="w100">
                    <button class="w100" style="margin-bottom: 20px;" mat-stroked-button
                        (click)="handleClick($event,'OR')" (contextmenu)="handleContextMenu($event,'OR')">
                        <mat-icon>add</mat-icon>
                        Abrir OR
                    </button>
                </div>
                <div *ngIf="ps.selected.vehicles.length > 1" class="w100">
                    <button class="w100" style="margin-bottom: 20px;" mat-stroked-button
                        [mat-menu-trigger-for]="openOr">
                        <mat-icon>add</mat-icon>
                        Abrir OR
                    </button>
                    <mat-menu #openOr="matMenu">
                        <button mat-menu-item *ngFor="let veh of ps.selected?.vehicles!"
                            (click)="params.go(v.createOr, veh.vehicle_id, 'vehicle')">
                            {{veh.getName()}}
                        </button>
                    </mat-menu>
                </div>
            </div>
            <app-card-footer>
                <app-whatsapp [phone]="ps.selected!.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.phone"></app-phone-button>
            </app-card-footer>
        </app-card>
    </ng-template>
</app-page-structure>