import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { action_types, action_type_or, FormService, or_status_close, or_status_invoiced, or_status_open, or_types, SnackService, or_types_normal, or_types_cargo_interno, or_types_siniestro, ResponsiveService, or_types_garantia, ConfirmDialogService, or_status } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { AddProductInterface } from 'src/app/core/interfaces/AddProductParent';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { M_GroupTask, notitle } from 'src/app/core/models/M_GroupTask';
import { M_Product } from 'src/app/core/models/M_Product';
import { M_Task } from 'src/app/core/models/M_Task';
import { PreviewService } from 'src/app/core/services/preview.service';
import { DragTaskComponent } from '../drag-task/drag-task.component';
import { MatInput } from '@angular/material/input';
import { DragComponent } from '../drag.component';
import { AddTimeComponent } from '../../add-time/add-time.component';
import { CreateCustomProductComponent } from 'src/app/views/create-bill/add-product/create-custom-product/create-custom-product.component';
import { CustomProductData } from 'src/app/core/interfaces/CustomProductData';
import { AddCommentComponent } from '../../add-comment/add-comment.component';

export class DragGroup {
  name: string;
  tasks: M_Task[] = [];
  constructor(name: string, tasks: M_Task[]) {
    this.name = name;
    this.tasks = tasks;
  }
}

@Component({
  selector: 'app-drag-group',
  templateUrl: './drag-group.component.html',
  styleUrls: ['./drag-group.component.css', '../drag.component.css']
})

export class DragGroupComponent implements OnInit, AddProductInterface {
  @ViewChildren(DragTaskComponent) tasksComponent?: QueryList<DragTaskComponent>;
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild("nameInput") nameInput!: MatInput;
  @Output() onRemoveGroup: EventEmitter<any> = new EventEmitter();
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() onTypeChangeEvent: EventEmitter<or_types> = new EventEmitter();
  @Output() onInvoiceTask: EventEmitter<M_GroupTask> = new EventEmitter();
  @Output() onGroupChanges: EventEmitter<any> = new EventEmitter();
  @Input() group!: M_GroupTask;
  @Input() price_hour: number = 0;
  @Input() type!: action_types;
  @Input() product_list: M_Product[] = [];
  @Input() canModifyAction!: boolean;
  @Input() abono: boolean = false;
  @Input() locked: boolean = false;

  isEdit = false;
  t_n = or_types_normal;
  t_ci = or_types_cargo_interno;
  t_g = or_types_garantia;
  t_s = or_types_siniestro;
  s_o = or_status_open;
  s_c = or_status_close;
  s_i = or_status_invoiced;

  public groupName: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private fs: FormService, private dialog: MatDialog,
    private apiS: ApiService, private snackS: SnackService, private chdRef: ChangeDetectorRef, private previewS: PreviewService, @Optional() public dg: DragComponent,
    public responsiveS: ResponsiveService, private confirmD: ConfirmDialogService) {
    this.groupName = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  getClientDiscount(): number | null {
    if (this.group.type.cargo) {return null;}
    let disc = this.dg.action?.defaultInvoiceClient?.discount;
    return disc ? disc : null;
  }

  ngOnInit(): void {
    /*this.saveS.onSave.subscribe(() => {
      if (this.group.hasChanges()) {
        this.saveChanges(undefined, "by-general");
      }
    })*/
  }

  billPreview() {
    this.previewS.showPreview("I", this.group.token, undefined, this.group.invoice_id);
  }

  hasChanges() {
    if (!this.tasksComponent) { return false }
    else {
      return this.group.tasks.some(t => {
        return t.changes == true
      });
    }
  }

  getInProcessProducts(): M_Product[] {
    let products: M_Product[] = [];
    this.group.tasks.forEach(task => {
      let p = task.getProduct()
      if (p instanceof M_Product) {
        products.push(p);
      }
    });
    return products;
  }

  appendProduct(focusSearcher = true): M_Task {
    let task = new M_Task({ changes: false });
    this.group.addTask(task)
    this.chdRef.detectChanges();
    let last = this.tasksComponent?.last;
    if (last && last.apc && focusSearcher) {
      last.apc.focusSearcher();
    }
    return task;
  }


  /** Special case : 
   *  Cannot acces add-product-component. We need to open a new dialog on this component.
   */
  appendTime() {
    let dRef = this.dialog.open(AddTimeComponent, { data: { ct: undefined, showPriceHour: true, priceHour: this.price_hour } });
    dRef.afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        let t = new M_Task({ changes: true });
        res.tax = 21;
        res.discount = this.getClientDiscount();
        t.setProduct(res);
        this.group.addTask(t);
        this.emitOnGroupChanges();
        this.chdRef.detectChanges();
      }
    })
  }

  appendComment(){
    let dRef = this.dialog.open(AddCommentComponent,{width:"500px",data:{ ct: undefined}});
    dRef.afterClosed().subscribe(res => {
      console.log(res);
      if (res instanceof M_CustomProduct) {
        console.log(res);
        let t = new M_Task({ changes: true });
        res.tax = 21;
        res.discount = this.getClientDiscount();
        t.setProduct(res);
        this.group.addTask(t);
        this.emitOnGroupChanges();
        this.chdRef.detectChanges();
      }
    });
  }

  hasTasks() {
    return this.group.tasks.length != 0;
  }

  /** TODO --> Always open dialog. */
  invoiceGroupTask() {
    if (this.group.hasChanges()) {
      this.saveChanges();
    }
    this.onInvoiceTask.emit(this.group);
    this.onGroupChanges.emit();
  }

  getStatus() {
    if (this.group.type.cargo && this.group.isInvoiced()) {
      let a : [or_status, string] = [this.group.state, 'Tramitado']
      return a;
    };
    return this.group.state;
  }

  remove(id: M_Task): void {
    if (id instanceof M_Task) {
      this.group.removeTask(id);
      if (!this.group.hasChanges()) {
        //this.saveS.hide();
      }
    }
    this.onGroupChanges.emit();
  }

  toggleEdit(text: string) {
    if (this.canModifyGroup()) {
      this.isEdit = !this.isEdit;
      if (text == notitle) {
        this.groupName.patchValue({ name: null })
      }
      else {
        this.groupName.patchValue({ name: text })
      }
    }

    setTimeout(() => {
      this.nameInput.focus();
    })
  }

  onTypeChange(val: number) {
    let beforeType = this.group.type;
    let futureType = new or_types(val);
    this.apiS.addEditGroup(this.group.action_id, this.group.id, this.group.title, futureType).then(res => {
      if (res != -1) {
        this.group.type = futureType;
        if (this.group.type.cargo) { this.showCargoInternoMessage(); }
        if (beforeType.cargo && !futureType.cargo && this.getClientDiscount()) {
          this.group.tasks.forEach(t => {
            let p = t.getProduct();
            if (p) { p.discount = this.getClientDiscount(); t.changes = true; }
          })
        }
        this.onTypeChangeEvent.emit(this.group.type);
        this.onGroupChanges.emit();
      }
    })
  }

  showCargoInternoMessage() {
    let discountMessage = false;
    let priceMessage = false;

    if (this.tasksComponent) {
      discountMessage = this.tasksComponent.some(t => {
        let p = t.task.getProduct();
        return (p != undefined && p != null && p.discount != 0);
      });
      priceMessage = this.tasksComponent.some(t => {
        let p = t.task.getProduct();
        return p != undefined && !p.buy_price && !t.task.isTime();
      })
    }

    if (discountMessage || priceMessage) {

      let dMessage = "• Se ha eliminado el descuento de todos los productos.";
      let pMessage = "• Hay productos que NO tienen establecido el precio de compra.";
      let bodyMessage: string[] = [];
      bodyMessage.push("A tener en cuenta : ")
      if (discountMessage) { bodyMessage.push(dMessage) }
      if (priceMessage) { bodyMessage.push(pMessage) }

      this.confirmD.show({
        title: "Cambio a grupo interno",
        body: bodyMessage,
        showCancel: false,
        type: "info",
        confirmTxt: "OK"
      })
    }
  }

  editGroupName(val: string) {
    if (this.fs.isOk(this.groupName)) {
      this.apiS.addEditGroup(this.group.action_id, this.group.id, val, this.group.type).then(res => {
        if (res != -1) {
          this.group.title = val;
        }
        if (this.isEdit) {
          this.isEdit = false;
        }
      })
    }
  }

  hasFacturableTaskas() {
    let tasks = false;
    this.group.tasks.forEach(t => {
      if (t.getProduct() != undefined) {
        tasks = true;
      }
    })
    return tasks;
  }

  drop(event: CdkDragDrop<M_Task[]>, g: M_GroupTask) {
    let group_id = g.id;
    let task = event.previousContainer.data[event.previousIndex];
    /** Reorder */
    if (this.isReorder(event)) {
      if (!this.samePosition(event)) {
        this.groupReorder(event);
        if (task.task_id) {
          this.apiS.sortTask(g.getTasksWithId()).then(res => {
            if (res == undefined) {
              this.groupReorder(event, true);
            }
          })
        }
      }
    }
    /** Group Change */
    else {
      this.groupChange(event);
      task.group_id = group_id;
      if (task.task_id) {
        this.apiS.changeTask(task.task_id, group_id).then(res => {
          if (res == undefined) {
            this.groupChange(event, true);
          }
        })
      }
    }
  }
  isMobileOrTablet(): boolean {
    const width = window.innerWidth;
    return width <= 768; // Cambia este valor según tus necesidades
  }
  isReorder(event: CdkDragDrop<M_Task[]>) {
    return event.previousContainer === event.container;
  }

  samePosition(event: CdkDragDrop<M_Task[]>) {
    return event.previousIndex == event.currentIndex;
  }

  groupReorder(event: CdkDragDrop<M_Task[]>, reverse = false) {
    moveItemInArray(event.container.data, reverse ? event.currentIndex : event.previousIndex,
      reverse ? event.previousIndex : event.currentIndex);
  }

  groupChange(event: CdkDragDrop<M_Task[]>, reverse = false) {
    transferArrayItem(
      reverse ? event.container.data : event.previousContainer.data,
      reverse ? event.previousContainer.data : event.container.data,
      reverse ? event.currentIndex : event.previousIndex,
      reverse ? event.previousIndex : event.currentIndex,
    );
  }

  openGroup() {
    this.saveChanges();
    this.apiS.openGroup(this.group.id).then(_res => {
      this.group.changeStatus(or_status_open);
      this.chdRef.detectChanges();
      this.onStatusChange.emit();
    })
  }

  closeGroup() {
    this.saveChanges();
    this.apiS.closeGroup(this.group.id).then(_res => {
      this.group.changeStatus(or_status_close);
      this.chdRef.detectChanges();
      this.onStatusChange.emit();
    })
  }

  isGroupInvoiced() {
    return this.group.state.num == or_status_invoiced.num;
  }

  isGroupClosed() {
    return this.group.state.num == or_status_close.num;
  }

  async saveChanges(_e?: Event | undefined, from?: "by-general" | undefined): Promise<boolean> {
    let tasksToSave = this.group.tasks.filter(task => { return task.isValidToSave() })

    this.preventDropDownOpening();
    //this.saveS.hide();

    return new Promise(resolve => {
      console.log(tasksToSave);
      if (tasksToSave.length) {
        this.apiS.addEditTasks(tasksToSave).then(_res => {
          this.group.markTaskAsSaved();
          this.onGroupChanges.emit();
          if (from != "by-general") { // --> General saved
            this.snackS.show("Cambios guardados")
          }
          resolve(true);
        })
      }
      else {
        resolve(true);
      }
    })

  }

  preventDropDownOpening(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  isOr() {
    return this.type.num == action_type_or.num;
  }

  emitOnGroupChanges() {
    this.onGroupChanges.emit();
    //this.saveS.show();
  }

  canModifyGroup(): boolean {

    if (this.abono) { return false; }

    if (!this.isOr()) {
      return this.canModifyAction;
    }
    else {
      return this.group.state.open;
    }
  }

  getOthersStockOf(p: M_Product) {
    return this.dg.getOthersStockOf(p, this.group);
  }


  newCustomProduct() {
    let task = this.appendProduct(false);
    this.chdRef.detectChanges();
    let component: DragTaskComponent | undefined = this.tasksComponent?.get(this.tasksComponent.length - 1);
    if (component) {
      let dRef = this.dialog.open<CreateCustomProductComponent, CustomProductData>
        (CreateCustomProductComponent,
          {
            autoFocus: false,
            data: {
              inputValue: undefined,
              product: undefined,
              interno: this.group.type.cargo
            }
          });

      dRef.afterClosed().subscribe(res => {
        if (res instanceof M_CustomProduct) {
          component?.onSelectProduct(res);
        }
        else {
          this.group.tasks.removeElement(task);
        }
      })
    }
  }

}
