import { CustomDate } from '@sinigual/angular-lib';
import { EnumSubscription } from '../enums/EnumSubscription';

interface SubscriptionStatus {
    type: EnumSubscription,
    active: boolean
    will_cancel : boolean
    is_demo : boolean;
    users: number
    remaining_days: number
    amount: number
    next_payment: CustomDate | null
    first_payment?: CustomDate
    cancel_at?: CustomDate
    typedetails: {
        first_user: number
    }
}

export class M_Subscription implements SubscriptionStatus {
    /** ENUM that determinates the user's subscription state */
    type: EnumSubscription;
    /** Is the subscription activated ? */
    active: boolean;
    /** The subscription going to be canceled at the end of the month? */
    will_cancel: boolean;
    /** Is demo */
    is_demo : boolean;
    /** Hired users */
    users: number;
    /** The remainin days of the current subscription */
    remaining_days: number;
    /** Total to pay this month */
    amount: number;
    /** Next payment date */
    next_payment: CustomDate | null;
    /** First paymen done at */
    first_payment?: CustomDate | undefined;
    /** Cancelet at */
    cancel_at?: CustomDate | undefined;
    /** Pricing details */
    typedetails: {
        /** PRO plan price */
        first_user: number;
    };

    constructor(sub: SubscriptionStatus) {
        this.type = sub.type;
        this.active = sub.active;
        this.will_cancel = sub.will_cancel;
        this.is_demo = sub.is_demo;
        this.users = sub.users;
        this.remaining_days = sub.remaining_days;
        this.amount = sub.amount;
        this.next_payment = sub.next_payment ? new CustomDate(sub.next_payment) : null;
        this.first_payment = sub.first_payment ? new CustomDate(sub.first_payment) : undefined;
        this.cancel_at = sub.cancel_at ? new CustomDate(sub.cancel_at) : undefined;
        this.typedetails = sub.typedetails;
        if (this.typedetails) {
            if (this.typedetails.first_user) { this.typedetails.first_user /= 100 } // /= 100 ? --> On the database is the price * 100
        }
    }

}
