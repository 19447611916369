<div class="advanced-details-container" *ngIf="p != undefined || (p == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado productos'" [v]="v.products" [show]="loaded"></app-go-back>
    <div class="product">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="delete_p">
            <div *ngIf="p != undefined">
                <div class="df fdc aic jcc">
                    <p style="margin-top: 10px" class="fsxl fw200"><span class=" fsxl fw500">{{p.name}}</span></p>
                    <p class="fsm fw300" style="letter-spacing: 2px"><span class="fsl fw400" decimal
                            [val]="p.stock"></span> {{p.stock == 1 ? 'existencia' : 'existencias'}}</p>


                    <ng-container [ngSwitch]="p.category">

                        <!-- Degault-->
                        <lord-icon *ngSwitchCase="pc.GENERAL" src="https://cdn.lordicon.com/ezosizhp.json" trigger="in"
                            colors="primary:#121331,secondary:#b26836 ,tertiary:#e3c0ac" class="lord-big">
                        </lord-icon>

                        <lord-icon *ngSwitchCase="pc.WRENCH" src="https://cdn.lordicon.com/putylcra.json" trigger="in"
                            class="lord-big"> </lord-icon>

                        <lord-icon *ngSwitchCase="pc.WHEEL" src="https://cdn.lordicon.com/gizjnixp.json" trigger="in"
                            class="lord-big">
                        </lord-icon>

                        <lord-icon *ngSwitchCase="pc.LIGHT" src="https://cdn.lordicon.com/xhihqryb.json" trigger="in"
                            colors="primary:#000000,secondary:#646e78,tertiary:#3a3347,quaternary:{{accent()}},quinary:{{warn()}},senary:#ffc738"
                            class="lord-big">
                        </lord-icon>

                        <lord-icon *ngSwitchCase="pc.LIQUID" src="https://cdn.lordicon.com/vofhrzot.json" trigger="in"
                            colors="primary:#121331,secondary:{{warn()}},tertiary:#3a3347,quaternary:#ebe6ef,quinary:#ffc738"
                            class="lord-big">
                        </lord-icon>

                        <lord-icon *ngSwitchCase="pc.BOUTIQUE" src="https://cdn.lordicon.com/mjgbpbfx.json" trigger="in"
                            colors="primary:#121331,secondary:#3a3347,tertiary:{{accent()}},quaternary:#646e78"
                            class="lord-big">
                        </lord-icon>

                    </ng-container>


                    <button mat-flat-button color="warn" (click)="params.go(v.createProduct, p.product_id!)"
                        class="one-line">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </div>
            </div>
            <ng-template #delete_p>
                <app-delete-button class="leftItem" [item]="p!"></app-delete-button>
            </ng-template>
        </app-card>
    </div>
    <div class="Details">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="p != undefined">
                <app-card-row-content [freeAlign]="true" [noPaddingLeft]="true" [data]="
                    [['Referencia', p.reference],    
                    ['P.V.P', p.price, {money : true}],
                    ['P.V.P + IVA', p.price_iva, {money : true}],   
                    ['IVA', p.tax == 0 ? 'Sin IVA' : p.tax, {symbol : p.tax != 0 ? '%' : undefined}],
                    ['Categoria', p.getCategoryName]]">
                </app-card-row-content>
            </div>
        </app-card>
    </div>
    <div class="moves">
        <app-card [contentLoaded]="loaded" [noData]="allMoves.length == 0">
            <app-card-title>Últimos movimientos</app-card-title>

            <app-productmove *ngIf="loaded" [allmoves]="allMoves"
                [ngClass]="{'notDisplay': allMoves.length == 0}"></app-productmove>
            <!-- <div *ngIf="p != undefined && allMoves.length != 0">
                <mat-button-toggle-group class="fww" [value]="0" (valueChange)="onToggleChange()">
                    <mat-button-toggle *ngFor="let kv of moveFilters.options | keyvalue; let i = index" [value]="i">
                        <mat-icon fontSet="material-icons-outlined" *ngIf="kv.value.icon">{{kv.value.icon}}</mat-icon>
                        {{kv.value.name}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>    
                  
                <div class="df fww">
                    <div *ngFor="let move of filteredMovements">
                        <app-productinvoice *ngIf="isInvoiceMove(move)" [pi]="move"></app-productinvoice>
                        <app-productmove *ngIf="isAddOrRemove(move)" [pm]="move"></app-productmove>
                    </div>
                    <p *ngIf="filteredMovements.length == 0" class="c_t2" style="margin-top: 10px;">Sin movimientos</p>
                </div> -->
            <!-- </div> -->
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha enctontrado el producto'" [goText]="'Ir al listado de productos'"
        [view]="v.products">
    </app-model-not-found>
</ng-template>