import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { cifValidator, nifValidator } from '@sinigual/angular-lib';

/**
 * Input to validate a CIF
 */
@Component({
  selector: 'app-cif',
  templateUrl: './cif.component.html',
})
export class CifComponent implements OnInit {
  @HostBinding('class') class = "_customInput";
  @Input() form!: UntypedFormGroup;
  @Input() formCNmae!: string;

  get error() {
    let v = this.form.get(this.formCNmae)!;
    return (v.hasError('cif') || v.hasError('nif') || v.hasError('dni')) &&
    v.dirty && v.touched;
  }

  ngOnInit(): void {
    this.form.get(this.formCNmae)?.addValidators(this.nifOrCifValidator());
  }

  nifOrCifValidator(): ValidatorFn {
    return nifValidator() || cifValidator();
  }
}
