import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pro-tips-dialog',
  templateUrl: './pro-tips-dialog.component.html',
  styleUrls: ['./pro-tips-dialog.component.css']
})
export class ProTipsDialogComponent {
  gifRoute = "./assets/pro-tips/";
  tipName = "protip";
  tipIndex = 1;
  totalTips = 4;
  tipUrl = ""

  e = environment;

  constructor() {
    const d = new Date();
    var firstWeekday = new Date(d.getFullYear(), d.getMonth(), 1).getDay();
    var offsetDate = d.getDate() + firstWeekday - 1;
    let week =  Math.floor(offsetDate / 7);
    this.tipIndex = week + 1;
    this.tipUrl = this.gifRoute + this.tipName + this.tipIndex.toString() + ".mp4";
  }

}
