<div #menu class="menu" (mouseleave)="onMouseLeaveComponent()"
    [ngClass]="!opened && isDesktop ? 'menu-closed' : ''">
    <div (mouseenter)="onMouseEnterComponent()">
      <app-menu-item [mc]="this" [data]="panel"></app-menu-item>
      <app-menu-item [mc]="this" [data]="clientes"></app-menu-item>
      <app-menu-item [mc]="this" [data]="vehiculos" [svgIcon]="true"></app-menu-item>
      <app-menu-item [mc]="this" [data]="productos" [svgIcon]="true"></app-menu-item>
      <app-menu-item [mc]="this" [data]="budget"></app-menu-item>
      <app-menu-item [mc]="this" [data]="or"></app-menu-item>
      <app-menu-item [mc]="this" [data]="facturas"></app-menu-item>
      <div class="m10"><mat-divider></mat-divider></div>
      <!---------->
      <app-menu-item [mc]="this" [data]="cargataller"></app-menu-item>
      <!---------->
      <div class="m10"><mat-divider></mat-divider></div>
      <app-menu-item #company [mc]="this" [data]="empresa" [badge]="userS.missingCompanyInfo ? '!' : undefined"></app-menu-item>
      <app-menu-item [mc]="this" [data]="upload" *ngIf="features.dataUpload"></app-menu-item>
      <!---------->
      <div class="m10" *ngIf="company.isShowing"><mat-divider></mat-divider></div>
      <app-menu-item #sub [mc]="this" [data]="subscription"></app-menu-item>
      <!---------->
      <div class="m10" *ngIf="sub.isShowing "><mat-divider></mat-divider></div>
      <app-menu-item [mc]="this" [data]="issues"></app-menu-item>
</div>
<!--(mouseleave)="opened = false" (mouseenter)="opened = true"-->