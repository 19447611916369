<div (click)="handleClick($event)" class="item-row" [ngClass]="item.deleted ? 'deleted' : ''"  (contextmenu)="handleContextMenu($event)">
    <p [ngClass]="item.deleted ? '' : 'cp'">
        <mat-icon class="vam mr5">{{isV(item) ? item.icon : 'person'}}</mat-icon>
        <!-- Name -->
        <span [ngClass]="item.deleted ? 'fw500' : 'clicktext'" style="font-size: 15px;">{{item.getName()}}</span>
        
        <!-- Vehicle specific-->
        <span *ngIf="isV(item)">
            <span class="c_t2 ml10 mr10">|</span>
            <span>{{item.license}}</span>
        </span>

        <!-- Deleted -->
        <span *ngIf="item.deleted" class="ml5 fw500 c_r"> (Eliminado) </span>

        <!-- Go arrow-->
        <span *ngIf="!advanced" [ngTemplateOutlet]="goArrow"></span>

    </p>
    <p *ngIf="advanced && isV(item)" class="fw500">Matriculación :
        <span *ngIf="item.license_date" class="fwn">{{item.license_date.humanFormat}}</span>
        <span *ngIf="!item.license_date" class="c_t2 fwn">Sin datos</span>
        <span [ngTemplateOutlet]="goArrow"></span>
    </p>
</div>

<ng-template #goArrow>
    <mat-icon *ngIf="!item.deleted" class="goArrow">arrow_forward</mat-icon>
</ng-template>