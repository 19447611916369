import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FormService, SnackService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_LastMovements } from 'src/app/core/models/M_LastMovement';
import { M_TypePayment } from 'src/app/core/models/M_TypePayment';
import { PreviewService } from 'src/app/core/services/preview.service';

@Component({
  selector: 'app-dialog-advance-pay',
  templateUrl: './dialog-advance-pay.component.html',
  styleUrls: ['./dialog-advance-pay.component.css']
})
export class DialogAdvancePayComponent implements OnInit {
  form: UntypedFormGroup;
  selectedPayment: any;
  typePayment:M_TypePayment []=[];
  private personalizadoSelected = false;
  constructor(private apiS: ApiService,private fb: UntypedFormBuilder,private snackS: SnackService,private fs: FormService,
  @Inject(MAT_DIALOG_DATA) public data:{client:M_Client,typePayment:M_TypePayment[],last_movement:M_LastMovements[],editLastMovement:M_LastMovements},
  public dRef: MatDialogRef<DialogAdvancePayComponent>,private previewS: PreviewService) { 
    this.form = this.fb.group({
      type_payment_id: ['',Validators.required],
      import:['',Validators.required],
      client_id:[''],
      type:['1'],
      name:['']
      
    });
    if(this.data.editLastMovement){
      this.form.addControl('id', this.fb.control(''));      
      this.form.patchValue(this.data.editLastMovement);
      this.form.get('type_payment_id')?.patchValue(this.data.editLastMovement.type_payment_id);
      this.selectedPayment = this.data.typePayment.find(payment => payment.id === this.data.editLastMovement.type_payment_id);
    }

    }
   

  ngOnInit(): void {

  }
  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;
    this.selectedPayment = this.data.typePayment.find(payment => payment.id === selectedValue);
  
    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('name')?.setValidators([Validators.required]);
    } else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('name')?.clearValidators();
      }
    }
  
    this.form.get('name')?.updateValueAndValidity();
  }
  updateForm(){

    this.form.patchValue({
      client_id: this.data.client.client_id
     
    });
   
    if(this.fs.isOk(this.form)){
      this.apiS.createAdvanceClient(this.form.getRawValue()).then(
        res=>{
          const typePaymentValue = res.type_payment;
          if (!this.data.typePayment.some(item => item.payment === typePaymentValue.payment)) {
            this.data.typePayment.push(typePaymentValue);
          }
          this.data.last_movement.push(res);
          this.previewS.showPreview("AN", res.id.toString());
           this.dRef.close(this.data.last_movement);     
           this.snackS.show('Se ha efectuado el cobro');
      });  
    }

  }
  editForm(){


    if(this.fs.isOk(this.form)){
      this.apiS.EditAdvanceClient(new M_LastMovements(this.form.getRawValue())).then(
        res=>{
           this.dRef.close(new M_LastMovements(res));     
           this.snackS.show('Se ha efectuado el cobro');
      });  
    }
  }
  deleteLastPayment(last_movement:M_LastMovements){
    this.apiS.deleteLastPayment(last_movement).then(res=>{
      this.data.last_movement.removeElement(last_movement);
    },
    error=>{

    })
  }
  deleteTypePayment(type_payment:M_TypePayment){
    
    this.apiS.deleteTypePayment(type_payment.id).then(res=>{

      this.data.typePayment.removeElement(type_payment);

    },error=>{

    });
  }
  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);
 
    if (!reg.test(input)) {
        event.preventDefault();
    }
 }
}
