import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService, FormService, CustomTime } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_CustomProduct } from '../../models/M_CustomProduct';
import { AddTimeComponent } from '../add-time/add-time.component';
import { CustomProductData } from '../../interfaces/CustomProductData';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.css']
})
export class AddCommentComponent {
  custom: M_CustomProduct | undefined;
  form: UntypedFormGroup;
  v = ViewPath;

  constructor(public routerS: RouterService, private fs: FormService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddCommentComponent>, @Inject(MAT_DIALOG_DATA) public data: {ct: M_CustomProduct, comment ?:string}) {
    this.form = this.fb.group({
      comment: [''],
    })
    if(this.data.comment){
      this.form.patchValue({comment:this.data.comment});
    }
  }
  create() {
    if (this.fs.isOk(this.form)) {
      let c = new M_CustomProduct(this.form.value);
      c.setQuantity(0);
      c.type = "comment";
      c.name ="Comentario";
      c.reference = "";
      c.buy_price = 0;
      this.dialogRef.close(c);
    }
  }
  get disabled() {
    return !this.input && !this.form.valid;
  }
  get input() : number {
    let val = this.form.get('comment')!.value;
    return val ? val : '';
  }
  
}
