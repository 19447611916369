import { M_Client } from "../models/M_Client";
import { M_Product } from "../models/M_Product";
import { M_User } from "../models/M_User";
import { M_Vehicle } from "../models/M_Vehicle";

/** INTANCES FOR THE CLASS SERACHER COMPONENT */

/** CLIENT */
export const MASTER_CLIENT = new M_Client({})
export const MASTER_CLIENT_MINIFIY = new M_Client({})
MASTER_CLIENT.minify = true;

/** VEHICLE */
export const  MASTER_VECHILE = new M_Vehicle({});
export const  MASTER_VECHILE_MINIFY = new M_Vehicle({});
MASTER_VECHILE_MINIFY.minify = true;

/** PRODUCT */
export const MASTER_PRODUCT = new M_Product({})


/** USER */
export const MASTER_USER = new M_User({});