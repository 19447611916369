import { Component, Inject, OnInit } from '@angular/core';
import { M_CustomProduct } from '../../models/M_CustomProduct';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService, FormService, CustomTime } from '@sinigual/angular-lib';
import { max_price } from '../../constants/constants';
import { getQuantityFromTime } from '../../constants/time-picker-constants';

@Component({
  selector: 'app-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.css']
})
export class AddTimeComponent implements OnInit {

  custom: M_CustomProduct | undefined;
  form: UntypedFormGroup;
  v = ViewPath;

  constructor(public routerS: RouterService, private fs: FormService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddTimeComponent>, @Inject(MAT_DIALOG_DATA) public data: { ct?: CustomTime, showPriceHour: boolean, priceHour?: number,p?:M_CustomProduct }) {
    this.form = this.fb.group({
      hours: [''],
      minutes: [''],
      name:[''],
    })
    
  }

  ngOnInit(): void {

    if (this.data.showPriceHour) {
      let fc = new FormControl('price');
      fc.addValidators([Validators.required, Validators.min(0), Validators.max(max_price)]);
      this.form.addControl('price', fc);
      this.form.patchValue({ price: this.data.priceHour })
    }

    if (this.data.ct) {
      this.form.patchValue({ hours: this.data.ct.getHours() })
      this.form.patchValue({ minutes: this.data.ct.getMinutes() })
      if(this.data.p){
        this.form.patchValue({ name: this.data.p.name })
      }
      
    }


  }

  preventNegativeInput(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  get hours() : number {
    let val = this.form.get('hours')!.value;
    return val ? val : 0;
  }

  get minutes() : number {
    let val = this.form.get('minutes')!.value;
    return val ? val : 0;
  }

  create() {
    let t: CustomTime = new CustomTime(this.hours.toString(), this.minutes.toString());
    let q = getQuantityFromTime(t);
    if (this.fs.isOk(this.form) && t != undefined && q != 0) {
      let c = new M_CustomProduct(this.form.value);
      if(!c.name){
        c.name = "MANO DE OBRA";
      }else{
      }
      
      c.setQuantity(q);
      c.type = "time";
      c.tax = 21;
      c.reference = "";
      c.buy_price = 0;
      this.dialogRef.close(c);
    }
  }

  get disabled() {
    return (!this.hours && !this.minutes) || !this.form.valid;
  }

}