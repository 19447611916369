import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { M_LastMovements } from '../../models/M_LastMovement';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-show-advance-client',
  templateUrl: './show-advance-client.component.html',
  styleUrls: ['./show-advance-client.component.css']
})
export class ShowAdvanceClientComponent implements OnInit {

  @Input() dataMostrada?: M_LastMovements;
  @Input() disabled: boolean = false;
  @Input() preSelected?: boolean;
  @Output() onSelect: EventEmitter<ShowAdvanceClientComponent> = new EventEmitter();
  @Output() ondisSelect: EventEmitter<ShowAdvanceClientComponent> = new EventEmitter();
  @ViewChild(MatCheckbox) checkbox?: MatCheckbox;
  elementosSeleccionados: M_LastMovements[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  switchSelected(event: Event, stopPropagation: boolean) {
    if (!this.checkbox || this.disabled) { return; }
    this.checkbox.toggle();
    if (this.checkbox.checked) {
      this.onSelect.emit(this);
    }else{
      this.ondisSelect.emit(this);
    }
  }

  get selected() {
    if (!this.checkbox) { return; }
    return this.checkbox.checked;
  }
 
}
