import { Component, Inject, ViewChild } from '@angular/core';
import { M_Action } from '../../models/M_Action';
import { M_Client } from '../../models/M_Client';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassSearcherComponent, ConfirmDialogService, FormService, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../../api/api.service';
import { OrFormComponent } from '../or-form/or-form.component';
import { TemplateFieldsService } from '../../services/template-fields.service';
import { M_TemplateField } from '../../models/M_TemplateField';
import { M_ActiveReception } from '../../models/M_ActiveReception';
import { forkJoin } from 'rxjs';
import { PreviewService } from '../../services/preview.service';

export interface transferData {
  action: M_Action,
  from: "or" | "budget",
  to: "or" | "budget"
}

/**
 * Transfer OR to Buget, OR to OR, or Budget to OR
 */
@Component({
  selector: 'app-trasnfer-action',
  templateUrl: './trasnfer-action.component.html',
  styleUrls: ['./trasnfer-action.component.css']
})
export class TrasnferActionComponent {

  client = MASTER_CLIENT_MINIFIY;
  v = ViewPath;
  @ViewChild(ClassSearcherComponent) clienSearcher!: ClassSearcherComponent<M_Client>;
  @ViewChild(OrFormComponent) formComponent?: OrFormComponent;

  constructor(public dialogRef: MatDialogRef<TrasnferActionComponent, transferData>, @Inject(MAT_DIALOG_DATA) public data: transferData,
    private routerS: RouterService, private apiS: ApiService, private formS: FormService, private templateService: TemplateFieldsService, private previewS: PreviewService,
    private confirmD: ConfirmDialogService) {

    if (this.specialSiniestroCase) {
      //this.transferSiniestroBudget();
    }
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createClient);
  }

  ngAfterViewInit() {
    if (this.data.to == "or" && this.formComponent) {
      this.formComponent.vehicleSearcher?.setMasterById(this.data.action.vehicle?.vehicle_id!);
      this.formComponent.clientInvoice?.setMasterById(this.data.action.vehicle!.clientInvoice?.client_id!);
    }
  }

  get specialSiniestroCase(){
    return this.data.action.isSomeGroupSiniestro && this.data.from == "budget" && this.data.to == "or";
  }

  get orFormLoaded() {
    return this.formComponent && this.formComponent.vehicleSearcher?.loaded && this.formComponent?.clientInvoice?.loaded
  }

  startRA() {
    if (!this.formComponent) { return; }
    if (this.formS.isOk(this.formComponent.form, this.formComponent.vehicleSearcher!.form)) {
      this.formComponent.form.patchValue({ "assigned_to": this.formComponent.workerSearcher?.selected?.id })
      let ra = new M_ActiveReception(
        this.formComponent!.vehicleSearcher!.selected!.client!.client_id!,
        this.formComponent!.clientInvoice!.selected?.client_id,
        this.formComponent!.vehicleSearcher!.selected!.vehicle_id,
        this.formComponent.form.value,
        this.data.action.groupsTaskToTabbedComments
      )
      this.templateService.showTemplateSteps("RA", undefined, undefined, this.formComponent.vehicleSearcher?.selected?.type, ra).afterClosed().subscribe
        ((dialog_res: { models: M_TemplateField[], files: M_TemplateField[] } | false) => {
          if (dialog_res != false) {
            this.apiS.transferAction(this.data.action.id, "or", { ra: ra }).then(res_create_or => {
              if (res_create_or.or_id) {
                ra.id = res_create_or.or_id;
                const a = this.apiS.saveTemplateFields(res_create_or.action_id!, dialog_res.models);
                const b = this.apiS.saveTemplateFiles(res_create_or.action_id!, dialog_res.files);
                forkJoin([a, b]).subscribe(res => {
                  this.previewS.showPreview("RA", res_create_or.token, undefined, ra.id, false, true);
                  this.dialogRef.close();
                })
              }
            });
          }
        });
    }
    else {
      this.confirmD.showError("Error en el formulario", "Se requiere como mínimo introducir un vehículo y sus kilómetros")
    }
  }

  trasnfer() {
    if (!this.clienSearcher.selected) { return; }
    this.apiS.transferAction(this.data.action.id, this.data.to, { client: this.clienSearcher.selected! }).then(res => {
      this.routerS.goWithQueryParams(ViewPath.createEditBudget, { budget: res })
      this.dialogRef.close();
    })
  }


  /** From budget to OR, if some group is 'Siniestro' 
   * To do : Recepció activa ?
  */
  transferSiniestroBudget() {
    this.apiS.transferAction(this.data.action.id, this.data.to, { client: this.data.action.client }).then(res => {
      this.routerS.goWithQueryParams(ViewPath.createEditBudget, { budget: res })
      this.dialogRef.close();
    })
  }

}
