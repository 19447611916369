import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ResponsiveService, RouterService, SearchService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../../api/api.service';
import { M_Action } from '../../models/M_Action';
import { M_Client } from '../../models/M_Client';
import { M_Product } from '../../models/M_Product';
import { M_Vehicle } from '../../models/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { environment } from 'src/environments/environment';
import { feature } from '../../features-controller/FeaturesController';


class ResultOption {
  constructor(public icon: string, public label: string, public action: () => void, public sublabel?: string) { }
}

class ResultOptionGroup {
  constructor(public groupId: groupEnum, public options: ResultOption[]) { }
}

enum groupEnum {
  USERS = "Clientes",
  VEHICLES = "Vehículos",
  PRODUCTS = "Productos",
  OR = "Órdenes de reparación",
  BUDGETS = "Presupuestos",
  ACTION = "Aciones"
}

@Component({
  selector: 'app-big-mama',
  templateUrl: './big-mama.component.html',
  styleUrls: ['./big-mama.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BigMamaComponent implements OnInit {
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('input') input! : ElementRef<HTMLElement>;
  myControl = new UntypedFormControl('');
  resultOptionsGroup: ResultOptionGroup[] = [];
  actions: ResultOption[];
  v = ViewPath;
  loading = false;
  finalsearh: string = "";

  constructor(private apiS: ApiService, private routerS: RouterService, private paramsS: ParamsService, private searchS: SearchService, public responsiveS : ResponsiveService) {
    this.actions = [new ResultOption("start", "Crear un cliente", () => this.routerS.goTo(this.v.createClient)),
    new ResultOption("start", "Crear un vehiculo", () => this.routerS.goTo(this.v.createvehicle)),
    new ResultOption("start", "Crear un producto", () => this.routerS.goTo(this.v.createProduct)),
    new ResultOption("start", "Crear factura", () => this.routerS.goTo(this.v.createBill)),
    new ResultOption("start", "Crear OR", () => this.routerS.goTo(this.v.createOr)),
    new ResultOption("start", "Crear presupuesto", () => this.routerS.goTo(this.v.createEditBudget))]
  }

  ngOnInit() { }

  search(searched: string) {

    if (searched == this.finalsearh){return}

    this.finalsearh = searched;

    if (searched == "") {
      this.clearGroups();
      this.loading = false;
    }
    else if (!this.loading) {
      this.loading = true;
      this.apiS.globalSearch(searched).then(resp => {

        this.loading = false;
        if (searched != this.finalsearh){
          this.search(this.finalsearh);
        }

        this.clearGroups();


        if (resp.clients) {
          for (let i = 0; i < resp.clients.length; i++) {
            let c = new M_Client(resp.clients[i])
            this.addOption(groupEnum.USERS, new ResultOption("person", c.getName(), () => this.paramsS.go(this.v.clientDetails, c.client_id), c.nif))
          }
        }
        if (resp.vehicles) {
          for (let i = 0; i < resp.vehicles.length; i++) {
            let v = new M_Vehicle(resp.vehicles[i])
            this.addOption(groupEnum.VEHICLES, new ResultOption(v.icon, v.getName(), () => this.paramsS.go(this.v.vehicleDetails, v.vehicle_id), v.license))
          }
        }
        if (resp.products) {
          for (let i = 0; i < resp.products.length; i++) {
            let p = new M_Product(resp.products[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption(p.icon, p.name, () => this.paramsS.go(this.v.productDetails, p.product_id!), p.reference))
          }
        }
        if (resp.or) {
          for (let i = 0; i < resp.or.length; i++) {
            let or = new M_Action(resp.or[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption("build", or.id.toString(), () => this.paramsS.go(this.v.editOr, or.id!), or.vehicle ? or.vehicle.license : "Sin vehículo asigando"))
          }
        }
        if (resp.budget) {
          for (let i = 0; i < resp.budget.length; i++) {
            let budget = new M_Action(resp.budget[i]);
            this.addOption(groupEnum.PRODUCTS, new ResultOption("request_quote", budget.id.toString(), () => this.paramsS.go(this.v.createEditBudget, budget.id!), budget.vehicle ? budget.vehicle.license : "Sin vehículo asigando"))
          }
        }
        /** Actions  */
        this.addActions(searched);

      })
    }
  }



  /** Se evita que en el input salga [object Object] al hacer click en una opción de 'autocompletado' */
  getOptionText(_v: any) {
    return ""
  }

  addOption(g: groupEnum, ro: ResultOption) {
    let groupFound = false;
    for (let i = 0; i < this.resultOptionsGroup.length; i++) {
      if (this.resultOptionsGroup[i].groupId == g) {
        this.resultOptionsGroup[i].options.push(ro);
        groupFound = true;
      }
    }
    if (!groupFound) {
      this.resultOptionsGroup.push(new ResultOptionGroup(g, [ro]))
    }
  }

  addActions(searched: string) {
    for (let i = 0; i < this.actions.length; i++) {
      if (this.searchS.match(searched, this.actions[i].label)) {
        this.addOption(groupEnum.ACTION, this.actions[i])
      }
    }
  }

  clearGroups() {
    this.resultOptionsGroup = [];
  }

  optionClick(o: ResultOption) {
    o.action();
    this.clearGroups();
    setTimeout(()=>{
      this.input.nativeElement.blur();
    })
  }

  hasResults() {
    return this.resultOptionsGroup.length != 0;
  }

  showNoData() {
    return this.resultOptionsGroup.length == 0 && this.myControl.value;
  }
}
