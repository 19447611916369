import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { CustomDate } from "@sinigual/angular-lib";
import { Console } from "console";

/**
 * [Adapter]
 * Used for calendar days start on Monday.
 * Used for check dates on inputs
 */

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    /** Date locales construction */
    constructor() {
        super('es');
        this.setLocale('es');
    }

    /**Necessray to override parse method.
     * The parse method is called when a form is validating a date input
     * This regex allows to introduce dates with 'es' locale withot the input containg an error
    */
    override parse(value: any): Date | null {
        if (!value) { return null }

        var finalValue: Date | string | null = null;
        try { 
            finalValue = new CustomDate(value, 'es').value;
        }
        catch (e: any) {
            return null;
        }
        return finalValue;
    }

    /** Mark Monday at as the first day of week */
    override getFirstDayOfWeek(): number {
        return 1;
    }
}