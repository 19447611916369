import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResponsiveService, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from 'src/app/core/models/M_Action';

@Component({
  selector: 'app-dashboard-or-table',
  templateUrl: './dashboard-or-table.component.html',
  styleUrls: ['./dashboard-or-table.component.css']
})
export class DashboardOrTableComponent implements OnInit {
  @Input() ors: M_Action[] = [];
  dataSource: MatTableDataSource<M_Action> = new MatTableDataSource<M_Action>([]);
  constructor(public responsiveS: ResponsiveService, private routerS: RouterService) { }

  ngOnInit() {
    const filteredOrs = this.ors.filter(or => !or.isClosed());
    this.dataSource.data = filteredOrs;
  }

  get displayedColumns() {
    return  this.responsiveS.w > 550 ?  ['vehicle', 'task', 'worker', 'status'] : ['vehicle', 'worker', 'status'];
  }

  goOr(or: M_Action) {
    this.routerS.goWithQueryParams(ViewPath.editOr, { or: or.id })
  }

  hasAssigned(element: M_Action) {
    return element.assigned != undefined;
  }

  getTask(e: M_Action): string {
    // Filtra los grupos que no están cerrados
    const openGroups = e.groups.filter(group => !group.isClosed());
    
    // Si hay grupos abiertos, devuelve el título del primero, de lo contrario "Sin datos"
    return openGroups.length ? openGroups[0].title : "Sin datos";
  }
}
