<p mat-dialog-title>Cancelar subscripción</p>

<mat-dialog-content>
    <p class="alert-text">Se cancelará tu subscripción a Eina.</p>
    <p>Introduce el código de seguridad <span class="fw500">{{fd.securityCode}}</span> para poder proceder</p>
    <div class="df jcc">
        <app-fourdigits #fd></app-fourdigits>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button [mat-dialog-close]="false" mat-button >Mantener mi plan actual</button>
    <button [mat-dialog-close]="true" mat-flat-button [disabled]="!fd.isOk()" color="warn" >Cancelar subscripción</button>
</mat-dialog-actions>