import { CompanyInterface, CustomDate, getArrayOf } from "@sinigual/angular-lib";
import { DEFAULT_IVA } from "src/app/views/profile/user-service.service";
import { M_Smpt } from "./M_Smpt";
import { M_TemplateField } from "./M_TemplateField";
import { T_Footers } from "src/app/views/company/documentation-config/documentation-config.component";
import { VehicleType } from "../enums/VehicleType";

export class M_Company implements CompanyInterface {
    logo: string | undefined;
    email: string;
    phone: string;
    name: string;
    cif: string;
    province: string;
    address: string;
    address2: string | undefined;
    city: number;
    zip: string;
    ccaa: number;
    primary_color: string | undefined;
    secondary_color: string | undefined;
    footer: string | undefined;
    footer2: string | undefined;
    footer3: string | undefined;
    footer4: string | undefined;
    footer5: string | undefined;
    places: number;
    default_vehicle : VehicleType = 1;
    smtp: M_Smpt | undefined;
    price_hour: number;
    iva: number;
    holidays: CustomDate[] = [];
    template_fields: M_TemplateField[] = [];
    id: number | undefined;

    constructor(d: any) {
        this.id = d.id
        this.logo = d.logo;
        this.name = d.name;
        this.email = d.email;
        this.phone = d.phone;
        this.cif = d.cif;
        this.address = d.address;
        this.address2 = d.address2? d.address2 : undefined;
        this.city = d.city;
        this.zip = d.zip;
        this.ccaa = d.ccaa;
        this.province = d.province;
        this.primary_color = d.primary_color;
        this.secondary_color = d.secondary_color;
        this.footer = d.footer;
        this.footer2 = d.footer2;
        this.footer3 = d.footer3;
        this.footer4 = d.footer4;
        this.footer5 = d.footer5;
        this.places = d.places ? d.places : 0;
        this.default_vehicle = d.default_vehicle ? d.default_vehicle : VehicleType.car;
        this.price_hour = d.price_hour ? d.price_hour.price_hour ? d.price_hour.price_hour : 0 : 0;
        this.smtp = d.smtp ? new M_Smpt(d.smtp) : undefined;
        this.iva = d.iva != undefined ? d.iva : DEFAULT_IVA;
        this.template_fields = getArrayOf(M_TemplateField, d.template_fields)
    }

    getMaxAppointments(): number | undefined {
        return undefined;
    }

    getPlaces(): number | undefined {
        return this.places;
    }

    hasInfo(): boolean {
        return (this.name != undefined && this.address != undefined && this.cif != undefined && this.city != undefined && this.zip != undefined);
    }

    getAllFootersByOrder() : T_Footers {
        return {
            footer2: this.footer2, // budget
            footer3: this.footer3, // ra
            footer4: this.footer4, // or
            footer5: this.footer5, // invoice
        }
    }
}