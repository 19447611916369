import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { DragTutorialComponent } from '../carga-taller/drag-tutorial/drag-tutorial.component';

import { UserServiceService } from '../profile/user-service.service';
import { ChangeDeliveryOrDialogComponent } from './change-delivery-or-dialog/change-delivery-or-dialog.component';
import { ORInterface } from '@sinigual/angular-lib';

@Component({
  selector: 'app-carga-taller-parent',
  templateUrl: './carga-taller-parent.component.html',
  styleUrls: ['./carga-taller-parent.component.css']
})
export class CargaTallerParentComponent {
  constructor(private userS: UserServiceService, private d: MatDialog, public wS: WorkloadService) {
    if (this.userS.needToShowWorkloadDialog) {
      this.d.open(DragTutorialComponent, { disableClose: true });
      this.userS.setWorkloadShowed(true);
    }
  }

  openWarnDialog(data : ORInterface) {
    this.d.open(ChangeDeliveryOrDialogComponent, {data : data});
  }
}