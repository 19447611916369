<div class="company-title">
    <app-view-title-lord-icon [pageTitle]="'EMPRESA'" [lordIcon]="subTitle"></app-view-title-lord-icon>
    <ng-template #subTitle>
        <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in"
            colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{warn()}},senary:#3a3347"
            class="lord-small">
        </lord-icon>
    </ng-template>
</div>

<mat-tab-group color="warn" mat-align-tabs="center" [selectedIndex]="selectedTab"
    (selectedTabChange)="tabChanged($event)" class="company-tabs">

    <!-- GENERAL -->
    <mat-tab [labelClass]="'onboarding-company-genera-tab'">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">store</mat-icon>
            General
        </ng-template>
        <app-company-general-info (changeTab)="selectedTab = $event"></app-company-general-info>
    </mat-tab>

    <!-- DOCUMENTATION -->
    <mat-tab [labelClass]="'onboarding-company-layouts-tab'">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon" [svgIcon]="'file-pdf'"></mat-icon>
            Documentos
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-documentation-config (changeTab)="selectedTab = $event"></app-documentation-config>
        </div>
    </mat-tab>

    <!-- USERS -->
    <mat-tab [labelClass]="'onboarding-company-users-tab'">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person</mat-icon>
            Usuarios
        </ng-template>
        <app-users></app-users>
    </mat-tab>

    <!-- SMTP -->
    <mat-tab [labelClass]="'onboarding-company-emails-tab'">
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">email</mat-icon>
            Correos
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-smtp (changeTab)="selectedTab = $event"></app-smtp>
        </div>
    </mat-tab>
</mat-tab-group>