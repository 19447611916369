<span mat-menu-item (click)="onClickOption.emit(0)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_n]" [showText]="false"></app-or-type-label>
</span>
<span *ngIf="feature.cargoInterno && showInterno" mat-menu-item (click)="onClickOption.emit(1)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_ci]" [showText]="false"></app-or-type-label>
</span>
<span *ngIf="feature.garantia" mat-menu-item (click)="onClickOption.emit(2)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_g]" [showText]="false"></app-or-type-label>
</span>

<span mat-menu-item (click)="onClickOption.emit(3)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_s]" [showText]="false"></app-or-type-label>
</span>