import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParameterStateComponent, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Invoice } from 'src/app/core/models/M_Invoice';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { M_Product } from 'src/app/core/models/M_Product';
import { PreviewService } from 'src/app/core/services/preview.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-abono-invoice',
  templateUrl: './abono-invoice.component.html',
  styleUrls: ['./abono-invoice.component.css']
})
export class AbonoInvoiceComponent extends ParameterStateComponent {

  invoice: M_Invoice | undefined;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public previewS: PreviewService) {
    super(routerS, route, ["or", "invoice"])
  }

  override onParam(param: string, value: string): void {
    if (param == "invoice") {
      let v = value.getNumber();
      this.apiS.bill(v).then(res => {
        this.invoice = res;
      })
    }
  }

  get hasOrOrInvoice() {
    return this.invoice != undefined;
  }

  get client() {
    return this.invoice ? this.invoice.client : undefined;
  }


  abonar() {
    this.apiS.abonar(this.invoice!.id!).then(res => {
      this.previewS.showPreview("A", res.token, undefined, res.id);
      this.routerS.goTo(ViewPath.facturas)
    })
  }

}
