import { M_Parser, or_types, or_types_normal } from "@sinigual/angular-lib";

export class M_RAGroup implements M_Parser {
    title: string = ""
    needsToBeParsed = true;
    or_type: or_types = or_types_normal;
    type: number = 0;
    constructor(title: string) {
      this.title = title;
    }
  
    parse() {
      this.type = this.or_type.num;
    }
  }