import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CustomDate, CustomFile, FileUploadComponent, ParameterStateComponent, RouterService, getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Client } from 'src/app/core/models/M_Client';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ModifyObservationsComponent } from './modify-observations/modify-observations.component';
import { ViewPath } from 'src/app/app-routing.module';
import { InvoiceFlotaOrDialogComponent } from './invoice-float-or-dialog/invoice-flota-or-dialog.component';
import { DialogAdvancePayComponent } from './dialog-advance-pay/dialog-advance-pay.component';
import { AdvancesArrayComponent } from 'src/app/core/components/advances-array/advances-array.component';
import { PreviewService } from 'src/app/core/services/preview.service';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { AdvancesClientDetailsDialogComponent } from './advances-client-details-dialog/advances-client-details-dialog.component';
import { PendingPaymentDialogComponent } from './pending-payment-dialog/pending-payment-dialog.component';
import { M_Company } from 'src/app/core/models/M_Company';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientDetailsComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;
  feature = feature;
  loaded: boolean = false;
  c: M_Client | undefined;
  company : M_Company | undefined;
  v = ViewPath;
  selectedPayment: any;
  @ViewChild("dniFrontComponent") dniFrontComponent?: FileUploadComponent;
  @ViewChild("dniBackComponent") dniBackComponent?: FileUploadComponent;
  @ViewChild('documentation') documentationComponent!: FileUploadComponent;

  constructor(route: ActivatedRoute, public override routerS: RouterService,
    private apiS: ApiService, public params: ParamsService, private d: MatDialog,
    private chdRef: ChangeDetectorRef, public subS: SubscriptionService, private previewS: PreviewService) {
    super(routerS, route, ["client"]);
  }

  ngOnInit(): void {
    this.apiS.company().then(res=>{
      this.company = res;
      
    });
   }

  override onState(state: any) {
    if (state instanceof M_Client) {
      this.c = new M_Client(state); //--> Es necesario crear un objeto nuevo. En caso contrario, los vehiculos pierden sus funciones de classe.
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }

  override onParam(_k: string, v: any) {
    this.apiS.getClientById(v).then(res => {
      if (res == undefined) {
        this.c = undefined;
      }
      if (res instanceof M_Client) {
        this.c = res;
        this.fillImages(this.c);
      }
      this.loaded = true;
      
      this.chdRef.detectChanges();
    })
  }
  
  emptyVehicles() {
    if (this.c != undefined) {
      return this.c.vehicles.length == 0;
    }
    else {
      return false;
    }
  }
  get companyInfo(){
     let disable  = true;
     if(this.company?.address != undefined && this.company.phone != undefined && this.company.email != undefined){
      disable = false;
     }
     return disable;
     
  }
  get getClientSince() {
    if (this.c) {
      let d = this.c.createdAt!.differenceWith(new CustomDate());
      return d.breakdown.years ? this.formatYear(d.breakdown.years) + " " + this.formatMonth(d.breakdown.months - (12 * d.breakdown.years)) :
        d.breakdown.months ? this.formatMonth(d.breakdown.months) + " " + this.formatDays(d.breakdown.days - (31 * d.breakdown.months)) :
          d.breakdown.days ? this.formatDays(d.breakdown.days) : 'Hoy';
    }
    return "";
  }

  formatDays(day: number) {
    return day <= 0 ? '' : day + " " + (day > 1 ? "días" : "día")
  }
  formatMonth(month: number) {
    return month <= 0 ? '' : month + " " + (month > 1 ? "meses" : "mes")
  }
  formatYear(years: number) {
    return years <= 0 ? '' : years + " " + (years > 1 ? "años" : "año")
  }

  fillImages(c: M_Client) {
    if (c.hasDniFront()) {
      this.dniFrontComponent!.setImageFromDatabase(c.dni_front!)
    }
    if (c.hasDniBack()) {
      this.dniBackComponent!.setImageFromDatabase(c.dni_back!)
    }

    if (c.documentation) {
      this.documentationComponent.setImagesFromDatabase(c.documentation)
    }
  }

  modifyObservations() {
    if (this.c) {
      this.d.open(ModifyObservationsComponent, { data: this.c.observations }).beforeClosed().subscribe(val => {
        if (val != undefined) {
          let clientCopy = new M_Client(this.c);
          clientCopy.observations = val;
          this.apiS.updateClient(clientCopy).then(_res => {
            this.c!.observations = val;
          });
        }
      })
    }
  }

  dniUpload(c: CustomFile, side: "dni_front" | "dni_back") {
    this.apiS.uploadImage(c, side, ["client_id", this.c!.client_id]).then(_res => {
    })
  }

  uploadDocumentImages(c: CustomFile) {
    this.apiS.uploadImage(c, "docu_img", ["client_id", this.c!.client_id]).then(res => {
      if (res.image_id) {
        c.database_id = res.image_id;
      }
    })
  }

  removeImage(c: CustomFile) {
    if (c.database_id) {
      this.apiS.deleteImage(c.database_id);
    }
  }

  openPendingPaymentDialog() {
    this.d.open(PendingPaymentDialogComponent, { data: this.c!, autoFocus: false })
  }

  openFlotasOrDialog() {
    this.d.open(InvoiceFlotaOrDialogComponent, { data: this.c, autoFocus: false });
  }

  openAdvancesDialog() {
    this.d.open(AdvancesClientDetailsDialogComponent, { data: this.c!, autoFocus: false })
  }

}
