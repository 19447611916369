<app-card class="user-opinion">
    <div>
        <div>
            <p class="opinion">{{userOpinion.opinion}}</p>
        </div>
        <div *ngIf="userOpinion.reviewLink">
            <a [href]="userOpinion.reviewLink" target="_blank" rel="noopener noreferrer">
                <p class="c_b fw400 underline_hover">Ver reseña</p>
            </a>
        </div>
        <div class="footer-container">
            <div class="user-image-container">
                <img [src]="userOpinion.img">
            </div>
            <div>
                <p class="user-name">{{userOpinion.name}}</p>
                <app-text-with-link class="user-position" [value]="userOpinion.position"></app-text-with-link>
            </div>
        </div>
    </div>
</app-card>