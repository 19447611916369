import { HARDCODED_TAX } from "../constants/constants";
import { M_ProductManager, invoiceCallProduct } from "./M_ProductManager";

export class M_CustomProduct extends M_ProductManager {
    reference: string | undefined;
    type: "customproduct" | "time" | "comment";
    comment : string | undefined;
    description : string | undefined;
    constructor(d: any) {
        super(d);
        this.reference = d.reference;
        if (d.is_hours) {
            this.type = "time"
        }
        else {
            this.type = "customproduct";
        }
        if(d.is_comment){
            this.type = "comment";
        }if(d.description){
            this.description = d.description;
        }
        this.tax = d.tax;
        this.comment = d.comment;
    }

    copyCoreAttributes(other: M_CustomProduct) {
        this.quantity = other.quantity;
        this.reference = other.reference;
        this.name = other.name;
        this.buy_price = other.buy_price;
        this.price = other.price;
        this.tax = other.tax;
        this.comment = other.comment;
    }

    override getInvoiceCallObject(modifyStock: boolean) {
        let obj: invoiceCallProduct = {
            prod_id: this.line_id!,
            quantity: !modifyStock ? "noaction" : undefined,
            custom: modifyStock ? this.quantity : undefined,
            discount: this.discount,
            /** Custom product specific properties */
            name: this.name,
            reference: this.reference,
            price: this.price,
            buy_price: this.buy_price,
            tax: this.tax,
        };
        return obj;
    }

    /** CLASS SEARCHER INTERFACE */
    get cs_id() { return this.line_id; }
    getInputText(): string {
        return this.name;
    }
    getOptionText(): [string, string] {
        return ([this.name, this.reference ? this.reference : ""])
    }
}