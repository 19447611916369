export const fakeBigMama = {
    "clients": [
        {
            "id": 17,
            "name": "123",
            "address": null,
            "city": null,
            "nif": "41231222j",
            "phone": null,
            "email": null,
            "created_at": "2022-09-19T11:34:33.000000Z",
            "updated_at": "2022-09-19T11:34:33.000000Z",
            "company_id": 4,
            "countable_num": null,
            "surname": "123",
            "zip": null,
            "phone2": null,
            "observations": null,
            "gdrp": 0,
            "email_always": 0,
            "deleted": 0,
            "company": 0,
            "address2": null,
            "province": null,
            "dob": "2022-09-19"
        },
        {
            "id": 19,
            "name": "Oriol",
            "address": null,
            "city": null,
            "nif": "12222123D",
            "phone": null,
            "email": null,
            "created_at": "2022-09-22T14:41:41.000000Z",
            "updated_at": "2022-09-22T14:41:41.000000Z",
            "company_id": 4,
            "countable_num": null,
            "surname": "Fig",
            "zip": null,
            "phone2": null,
            "observations": null,
            "gdrp": 0,
            "email_always": 0,
            "deleted": 0,
            "company": 0,
            "address2": null,
            "province": null,
            "dob": "2022-09-22"
        }
    ],
    "products": [
        {
            "id": 14,
            "reference": "1",
            "name": "1",
            "description": null,
            "price": 1,
            "tax": 21,
            "discount": null,
            "created_at": "2022-09-13T16:05:26.000000Z",
            "updated_at": "2022-09-13T16:05:26.000000Z",
            "company_id": 4,
            "family": "1",
            "brand": "1",
            "recycle": null,
            "stock": 1,
            "deleted": 0,
            "buy_price": 1
        }
    ],
    "vehicles": {
        "6": {
            "id": 18,
            "brand": "350 EXC  F SIX DAYS",
            "model": "123",
            "description": null,
            "license": "1231LMA",
            "created_at": "2022-09-19T11:35:49.000000Z",
            "updated_at": "2022-09-26T06:32:11.000000Z",
            "serie": "Sport",
            "chassis": "12312312312312311",
            "color": "Rosa",
            "cc": null,
            "engine_type": null,
            "license_date": null,
            "km": null,
            "fabrication_date": null,
            "hire_date": null,
            "electric": 0,
            "insurance_id": null,
            "deleted": 0,
            "last_itv": "2022-09-24",
            "next_itv": "2022-09-14",
            "client": {
                "id": 17,
                "name": "123",
                "address": null,
                "city": null,
                "nif": "41231222j",
                "phone": null,
                "email": null,
                "created_at": "2022-09-19T11:34:33.000000Z",
                "updated_at": "2022-09-19T11:34:33.000000Z",
                "company_id": 4,
                "countable_num": null,
                "surname": "123",
                "zip": null,
                "phone2": null,
                "observations": null,
                "gdrp": 0,
                "email_always": 0,
                "deleted": 0,
                "company": 0,
                "address2": null,
                "province": null,
                "dob": "2022-09-19",
                "laravel_through_key": 18
            }
        }
    }
}