<app-close-dialog-button [onCloseData]="false"></app-close-dialog-button>

<p mat-dialog-title>{{dialogTitle}}</p>

<mat-dialog-content>

    <div class="pdf-component-parent" [ngClass]="{'dn' : stepShowing != TS.PREVIEW}">
        <app-pdf [pdf_data]="data.pdf" *ngIf="containPreviewStep"></app-pdf>
    </div>

    <div [ngClass]="{'dn' : stepShowing != TS.FIELDSCLIENT && stepShowing != TS.FIELDSWORKSHOP}" *ngIf="loaded">
        <ng-container *ngIf="containFieldsClientStep">
            <ng-container *ngIf="clientFields && clientFields.length; else noFields">
                <app-template-fields-form #clientFieldsComponent [ngClass]="{'dn' : stepShowing != TS.FIELDSCLIENT}"
                    [fields]="clientFields!" [isCompanyView]="false"
                    [vehicleType]="data.dialog.vehicleType"></app-template-fields-form>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="containFieldsWorkshopStep">
            <ng-container *ngIf="workShopFields && workShopFields.length; else noFields">
                <app-template-fields-form #workShopFieldsComponent [ngClass]="{'dn' : stepShowing != TS.FIELDSWORKSHOP}"
                    [fields]="workShopFields!" [isCompanyView]="false"
                    [vehicleType]="data.dialog.vehicleType"></app-template-fields-form>
            </ng-container>
        </ng-container>

        <!-- No custom template fields -->
        <ng-template #noFields>
            <div>Sin campos a rellenar</div>
        </ng-template>

    </div>

</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="center">

    <!-- PREVIEW STEPPER   |  THE ORDER IS DEFINED ON TemplateFieldsService : getStepsByDocument() -->
    <ng-container *ngIf="loaded">
        <mat-stepper [(selectedIndex)]="index" class="template-stepper" color="warn">
            <ng-container *ngFor="let step of data.dialog.steps">
                <mat-step state="authWorkshop" *ngIf="step == TS.FIELDSWORKSHOP">
                    <ng-template matStepLabel>
                        <span class="dno525">Autorizaciones taller</span>
                        <span class="dbo525">Aut. taller</span>
                    </ng-template>
                    <span *ngIf="!isEndStep">
                        <button mat-button color="warn" matStepperNext>Siguiente</button>
                        <button mat-button color="primary" (click)="closeDialog()"><mat-icon>skip_next</mat-icon>Omitir todo</button>
                    </span>
                    <span *ngIf="isEndStep">
                        <button mat-flat-button color="warn" (click)="closeDialog()">Aceptar</button>
                    </span>
                </mat-step>
                <mat-step state="preview" *ngIf="step == TS.PREVIEW">
                    <ng-template matStepLabel>
                        <span class="dno525">Previsualización</span>
                        <span class="dbo525">Prev.</span>
                    </ng-template>
                    <span *ngIf="!isEndStep">
                        <button mat-button color="warn" matStepperNext>Siguiente</button>
                        <button mat-button color="primary" (click)="closeDialog()"><mat-icon>skip_next</mat-icon>Omitir todo</button>
                    </span>
                    <span *ngIf="isEndStep">
                        <button mat-flat-button color="warn" (click)="closeDialog()">Aceptar</button>
                    </span>
                </mat-step>
                <mat-step state="authClient" *ngIf="step == TS.FIELDSCLIENT">
                    <ng-template matStepLabel>
                        <span class="dno525">Autorizaciones cliente</span>
                        <span class="dbo525">Aut. cliente</span>
                    </ng-template>
                    <span *ngIf="!isEndStep">
                        <button mat-button color="warn" matStepperNext>Siguiente</button>
                        <button mat-button color="primary" (click)="closeDialog()"><mat-icon>skip_next</mat-icon>Omitir todo</button>
                    </span>
                    <span *ngIf="isEndStep">
                        <button mat-flat-button color="warn" (click)="closeDialog()">Aceptar</button>
                    </span>
                </mat-step>
            </ng-container>
            <ng-template matStepperIcon="edit">
                <mat-icon> {{stepShowing == TS.PREVIEW ? 'find_in_page' : stepShowing == TS.FIELDSWORKSHOP ? 'store' : 'person'}}</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="preview">
                <mat-icon>find_in_page</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="authClient">
                <mat-icon>person</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="authWorkshop">
                <mat-icon>store</mat-icon>
            </ng-template>
        </mat-stepper>
    </ng-container>

    <app-skeleton *ngIf="!loaded" [width]="200" [height]="20"></app-skeleton>

    <!--
        <button mat-icon-button [disabled]="index == 0"
            (click)="previousStep()"><mat-icon>navigate_before</mat-icon></button>
        <div class="step-counter" *ngIf="loaded">
            <p class="nmb"> {{index + 1}} / {{totalSteps}}</p>
        </div>
        <app-skeleton *ngIf="!loaded" [width]="20" [height]="10"></app-skeleton>
        <button mat-icon-button [disabled]="isEndStep || !loaded"
            (click)="nextStep()"><mat-icon>navigate_next</mat-icon></button>

        <button mat-flat-button [disabled]="!isEndStep" color="primary" (click)="closeDialog()">Aceptar</button>
    -->
</mat-dialog-actions>