import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { M_TemplateField, templateAddType, templateTypedoc } from '../../models/M_TemplateField';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignatureComponent } from '../signature/signature.component';
import { environment } from 'src/environments/environment';
import { CustomDate, FirmaData, ImagePreviewService } from '@sinigual/angular-lib';
import { VehicleType } from '../../enums/VehicleType';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';

/** The full form. Contains subforms of I_TemplateForm*/
interface I_FullForm {
  [key: string]: FormGroup<I_TemplateForm>;
}

interface I_TemplateForm {
  add_content: FormControl<string>;
  add_typedoc: FormControl<string>;
  add_type: FormControl<templateAddType>;
  add_contentnext: FormControl<string>;
  design_id: FormControl<number>;
  adv_value: FormControl<any | null>;
  value_id: FormControl<number | null>;
  add_general: FormControl<number | null>;
  add_active_from: FormControl<number | null>;
}

@Component({
  selector: 'app-template-fields-form',
  templateUrl: './template-fields-form.component.html',
  styleUrls: ['./template-fields-form.component.css']
})
export class TemplateFieldsFormComponent implements OnInit {

  @Input() fields: M_TemplateField[] = [];
  @Input() typeFilter?: templateTypedoc;

  /** Is this component showing on company view ? */
  @Input() isCompanyView!: boolean;

  /** Used for the 'damages' field. **/
  @Input() vehicleType?: VehicleType;

  /** Some input change */
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  form: FormGroup<I_FullForm> = new FormGroup<I_FullForm>({});
  env = environment;

  constructor(private chdRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private previewS: ImagePreviewService) {

  }

  ngOnInit(): void {
    if (this.fields) {
      this.generateAllSubforms();
    }
  }

  ngAfterViewInit() {
    this.fields.forEach((f, index) => {
      /** Detect adv_value change */
      let subForm = this.form.get(index.toString());
      subForm?.get('adv_value')?.valueChanges.subscribe(v => {
        this.onChangeAdvValue(v, f)
      });
    })
  }

  /** Initialize / Re-initizlize the form with the fields on the component */
  init(fields: M_TemplateField[]) {
    this.fields = fields;
    this.form = new FormGroup<I_FullForm>({});
    this.generateAllSubforms();
    this.chdRef.detectChanges();
  }

  filterFields() {
    if (this.typeFilter) {
      return this.fields.filter(f => f.add_typedoc == this.typeFilter);
    }
    else {
      return this.fields;
    }
  }

  cleanForm() {
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  private generateAllSubforms() {

    this.fields = this.filterFields();

    this.fields.forEach((f, index) => {
      this.form.addControl(index.toString(), this.generateSubForm(f), { emitEvent: false });
    })

    this.cleanForm();

    /** If something on the form changes, emit the event. NOT WORKING! */
    this.form.valueChanges.subscribe(val => {
      if (this.form.dirty) {
        this.onChange.emit();
      }
    })
  }

  /** On advVlue changes to some value */
  private onChangeAdvValue(value: any, field: M_TemplateField) {
    if (field.add_active_from) {
      let disableOther = value == true;
      this.fields.forEach((f, index) => {
        let subform = this.form.get(index.toString());
        let designIdControl = subform?.get('design_id');
        if (subform && designIdControl && designIdControl.value && designIdControl.value == field.add_active_from) {
          console.log("here")
          if (disableOther) { subform.get('adv_value')?.disable({ emitEvent: false }); }
          else { subform.get('adv_value')?.enable({ emitEvent: false }); }
        }
      })
    }
    this.chdRef.detectChanges();
  }

  private generateSubForm(f: M_TemplateField) {
    let subField = new FormGroup<I_TemplateForm>({
      add_content: new FormControl(f.add_content, { nonNullable: true }),
      add_typedoc: new FormControl(f.add_typedoc, { nonNullable: true }),
      add_type: new FormControl(f.add_type, { nonNullable: true }),
      add_contentnext: new FormControl(f.add_contentnext, { nonNullable: true }),
      design_id: new FormControl(f.design_id, { nonNullable: true }),
      adv_value: new FormControl(f.adv_value, { nonNullable: true }),
      value_id: new FormControl(f.value_id, { nonNullable: false }),
      add_general: new FormControl(f.value_id, { nonNullable: false }),
      add_active_from: new FormControl(f.add_active_from, { nonNullable: false }),
    });
    if (f.add_general && !this.isCompanyView || !f.add_general && this.isCompanyView) {
      subField.get('adv_value')?.disable();
    }
    return subField;
  }

  private getSubFormByIndex(i: number) {
    return this.form.get(i.toString()) as FormGroup<I_TemplateForm>;
  }

  isDisabled(t: M_TemplateField) {
    let index: number = this.fields.findIndex(template =>
      template.design_id == t.design_id &&
      template.value_id == t.value_id &&
      template.add_type == t.add_type);
    let control = this.getSubFormByIndex(index);
    return control.get('adv_value')!.disabled;
  }

  dateFilter: (date: Date | null) => boolean =
    (date: Date | null) => {
      return true;
    };
  dateFilterPost: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) { return false; }
      return date > new CustomDate().value;
    };
  dateFilterAnt: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (!date) { return false; }
      return date < new CustomDate().value;
    };

  /** On click add content (checkbox) */
  onClickAddContent(t: M_TemplateField) {
    if (this.isDisabled(t) || t.add_type != "boolean") { return; }
    t.adv_value = t.adv_value == true ? false : true;
  }

  getAddContentPointer(t: M_TemplateField) {
    return !this.isDisabled(t) && t.add_type == 'boolean';
  }

  /** Signature or File upload (Company View)*/
  onFileUpload(t: M_TemplateField, file?: File) {
    if (file) {
      t.adv_value = file; //Set the  file as the value of the template field
      let url = window.URL.createObjectURL(t.adv_value); //Generate a url with the file
      t.refreshFileUrl(this.sanitizer.bypassSecurityTrustResourceUrl(url)); //Refreshing the file url of the template field
      this.onChange.emit() // Emit changes
    }
  }

  setFieldValue(file : File | undefined, t : M_TemplateField){
    t.adv_value = file; //Set the  file as the value of the template field
    this.onChange.emit() // Emit changes
  }

  showPreview(f: M_TemplateField) {
    if (f.parsedFileURL) {
      this.previewS.openPreview(f.parsedFileURL as string);
    }
  }

  /** Get only the NON-File type models */
  getModels() {
    return this.fields.filter(f => f.add_type != "signature" && f.add_type != "damages");
  }

  /** Get all the file Models */
  getFilesModels() {
    return this.fields.filter(f => f.add_type == "signature" || f.add_type == "damages");
  }

  getAllModels() {
    return this.fields;
  }

  invalidDate(i: number) {
    let subForm = this.getSubFormByIndex(i);
    let control = subForm?.get('adv_value');
    return control?.touched && control.dirty && !control.value;
  }


  distinctClick(menu: MatMenuTrigger, signatureComponent: SignatureComponent, t: M_TemplateField) {

    if (!this.isCompanyView) { return; }

    /** If this component is on the company screen, we allow the user to choose whether to upload a photo or sign manually  */
    if (t.add_type == 'signature' && t.add_general) {
      menu.openMenu();
    }
    /** On the client side (or, budgets...) always open the signature dialog. */
    else {
      this.openSignatureDialog(signatureComponent, t)
    }
  }

  openSignatureDialog(signatureComponent: SignatureComponent, t: M_TemplateField) {
    signatureComponent.openSignatureDialog(this.automaticSignatureData(t, signatureComponent));
  }

  automaticSignatureData(t: M_TemplateField, signatureComponent?: SignatureComponent) {
    /** Company side data */
    if (signatureComponent) {
      let firmaData: FirmaData = {};
      if (t.add_type == 'signature') {
        firmaData = {
          title: "Firma digital",
          subtitle: "Firme en el siguiente recuadro :",
          showTerms: false,
        }
      }
      else if (t.add_type == 'damages') {
        firmaData = {
          title: "Marcar daños",
          subtitle: "Maque los daños del vehículo",
          background: this.vehicleType && this.vehicleType == VehicleType.bike ? "./assets/img/damages/damages-bike.png" : "./assets/img/damages/damages-car.png",
          showTerms: false,
          pencilColor: "#ff0000",
          repeatText: "Repetir"
        }
      }
      return firmaData;

    }
    /** Client side */
    else {
      let firmaData: FirmaData = {};
      if (t.add_type == 'signature') {
        firmaData = {
          title: undefined,
          subtitle: undefined,
          background: undefined,
          showTerms: false,
          showActions : false
        }
      }
      else if (t.add_type == 'damages') {
        firmaData = {
          title: undefined,
          subtitle: undefined,
          background: this.vehicleType && this.vehicleType == VehicleType.bike ? "./assets/img/damages/damages-bike.png" : "./assets/img/damages/damages-car.png",
          showTerms: false,
          showActions : false,
          pencilColor: "#ff0000",
          repeatText: "Repetir"
        }
      }
      return firmaData;
    }
  }

  deselect(mrg: MatRadioGroup, val: boolean, index: number) {
    if (mrg.selected?.value == val) {
      let subForm = this.form.get(index.toString());
      subForm?.get('adv_value')?.setValue(null);
    }
  }

}
