import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClassSearcherComponent, FormService, ParameterStateComponent, RouterService, SnackService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { VehicleOwnerSearchCreateComponent } from 'src/app/core/components/vehicle-owner-search-create/vehicle-owner-search-create.component';
import { VehicleformComponent } from 'src/app/core/components/vehicleform/vehicleform.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';
import { UserServiceService } from '../profile/user-service.service';
import { DialogConfirmPlateComponent } from 'src/app/core/components/vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.css']
})
export class CreateVehicleComponent extends ParameterStateComponent {
  @ViewChild(VehicleOwnerSearchCreateComponent) ownerComponent!: VehicleOwnerSearchCreateComponent;
  @ViewChild(VehicleformComponent, { static: true }) vehicleForm!: VehicleformComponent;
  @ViewChild(ClassSearcherComponent, { static: true }) searcherForm!: ClassSearcherComponent<M_Vehicle>;
  v = ViewPath;
  c: M_Client | undefined;
  ve: M_Vehicle | undefined;
  state: "alta_vehiculo" | "create" | "edit" | undefined;
  isVehicle:boolean = false;
  isClient:boolean = false;
  constructor(private fs: FormService, routerS: RouterService, private paramS: ParamsService, private apiS: ApiService, route: ActivatedRoute, 
    public params: ParamsService, private snackS: SnackService, private userS: UserServiceService,
    public dialog:MatDialog) {
    super(routerS, route, ["client", "vehicle"]);
  }

  override noParams() {
    this.setUpCreation();
  }

  override onParam(k: string, v: any) {
    if (k == "client") {
      this.apiS.getClientById(v).then(res => {
        this.c = res;
        this.state = "alta_vehiculo";
        
        this.vehicleForm.form.get('battery_1_no')!.disable();
        this.vehicleForm.form.get('battery_2_no')!.disable();
      })
    }
    else if (k == "vehicle") {
      this.apiS.getVehicleById(v).then(res => {
        this.ve = res;
        this.state = "edit";
        this.setupEdit();
      })
    }
    else {
      this.setUpCreation();
    }

  }

  getTitle() {
    switch (this.state) {
      case "alta_vehiculo":
        return "DAR DE ALTA UN VEHÍCULO"
      case "create":
        return "CREAR UN VEHÍCULO"
      case "edit":
        return "GUARDAR"
    }
    return ""
  }

  getButton() {
    switch (this.state) {
      case "alta_vehiculo":
        return "Vincular nuevo vehículo"
      case "create":
        return "Crear vehículo"
      case "edit":
        return "Guardar"
    }
    return ""
  }

  /** Here, the default cration vehicle type */
  setUpCreation() {
    this.vehicleForm.Iscreate = true;
    this.apiS.vehiclesType().then(res => {
      this.vehicleForm.form.get('battery_1_no')!.disable();
      this.vehicleForm.form.get('battery_2_no')!.disable();
      this.vehicleForm?.form.patchValue({ type: res });
      this.state = "create";
    })
  }

  setupEdit() {


    if (this.ve != undefined) {
      this.vehicleForm.form.patchValue({
        type: this.ve.type,
        brand: this.ve.brand,
        model: this.ve.model,
        description: this.ve.description,
        license: this.ve.license,
        serie: this.ve.serie,
        chassis: this.ve.chassis,
        color: this.ve.color,
        insurance: this.ve.insurance,
        cc: this.ve.cc,
        engine_type: this.ve.engine_type ? Number(this.ve.engine_type) : "",
        license_date: this.ve.license_date?.datePickerFormat,
        last_itv: this.ve.last_itv?.datePickerFormat,
        next_itv: this.ve.next_itv?.datePickerFormat,
        battery_1_no: this.ve.battery_1_no,
        battery_2_no: this.ve.battery_2_no,
        km: this.ve.km,
        clientInvoice_id: this.ve.clientInvoice?.client_id
      })
    }

    this.vehicleForm.initialChasisValue = this.ve?.chassis;
    this.vehicleForm.Iscreate = false;
    // DEBE PODER MODIFICAR 
    this.vehicleForm.form.get('license')?.disable();
  }

  create() {

    this.vehicleForm.form.patchValue({ serie: this.vehicleForm.serieToggle.value() })
    let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());
    if (this.state == "create") {
      /** Si se linka el veículo con un cliente ya existente */
      if (this.ownerComponent.ch1?.checked) {
        if (this.fs.isOk(this.vehicleForm.form, this.ownerComponent.searchForm.form)) {
          this.apiS.checkPlate(this.vehicleForm.form.get('license')?.value).then(res=>
            {
            if(res instanceof M_Vehicle){
              this.isVehicle = true;
              console.log('es cliente');
              if(vehicle.vehicle_id != res.vehicle_id){

                this.dialog.open(DialogConfirmPlateComponent,{data:{
                  title: "¡Atención!",
                  message: "Ya existe un vehiculo con el ",
                  param: this.vehicleForm.form.get('license')?.value,
                  message2: "Recordamos que no se pueden crear dos vehiculos con la misma matrícula",
                  error:true,
                }}).afterClosed().subscribe(res=>{
                  this.vehicleForm.form.get('license')?.setErrors({found:true});
                }
                )
              }
            }else{
              this.apiS.createVehicleWithClientId(new M_Vehicle(this.vehicleForm.form.value), this.ownerComponent.cSelected!).then(res => {
                this.params.go(this.v.vehicleDetails, res.vehicle_id)
                this.snackS.show("¡Vehículo creado y vinculado con éxito!")
              });
            }
          });
        }
      }
      /** Si se crea vehículo y cliente */
      else {
          let c = new M_Client(this.ownerComponent.clientForm.form.value);

          let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());
          if (this.ownerComponent.searchForm.form) {
            this.apiS.checkPlate(this.vehicleForm.form.get('license')?.value).then(res=>
              {
              if(res instanceof M_Vehicle){
               
                this.isVehicle = true;
                console.log('es cliente');
                if(vehicle.vehicle_id != res.vehicle_id){
  
                  this.dialog.open(DialogConfirmPlateComponent,{data:{
                    title: "¡Atención!",
                    message: "Ya existe un vehiculo con el ",
                    param: this.vehicleForm.form.get('license')?.value,
                    message2: "Recordamos que no se pueden crear dos vehiculos con la misma matrícula",
                    error:true,
                  }}).afterClosed().subscribe(res=>{
                    this.vehicleForm.form.get('license')?.setErrors({found:true});
                  }
                  )
                }
                
              }else{
                this.apiS.checkDni(this.ownerComponent.clientForm.form.get('nif')?.value).then(res=>
                  {
                    if(res instanceof M_Client){
                      this.isClient = true;
                      console.log('es cliente');
                      let fieldName = !this.ownerComponent.clientForm.isSpanish ? 'identificador' : this.ownerComponent.clientForm.isCompany ? "CIF" : "DNI"
                      if(c.client_id != res.client_id){
                        this.dialog.open(DialogConfirmPlateComponent,{data:{
                          title: "¡Atención!",
                          message: "Ya existe un cliente con el ",
                          param: this.ownerComponent.clientForm.form.get('nif')?.value,
                          message2: "Recordamos que no se pueden crear dos clientes con el mismo DNI",
                          error:true,
                        }}).afterClosed().subscribe(res=>{
                          this.ownerComponent.clientForm.form.get('nif')?.setErrors({found:true});
                        }
                        )
                      }
                  
                    }else{
                      c.vehicles = [new M_Vehicle(this.vehicleForm.form.value)];
                      this.apiS.createClient(c).then(res => {
                        this.params.go(this.v.vehicleDetails, res.vehicle_id);
                        this.snackS.show("¡Vehículo y cliente creados con éxito!")
                      })
                    }

                  } 
                  );
               
              }
            });
          }
      }
    }
    /**Editamos solo la moto, sin cliente */
    
    else if (this.state == "edit") {

      if (this.fs.isOk(this.vehicleForm.form)) {

        let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());
        vehicle.vehicle_id = this.ve!.vehicle_id;
        if(this.vehicleForm.initialInputValue == vehicle.license){
          console.log("es la misma entrada que tenia ");
        }else{
          console.log("es diferente");
        }
          this.apiS.checkPlate(this.vehicleForm.form.get('license')?.value).then(res=>
            {
            if(res instanceof M_Vehicle){

              this.isVehicle = true;
              console.log('es cliente');
              if(vehicle.vehicle_id != res.vehicle_id){

                this.dialog.open(DialogConfirmPlateComponent,{data:{
                  title: "¡Atención!",
                  message: "Ya existe un vehiculo con el ",
                  param: this.vehicleForm.form.get('license')?.value,
                  message2: "Recordamos que no se pueden crear dos vehiculos con la misma matrícula",
                  error:true,
                }}).afterClosed().subscribe(res=>{
                  this.vehicleForm.form.get('license')?.setErrors({found:true});
                }
                )
              }else{
                this.apiS.editVehicle(vehicle).then(_resp => {
                  this.params.go(this.v.vehicleDetails, vehicle.vehicle_id);
                  this.snackS.show("¡Vehículo editado con éxito!")
                })
              }
              
            }else{
              this.apiS.editVehicle(vehicle).then(_resp => {
                this.params.go(this.v.vehicleDetails, vehicle.vehicle_id);
                this.snackS.show("¡Vehículo editado con éxito!")
              })
            }
            } 
          );
      }
    }
    /** Dar de alta un vehículo a un cliente especifico */
    else if (this.state == "alta_vehiculo") {
      if (this.fs.isOk(this.vehicleForm.form)) {
        let vehicle = new M_Vehicle(this.vehicleForm.form.getRawValue());
        vehicle.client_id = this.c?.client_id;
        this.apiS.editVehicle(vehicle).then(_resp => {
          this.params.go(this.v.clientDetails, this.c!.client_id)
          this.snackS.show("¡Vehículo añadido con éxito!")
        })
      }
    }
  }

  goBackVehicle() {
    if (!this.ve) { return; }
    this.paramS.go(ViewPath.vehicleDetails, this.ve.vehicle_id);
  }

}
