import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService, FormService, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Register } from 'src/app/core/models/M_Register';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { Console } from 'console';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public form: UntypedFormGroup;
  v = ViewPath;
  registerDone = false;
  @ViewChild(MatCheckbox) checkBoxTerms!: MatCheckbox;
  constructor(private routerS: RouterService, private formBuilder: UntypedFormBuilder, private formService: FormService, private apiS: ApiService, public d: MatDialog, private confirmS: ConfirmDialogService) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.form.patchValue({ password_confirmation: '' })
  }

  ngAfterContentInit(): void {
    this.form.patchValue({ password_confirmation: '' })
    /*const img = document.querySelector(`#img`)! as HTMLImageElement;
    img.onload = () => {
      this.magnify(img, 4);
    };*/
  }

  createCompany() {
    if (this.formService.isOk(this.form)) {
      this.apiS.createCompany(new M_Register(this.form.value)).then(_res => {
        this.registerDone = true;
        this.confirmS.show({
          title: "¡Usuario creado con éxito!",
          body: "Se ha registrado en Eina. Puede iniciar sesión haciendo click en el botón de abajo",
          confirmTxt: "Iniciar sesión",
          showCancel: false
        }).afterClosed().subscribe(res => {
          this.routerS.goWithQueryParams(this.v.registerCompleted, { email: this.form.get('email')?.value });
        })
      });
    }
  }

  openTermsAndConditions(e: Event) {
    e.preventDefault();
    this.d.open(TermsAndConditionsComponent, { autoFocus: false }).afterClosed().subscribe(
      res => {
        if (res) {
          this.checkBoxTerms.checked = true;
        }
      }
    )
  }

  goLogin() {
    this.routerS.goTo(this.v.login);
  }

  /**
  magnify(img: HTMLImageElement, zoom: number): void {
    const glass = document.createElement("div");
    glass.classList.add("img-magnifier-glass")
    img.parentElement!.insertBefore(glass, img);

    glass.style.backgroundImage = `url('${img.src}')`;
    glass.style.backgroundRepeat = "no-repeat";
    console.log(img.clientWidth, "w")
    console.log(img.clientHeight, "h")
    glass.style.backgroundSize = `${img.clientWidth * zoom}px ${img.clientHeight * zoom}px`;

    const bw = 3;
    const w = glass.offsetWidth / 2;
    const h = glass.offsetHeight / 2;

    const moveMagnifier = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      const pos = this.getCursorPos(e);
      let x = pos.x;
      let y = pos.y;

      if (x > img.width - w / zoom) { x = img.width - w / zoom; }
      if (x < w / zoom) { x = w / zoom; }
      if (y > img.height - h / zoom) { y = img.height - h / zoom; }
      if (y < h / zoom) { y = h / zoom; }

      glass.style.left = `${x - w}px`;
      glass.style.top = `${y - h}px`;
      glass.style.backgroundPosition = `-${x * zoom - w + bw}px -${y * zoom - h + bw}px`;
    };

    const removeGlass = (e: MouseEvent | TouchEvent) => {
      glass.style.display = "none"
    }

    const addGlass = (e: MouseEvent | TouchEvent) => {
      glass.style.display = "block"
    }

    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    
    glass.addEventListener("mouseleave", removeGlass);
    img.addEventListener("mouseenter", addGlass);

    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
  }



  getCursorPos(e: MouseEvent | TouchEvent): { x: number, y: number } {
    let x = 0, y = 0;
    const img = document.querySelector(`#img`)! as HTMLImageElement;
    const a = img.getBoundingClientRect();

    if (e instanceof MouseEvent) {
      x = e.pageX - a.left;
      y = e.pageY - a.top;
    } else if (e instanceof TouchEvent) {
      x = e.touches[0].clientX - a.left;
      y = e.touches[0].clientY - a.top;
    }

    x -= window.pageXOffset;
    y -= window.pageYOffset;

    return { x, y };
  }
   */

}
