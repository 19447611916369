import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CustomColor, CustomDate, RouterService, downloadBlob } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';
import { M_Company } from '../../models/M_Company';
import { ParamsService } from '../../services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { PDF_DATA, PdfComponent } from '../pdf/pdf.component';
import { InvoicePayementFormComponent } from '../invoice-payement-form/invoice-payement-form.component';
import { M_Invoice } from '../../models/M_Invoice';
import { PayAllInvoiceDialogComponent } from 'src/app/views/bills/pay-check-control/pay-all-invoice-dialog.component';

export interface PREVIEW_DATA {
  showORcharge? : boolean
  canClose?: boolean,
  minifyDownload?: boolean
}

export type company_data = { footer?: string, p: CustomColor, s: CustomColor, c: M_Company }

@Component({
  selector: 'app-preview',
  templateUrl: './preview-dialog-component.html',
  styleUrls: ['./preview-dialog-component.css']
})
export class PreviewDialog {
  loading: boolean = false;
  v = ViewPath;
  @ViewChild(PdfComponent, { static: true }) pdfComponent!: PdfComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { pdf: PDF_DATA, preview: PREVIEW_DATA, invoice: M_Invoice }, public dRef: MatDialogRef<PreviewDialog>, private apiS: ApiService, private paramS: ParamsService, private routerS: RouterService,
    private d: MatDialog) {
    console.log("que pasa¿?");
  }

  /** Download the invoice by the token */
  download() {
    if (this.pdfComponent.url && this.pdfComponent.file) {
      downloadBlob(this.pdfComponent.file, this.downloadName);
    }
  }

  downloadXML() {
    if (this.data.pdf.token) {
      this.apiS.downloadEinvoiceXML(this.data.pdf.token).then(res => {
        if (res) { downloadBlob(res, this.downloadName); }
      });
    }
  }

  get downloadName() {
    return (this.data.pdf.id ? (this.data.pdf.id + " - ") : '') + this.nameByType + " - " + new CustomDate().dayMonthYearFormat;
  }

  get nameByType() {
    switch (this.data.pdf.preview_type) {
      case "B": return "PRESUPUESTO";
      case "A": return "ABONO";
      case "I": return "FACTURA";
      case "OR": return "FACTURA DE OR";
      case "RA": return "APERTURA OR";
      case "AN": return "ANTICIPO";
      case "AL": return "ALBARÁN";
      case "FL": return "FACTURA FLOTAS";
      default: return "";
    }
  }

  goOr() {
    if (this.data.pdf.id) {
      this.paramS.go(this.v.editOr, this.data.pdf.id)
    }

  }
  goCreateInvoice(invoice: M_Invoice) {
    this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog', data: { invoice: invoice, isDialog: true } }).afterClosed().subscribe(res => {
      this.routerS.goToWithState(this.v.facturas, invoice.id);
    });
  }
  goCobrarInvoice() {

    if (this.data.pdf.preview_type == 'OR' && this.data.pdf.id != undefined) {
      this.apiS.getInvoiceId(this.data.pdf.id).then(res => {
        this.goCreateInvoice(res);
      });
    }
    else if (this.data.invoice) {
      console.log(this.data.invoice);
      this.goCreateInvoice(this.data.invoice);
    }

  }
  goCobrarAbono() {
    if(this.data.pdf.id){
      this.apiS.getInvoiceId(this.data.pdf.id).then(res => {
        this.goCreateInvoice(res);

      });
      
    }
  }
  goOrView() {
    this.routerS.goTo(this.v.or)
  }

  goInvoiceView(invoice_id? : number) {
    if (invoice_id){
      this.routerS.goToWithState(this.v.facturas, invoice_id);
    }
    else{
      this.routerS.goTo(this.v.facturas);
    }
    
  }

}