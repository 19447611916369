<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Facturar</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <p mat-dialog-subtitle>Fecha de facturación</p>
    <mat-form-field appearance="outline" class="w100">
        <mat-label>Fecha</mat-label>

        <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1" (click)="picker_1.open()"
            [formControl]="date" appInvoiceDayChecker [fc]="date" #IDC="appInvoiceDayChecker">

        <!--TODO: Its possible to make a component of this? -->
        <mat-hint *ngIf="IDC.minDate" [ngClass]="{'c_r' : IDC.error}">{{IDC.error ? IDC.error : IDC.hint}}</mat-hint>
        <mat-spinner *ngIf="!IDC.loaded" matPrefix color="warn" [diameter]="18"
            style="float: right; margin-left: 8px"></mat-spinner>
        <!--<mat-error #error>{{IDC.error}}</mat-error>!-->
        <!-- TODO end-->

        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
        <mat-datepicker #picker_1></mat-datepicker>
    </mat-form-field>

    <div *ngIf="requiredExpedient">
        <mat-form-field appearance="outline" class="w100">
            <mat-label>Nº Expediente</mat-label>
            <input [formControl]="expedient" matInput placeholder="Nº Expediente">
        </mat-form-field>
    </div>


</div>
<div *ngIf="!expedient.value && this.data?.cli_is_invoice_e" class="df jcc c_r p10">Estás a punto de facturar sin número de expediente.</div>
<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>

    <button color="warn" mat-flat-button [disabled]="!date.valid" [mat-dialog-close]="{date : date.value, expedient : expedient.value}"
    cdkFocusInitial>Facturar</button>
</div>