import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent {
  recognition: any;
  isRecording: boolean = false;
  @Input() canEdit = true;
  @Output() onUserType = new EventEmitter();
  @Output() onUserSpeach = new EventEmitter<string>();
  
  @ViewChild("content", { static: true }) content!: ElementRef<HTMLDivElement>;

  constructor(private el: ElementRef, private chdRef: ChangeDetectorRef) {
    const { webkitSpeechRecognition }: IWindow = window as any;
    this.recognition = new webkitSpeechRecognition();
    this.recognition.onresult = (event: any) => {
      let result = event.results[0][0].transcript;
      let finalText = this.el.nativeElement.querySelector(".content").innerText += result;
      this.setValue(finalText);
      this.onUserSpeach.emit(finalText);
    };

    this.recognition.onstart = () => {
      this.isRecording = true;
      this.chdRef.detectChanges();
      console.log("Recording started...");
    };

    this.recognition.onend = () => {
      this.isRecording = false;
      this.chdRef.detectChanges();
      console.log("Recording ended.");
    };
  }

  setValue(val: string, updateForm = true) {
    console.log("Patching notes !");
    if (this.canEdit) {
      this.content.nativeElement.innerText = val;
    }
  }

  record(event: Event) {
    if (this.isRecording){this.recognition.stop();}
    this.recognition.start();
  }

}

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}