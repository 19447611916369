import { Component, Input } from '@angular/core';
import { ProductLineTableService } from '../../services/product-line-table.service';

@Component({
  selector: 'app-products-line-header',
  templateUrl: './products-line-header.component.html',
  styleUrls: ['./products-line-header.component.css']
})
export class ProductsLineHeaderComponent {
  @Input() showLastLine : boolean = true;
  @Input() isInterno : boolean = false;
  constructor(public productsLineS : ProductLineTableService){}
}
