<div class="advanced-details-container" *ngIf="c != undefined || (c == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado clientes'" [v]="v.clientes" [show]="loaded"></app-go-back>
    <div class="Client">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="delete_c">
            <div *ngIf="c != undefined">
                <div class="df fdc aic jcc">
                    <p class="fsxl fw200">{{c.getName()}}</p>
                    <p class="fsm fw300" style="letter-spacing: 2px">{{c.nif.toUpperCase()}}</p>
                    <button mat-button [mat-menu-trigger-for]="menu" color="accent">Ver más</button>
                    <mat-menu #menu="matMenu" class="card-row-menu">
                        <app-card-row-content *ngIf="c != undefined" [data]="[
                        ['Cliente desde', getClientSince],    
                        ['Email', c.email, {email : c}],
                        ['Teléfono', c.phone],
                        ['Teléfono 2', c.phone2],
                        ['Dirección', c.getLocation()],
                        [c.is_company ? 'invisible' : 'Fecha nacimiento', c.dob?.dayMonthYearFormat, c.dob? {dob : c.dob} : undefined],
                        ['Descuento', c.getDiscount()],
                        ['Última modificación', c.updatedAt?.shortFormat]]">
                        </app-card-row-content>
                        <app-card-row-content *ngIf="c != undefined" class="fullW"
                            [data]="[['Observaciones', c.observations, {modifiable : true}]]" [hiddenOnLoad]="true"
                            (onClickModify)="modifyObservations()">
                        </app-card-row-content>
                    </mat-menu>

                    <lord-icon *ngIf="!c.is_company; else iconCompany" src="https://cdn.lordicon.com/fmasbomy.json"
                        trigger="in" colors="primary:#121331,secondary:{{warn()}},tertiary:#fae6d1"
                        state="hover-looking-around" class="lord-big">
                    </lord-icon>
                    <ng-template #iconCompany>
                        <lord-icon #iconCompany src="https://cdn.lordicon.com/waxtfsst.json" trigger="in"
                            colors="primary:#121331,secondary:{{accent()}},tertiary:#3a3347,quaternary:#ebe6ef,quinary:#f24c00"
                            class="lord-big">
                        </lord-icon>
                    </ng-template>
                    <button mat-flat-button color="warn" (click)="params.go(v.createClient, c!.client_id!)"
                        class="one-line mb10">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </div>
            </div>
            <app-card-footer *ngIf="c != undefined">
                <app-whatsapp [phone]="c.phone"></app-whatsapp>
                <app-email-button [email]="c.email"></app-email-button>
                <app-phone-button [phone]="c.phone"></app-phone-button>
            </app-card-footer>
            <ng-template #delete_c>
                <app-delete-button class="leftItem" [item]="c!"></app-delete-button>
            </ng-template>
        </app-card>
    </div>
    <div class="Vehicle">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="c != undefined">
                <app-card-title>{{c.vehicles.length == 1 ?'Vehículo' : 'Vehículos'}}</app-card-title>
                <app-client-details-vehicle-table [client]="c"></app-client-details-vehicle-table>
            </div>
        </app-card>
    </div>
    <div class="Details">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="c != undefined">
                <app-card-subtitle [first]="true">Última intervención</app-card-subtitle>
                <app-last-intervention-or [or]="c.lastOr"></app-last-intervention-or>
                <mat-divider></mat-divider>
                <p></p>
                <div class="df fdr jcsa aifs fww">

                    <button mat-button [color]="c.paymentsTotal ? 'warn' : 'primary'"
                        (click)="openPendingPaymentDialog()">
                        <mat-icon class="vam">receipt</mat-icon>
                        A cobrar :
                        <span class="fw600" money [val]="c.paymentsTotal"></span>
                    </button>

                    <button mat-button [color]="c.ORtotal ? 'warn' : 'primary'" (click)="openFlotasOrDialog()">
                        <mat-icon class="vam">build</mat-icon>
                        A facturar :
                        <span class="fw600" money [val]="c.ORtotal"></span>
                    </button>

                    <button mat-button color="primary" [disabled]="companyInfo" (click)="openAdvancesDialog()" [matTooltip]="companyInfo ? 'Debes rellenar los datos de empresa para poder hacer un anticipo':'Crea anticipos para el cliente'">
                        <mat-icon class="vam">payments</mat-icon>
                        A cuenta :
                        <span class="fw600" money [val]="c.advancesTotal"></span>
                        
                    </button>
                </div>

                <p></p>

                <mat-divider></mat-divider>

            </div>
            <div class="documentos">
                <div class="dni-section">
                    <app-card-subtitle class="df aic" [first]="true" [noMarginBottom]="true">DNI o NIF
                        <button mat-icon-button [disabled]="true">
                            <mat-icon style="color: var(--p3);">badge</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <div class="df fww jcfs">
                        <div>
                            <lib-file-upload #dniFrontComponent (stateChanged)="dniUpload($event, 'dni_front')"
                                [showMax]="false" [maxSize]="6" [crop]="true" [dni]="true"></lib-file-upload>
                            <p class="nmb c_t2 tac fsi">Parte frontal</p>
                        </div>
                        <div>
                            <lib-file-upload #dniBackComponent (stateChanged)="dniUpload( $event, 'dni_back')"
                                [showMax]="false" [crop]="true" [maxSize]="6" [dni]="true"></lib-file-upload>
                            <p class="nmb c_t2 tac fsi">Parte trasera</p>
                        </div>
                    </div>
                </div>
                <div>
                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Documentación
                        <button mat-icon-button (click)="documentation.clickInputTrigger()" color="p3">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <div>
                        <p *ngIf="!documentation.hasFiles()" class="c_t2">Aún no has subido imágenes</p>
                        <lib-file-upload [phoneMultipleUpload]="true" #documentation class="hidde_add"
                            [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                            (stateChanged)="uploadDocumentImages($event)" (onRemoveImage)="removeImage($event)">
                        </lib-file-upload>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
    <div class="OR">
        <app-card [contentLoaded]="loaded" [noData]="!c?.invoices?.length && !c?.actions?.length ">
            <div *ngIf="c != undefined">
                <app-card-title>Historial</app-card-title>
                <app-action-history [ngClass]="{'notDisplay': history.data.length == 0}" #history [clientSide]="false"
                    [actions]="c!.actions" [invoices]="c!.invoices"></app-action-history>
            </div>
        </app-card>
    </div>
</div>


<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el cliente'" [goText]="'Ir al listado de clientes'"
        [view]="v.clientes">
    </app-model-not-found>
</ng-template>