import { action_types, CustomDate, CustomFile, match, ORInterface, or_status, or_status_close, or_status_open, or_types, or_types_todefine, SearchFiltrable, getArrayOf } from "@sinigual/angular-lib"
import { getColorByVehicle } from "../custom-classes/Colors";
import { M_Client } from "./M_Client"
import { M_GroupTask } from "./M_GroupTask";
import { M_User } from "./M_User";
import { M_Vehicle } from "./M_Vehicle"
import { M_RAGroup } from "./M_RAGroup";
import { M_TotalsBreakdown } from "../services/totals-calculator.service";
import { M_doc } from "./M_doc";

export class M_Action implements SearchFiltrable, ORInterface {

    id: number;
    action_id: number;
    type_id: action_types;
    client: M_Client | undefined;
    clientInvoice: M_Client | undefined;
    client_id: number;
    company_id: number;
    description: string;
    origen: null
    schedule: CustomDate;
    title: string;
    type: or_types[] = [];
    created_at: CustomDate;
    updated_at: CustomDate;
    user_id: number;
    vehicle: M_Vehicle | undefined;
    vehicle_id: number;
    assigned_to: number | undefined;
    groups: M_GroupTask[] = [];
    status: or_status = new or_status("Abierta");
    delivery: CustomDate | undefined;
    notes: string | undefined;
    km: number | undefined;
    signature: CustomFile | undefined;
    images: CustomFile[] = [];
    assigned: M_User | undefined;
    total: number | undefined;
    price_hour: number = 0;
    token: string | undefined;
    has_or: number | undefined;
    /** Avoid dialog on exit confirmation on deleteing the action */
    deleted: boolean = false;
    /** TODO */
    has_budget: boolean = false;
    fuel: number = 1;
    title_id: string | undefined;
    urlpdf  : M_doc [] = [];
    totalToInvoice : number = 0;

    constructor(d: any) {
        this.id = d.id;
        this.action_id = d.action_id;
        this.type_id = d.type_id ? new action_types(d.type_id) : new action_types("Orden de reparación");
        this.status = d.state ? new or_status(d.state) : new or_status("Abierta");
        this.client_id = d.client_id;
        this.company_id = d.company_id;
        this.description = d.description;
        this.origen = d.origen;
        this.schedule = new CustomDate(d.schedule);
        this.delivery = d.delivery ? new CustomDate(d.delivery) : undefined;
        this.title = d.title;
        this.created_at = new CustomDate(d.created_at);
        this.updated_at = new CustomDate(d.updated_at);
        this.user_id = d.user_id;
        this.vehicle_id = d.vehicle_id;
        this.assigned_to = d.assigned_to;
        this.client = d.client ? new M_Client(d.client) : undefined;
        this.notes = d.notes;
        this.km = d.km;
        this.signature = d.signature;
        this.price_hour = d.price_hour ? d.price_hour : 0;
        this.token = d.token;
        this.has_or = d.has_or;
        this.has_budget = d.has_budget;
        this.fuel = d.fuel != undefined ? d.fuel : 1;
        this.title_id = d.title_id;
        this.totalToInvoice = d.totalToInvoice ? d.totalToInvoice : 0;
        
        if (d.urlpdf) {
            this.urlpdf = getArrayOf(M_doc, d.urlpdf);
        }
        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name == "action_image") {
                    this.images.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id))
                }
                else if (d.images[i].name == "signature") {
                    this.signature = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id)
                }
            }
        }
        if (d.vehicle) {
            this.vehicle = new M_Vehicle(d.vehicle);
        }
        if (d.client) {
            this.client = new M_Client(d.client);
        }
        if (d.clientInvoice) {
            this.clientInvoice = new M_Client(d.clientInvoice);
        }
        if (d.groups) {
            for (let i = 0; i < d.groups.length; i++) {
                this.addGroupTask(new M_GroupTask(d.groups[i]));
            }
        }
        if (d.assigned && !Array.isArray(d.assigned)) {
            this.assigned = new M_User(d.assigned);
        }
        this.refreshType();
        this.total = d.total ? d.total : this.calculateTotal();
    }


    get id_to_show() {
        return this.action_id;
    }

    get title_to_show() {
        return this.title_id ? this.title_id : "Sin título";
    }

    get closedTotal() {
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed()) {
                t++;
            }
        })
        return t;
    }

    get totalInternosClosed(){
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed() && g.type.cargo) {
                t++;
            }
        })
        return t;
    }

    /** Get the action invoice client. **/
    get defaultInvoiceClient(): M_Client | undefined {
        return this.clientInvoice || this.client
    }

    /** Is the client associated with the OR the same as the client to invoice?*/
    get sameClientAndInvoiceClient() {
        return this.clientInvoice == undefined || this.clientInvoice.client_id == this.client?.client_id
    }

    /* To do : Refactor totals. Need to know the client invoiced to */
    get closedTotalMoney() {
        let t = 0;
        this.groups.forEach(g => {
            if (g.isClosed()) {
                t += g.getTotalBreakdown(this.defaultInvoiceClient).total;
            }
        })
        return t;
    }

    hasClient() {
        return this.client != undefined;
    }

    hasImages() {
        return this.images.length != 0;
    }

    addGroupTask(gt: M_GroupTask) {
        gt.action_id = this.id;
        this.groups.push(gt);
        this.refreshStatus();
        this.refreshType();
    }

    hasVehicle() {
        return this.vehicle != undefined;
    }

    refreshType() {
        this.type = [];
        if (this.groups) {
            for (let i = 0; i < this.groups.length; i++) {
                const index = this.type.findIndex(tipo => tipo.num == this.groups[i].type.num);
                if (index === -1) {
                    this.type.push(this.groups[i].type);
                }
            }
        }

        if (this.type.length == 0) {
            this.type.push(or_types_todefine);
        }
    }

    refreshStatus() {
        if (this.groups.length == 0) {
            this.status = or_status_open;
        }
        else {
            let lowestStatus: number | undefined = undefined;
            for (let i = 0; i < this.groups.length; i++) {
                if (lowestStatus == undefined) {
                    lowestStatus = this.groups[i].state.num;

                }
                else {
                    if (this.groups[i].state.num < lowestStatus) {
                        lowestStatus = this.groups[i].state.num;
                    }
                }

            }
            this.status = new or_status(lowestStatus!);
        }
    }

    getGroupsByInvoice(): M_GroupTask[] {
        let groupsGroupedByToken: M_GroupTask[] = []
        this.groups.forEach(g => {
            if (g.token) {
                if (groupsGroupedByToken.every(grouped => grouped.token != g.token)) {
                    groupsGroupedByToken.push(g);
                }
            }
        })
        return groupsGroupedByToken;
    }


    hasGroupsAndTasks() {
        return this.hasGroups() && this.hasTasks(true);
    }

    isBudgetClosable() {
        return this.isBudget() && !this.isClosed() && this.hasGroups() && this.allGroupsCanBeInvoiced();
    }

    allGroupsCanBeInvoiced() {
        return this.groups.every(g => { return g.hasTasks() && g.allTasksHasProduct() })
    }

    hasClosedGroups() {
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].isClosed()) {
                return true;
            }
        }
        return false;
    }

    hasPendingToInvoiceGroups() {
        for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].isClosed() && !this.groups[i].type.cargo) {
                return true;
            }
        }
        return false;
    }



    allInvoiced() {
        if (this.groups.length == 0) { return false; }
        return this.groups.every(g => g.isInvoiced())
    }

    hasGroups() {
        return this.groups.length != 0;
    }

    hasTasks(checkProducts: boolean) {
        let g = false;

        for (let i = 0; i < this.groups.length; i++) {
            if (checkProducts) {
                if (this.groups[i].hasTasks()) {
                    for (let j = 0; j < this.groups[i].tasks.length; j++) {
                        if (!this.groups[i].tasks[j].isEmpty()) {
                            g = true;
                        }
                    }
                }
            }
            else {
                if (this.groups[i].hasTasks()) {
                    g = true;
                }
            }
        }
        return g;
    }

    getStateColor() {
        if (this.status.name == "Abierta") { return 'red' }
        else if (this.status.name == "Cerrada") { return 'orange' }
        else { return 'green' }
    }

    getTypeColor() {
        if (this.type.length == 1 && this.type[0].num == -1) {
            return "gray"
        }
        return ""
    }

    getStrTypes() {
        let val = "";
        this.type.forEach(v => {
            val += v.name + " "
        })
        return val;
    }

    /* To do : Refactor totals. No puedo calcular el total si no se indica a que cliente se a facturdo el group task */
    calculateTotal() {
        if (!this.client) {
            return 0;
        }
        else {
            let total: number = 0;
            this.groups.forEach(g => {
                total += g.getTotalBreakdown(this.client).total;
            })
            return total;
        }
    }

    get groupsTaskToTabbedComments() {
        let tc: M_RAGroup[] = [];
        this.groups.map(g => {
            tc.push(new M_RAGroup(g.title))
        })
        return tc;
    }

    get isSomeGroupSiniestro() {
        return this.type.some(e => e.siniestro);
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        if (!this.isBudget) {
            return match(text,
                this.id.toString(),
                this.title_id,
                this.client ? this.client.getName() : '',
                this.vehicle ? this.vehicle.model : '',
                this.vehicle ? this.vehicle.brand : '',
                this.vehicle ? this.vehicle.license : '',
                this.total ? this.total.toString() : '',
                this.status ? '' : '',
                this.type ? '' : '',
                this.schedule ? this.schedule.dayMonthYearFormat : '')
        }
        else {
            return match(text,
                this.id.toString(),
                this.title_id,
                this.client ? this.client.getName() : '',
                this.vehicle ? this.vehicle.model : '',
                this.vehicle ? this.vehicle.brand : '',
                this.vehicle ? this.vehicle.license : '',
                this.created_at ? this.created_at.dayMonthYearFormat : '',
                this.total ? this.total.toString() : '',
                this.status ? '' : '',
                this.type ? '' : '')
        }

    }

    isOr() {
        return this.type_id.isOr;
    }

    isBudget() {
        return this.type_id.isBudget;
    }

    isClosed() {
        return this.status.num == or_status_close.num;
    }

    isDone() {
        if (this.isBudget()) {
            return this.isClosed();
        }
        return this.allInvoiced()
    }

    /** Interface implementation */

    vehicleName(): string | undefined {
        return this.vehicle?.getName();
    }
    vehicleId(): number | undefined {
        return this.vehicle?.vehicle_id;
    }
    vehicleBrand(): string | undefined {
        return this.vehicle?.brand;
    }
    vehicleLicense(): string | undefined {
        return this.vehicle?.license;
    }
    vehicleColor(): string {
        return getColorByVehicle(this.vehicle);
    }

    clientName(): string | undefined {
        return this.client?.getName();
    }
    clientNif(): string | undefined {
        return this.client?.getName();
    }
    clientId(): number | undefined {
        return this.client?.client_id;
    }
    clientEmail(): string | undefined {
        return this.client?.email;
    }
    clientPhone(): string | undefined {
        return this.client?.getPhoneIfExists();
    }

}