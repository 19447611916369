<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Modificar fecha de entrega</p>

<!-- Dialog content -->
<div mat-dialog-content *ngIf="datesStatus as status" style="max-width: 400px;">
    <p>
        <span class="fw500 fss" *ngIf="status == 'equal'">
            La <span class="c_a">fecha de realización</span> es la misma que la <span class="c_p">fecha de
                entrega</span>
        </span>
        <span class="fw500 fss" *ngIf="status == 'less'">
            La <span class="c_a">fecha de realización</span> es posterior a la <span class="c_p">fecha de entrega</span>
        </span>
        <span class="fw500 fss" *ngIf="status == 'bigger'">
            La <span class="c_a">fecha de realización</span> es anterior a la <span class="c_p">fecha de entrega</span>
        </span>
        <mat-icon class="ml5 vam" [ngClass]="{'c_o' : status == 'equal', 'c_r' : status == 'less', 'c_p' : status == 'bigger'}">
            {{status == 'less' ? 'error' : status == 'bigger' ? 'thumb_up' : 'running_with_errors'}}
        </mat-icon>
    </p>

    <mat-calendar [dateClass]="dateClass" [startAt]="selected" [dateFilter]="maxScheduleAndSunday"
        [(selected)]="selected"></mat-calendar>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" mat-flat-button (click)="changeOrDelivaery()">OK</button>
</div>