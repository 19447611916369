import { CustomDate, ORGroupInterface, or_status, or_status_close, or_status_open, or_types, or_types_normal } from "@sinigual/angular-lib";
import { M_Task } from "./M_Task";
import { M_Product } from "./M_Product";
import { M_CustomProduct } from "./M_CustomProduct";
import { M_TotalsBreakdown, calculateTotalsBreakdown } from "../services/totals-calculator.service";
import { M_Client } from "./M_Client";

export const notitle = "Nueva Intervención";

export class M_GroupTask implements ORGroupInterface {
    action_id: number;
    /** Client who has been invoiced */
    client: M_Client | undefined;
    id: number;
    invoice_id: number | undefined;
    state: or_status;
    state_id: number;
    tasks: M_Task[] = [];
    title: string;
    type: or_types;
    created_at: CustomDate;
    updated_at: CustomDate;
    token?: string; //invoice token
    invoice_name?: string; //invoice name

    constructor(d: any) {
        this.id = d.id;
        this.action_id = d.action_id;
        this.state = d.state_id ? new or_status(d.state_id) : or_status_open;
        this.state_id = d.state_id;
        if (d.tasks) {
            for (let i = 0; i < d.tasks.length; i++) {
                this.tasks.push(new M_Task(d.tasks[i]));
            }
        }
        this.title = d.title ? d.title : notitle;
        this.type = d.type ? new or_types(d.type) : or_types_normal;
        this.created_at = new CustomDate(d.created_at);
        this.updated_at = new CustomDate(d.updated_at);
        if (d.invoice) {
            this.token = d.invoice.token;
            this.invoice_name = d.invoice_name;
        }
        else {
            this.token = d.token ? d.token : undefined;
        }

        if (d.client) {
            this.client = new M_Client(d.client);
        }
    }


    getTitle(): string {
        return this.title;
    }

    changeStatus(status: or_status) {
        this.state = status;
        this.generateStatus()
    }

    generateStatus() {
        return this.state;
    }

    addTask(t: M_Task) {
        t.group_id = this.id;
        this.tasks.push(t);
    }

    /*onDragAndDropReorder() {
        for(let i = 0; i < this.tasks.length; i++){
            this.tasks[i].order = i;
          }
    }*/

    getTasksWithId() {
        let tasks = this.tasks.filter(t => { return t.task_id != undefined }).map(t => t.task_id!);;
        return tasks;
    }

    removeTask(t: M_Task) {
        this.tasks.removeElement(t);
    }

    hasTasks() {
        return this.tasks.length != 0;
    }

    getAllProducts() {
        let products: (M_Product | M_CustomProduct)[] = []
        this.tasks.forEach(t => {
            let p = t.getProduct();
            if (p) {
                products.push(p);
            }
        })
        return products;
    }

    hasChanges() {
        let c = false;
        this.tasks.forEach(t => {
            if (t.changes) {
                c = true;
            }
        })
        return c;
    }

    allTasksHasProduct() {
        return this.tasks.every(t => !t.isEmpty())
    }

    markTaskAsSaved() {
        this.tasks.forEach(t => {
            t.changes = false;
        })
    }

    isInvoiced() {
        return this.state.invoiced;
    }

    isClosed() {
        return this.state.num == or_status_close.num;
    }

    getTotalBreakdown(client?: M_Client): M_TotalsBreakdown {

        if (this.type.cargo) {return calculateTotalsBreakdown(this.getAllProducts(), undefined, true);}

        if (this.state.invoiced && this.client) {
            return calculateTotalsBreakdown(this.getAllProducts(), this.client)
        }
        return calculateTotalsBreakdown(this.getAllProducts(), client)
    }
}