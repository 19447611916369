<app-page-structure (onChangeSelected)="isEdit = false" (onclickAddNew)="openDialog()" class="users-table"
    [masterClass]="'user'" [autocompleteID]="'users'" searchLabel="Buscar usuario" circleIcon="person_add"
    circleIconClass="onboarding-company-users-create" [addNewView]="undefined" [data]="[]"
    [displayedHeader]=" ['Nombre', 'Email']" [displayedColumns]="['name', 'email']" [circularLetter]="true"
    [specialText]="[undefined, undefined]" [specialClass]="[undefined, undefined]" [card]="card"
    [circleIconLoading]="!subS.status" [disableCircle]="disableCircle" [circleTooltip]="circleTooltip"
    [circleIconLoading]="loading" [showPagination]="false" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()" (onChangeSelected)="onChange()">
    <ng-template #card>
        <app-card *ngIf="ps.showItem()"
            [cornerMatMenu]="sessionS.isMe(ps.selected!.id!) || ps.selected!.is_admin ? no_data : delete_u">
            <div *ngIf="!isEdit; else editFrom">
                <div class="df fdc aic">

                    <app-card-title>{{ps.selected!.name}}</app-card-title>
                    <app-card-subtitle [first]="true">{{ps.selected!.email}}</app-card-subtitle>
                    <app-circular-letter [user]="ps.selected" [showFullName]="false" [showTooltip]="false"
                        [big]="true"></app-circular-letter>
                    <button mat-flat-button color="warn" (click)="setEditMode()" class="one-line mt10 mb10">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </div>
                <app-layout-send-email #alm [temaplte]="'user'" *ngIf="!ps.selected!.confirm_sent"
                    [endpoint]="resendConfirm.bind(this)"></app-layout-send-email>

                <p class="tac" *ngIf="ps.selected!.confirm_sent"><mat-icon class="vam c_b mr5">verified</mat-icon>Usuario confirmado</p>
                
            </div>
            <ng-template #editFrom>
                <app-card-title (click)="setEditMode()">{{ps.selected!.name}}</app-card-title>
                <app-userform #uf [u]="ps.selected!"></app-userform>
                <div class="df jcc">
                    <button style="margin-right: 20px" (click)="setEditMode()" mat-flat-button>Cerrar</button>
                    <button appTrialbutton (click)="editUser(uf, ps.selected!)" mat-flat-button
                        color="warn">Guardar</button>
                </div>
            </ng-template>
            <ng-template #delete_u>
                <app-delete-button class="leftItem" [item]="ps.selected!"
                    (onDelete)="onDeleteUser($event)"></app-delete-button>
            </ng-template>
            <ng-template #no_data></ng-template>
        </app-card>
    </ng-template>
</app-page-structure>