import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ClientDetailsComponent } from 'src/app/views/client-details/client-details.component';
import { M_Vehicle } from '../../models/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { M_Client } from '../../models/M_Client';

/** Double selector!!! */
@Component({
  selector: 'app-vehicle-row, app-client-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.css']
})
export class ClientVehicleRowComponent implements OnInit {
  v = ViewPath;
  @Input() item! : M_Vehicle | M_Client;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() public advanced : ClientDetailsComponent, public params : ParamsService) { }

  ngOnInit(): void {
  }

  goItem(){
    if (!this.item.deleted) {
      this.onClick.emit(); 
      if (this.item instanceof M_Vehicle){
        this.params.go(this.v.vehicleDetails, this.item.vehicle_id)
      }
      else{
        this.params.go(this.v.clientDetails, this.item.client_id)
      }
    }
  }

  isV(d : any) : d is M_Vehicle{
    return d instanceof M_Vehicle;
  }
  handleClick(event: MouseEvent) {
    if (!this.item.deleted) {
      this.onClick.emit(); 
      if (event.ctrlKey || event.metaKey) { // Verifica si la tecla Control o Command está presionada
        event.preventDefault(); // Prevenir el comportamiento por defecto
        this.openInNewTab();
      } else {
        this.navigate();
      }
    }
  }
  handleContextMenu(event: MouseEvent) {
    event.preventDefault(); // Prevenir el menú contextual por defecto
    this.openInNewTab();
  }

  openInNewTab() {

    if (this.item instanceof M_Vehicle){
      
      const url = "vehicledetails?vehicle="+this.item.vehicle_id;
      window.open(url, '_blank');

    }
    else{
      const url = "clientdetails?client="+this.item.client_id;
      window.open(url, '_blank');
    }
  }
  navigate(){
    if(this.item instanceof M_Vehicle){
      this.params.go(this.v.vehicleDetails, this.item.vehicle_id)
    }else{
      this.params.go(this.v.clientDetails, this.item.client_id)
    }
  }
}
