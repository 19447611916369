<!--Nº or, estado, operario, client, fecha entrega → Si sobra espacio (matricula, marca modelo…)-->
<table mat-table [dataSource]="dataSource" *ngIf="ors.length; else noData">
    
    <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef> Vehículo </th>
        <td mat-cell *matCellDef="let element"> {{element.vehicle?.getName()}}</td>
    </ng-container>

    <ng-container matColumnDef="task"  *ngIf="responsiveS.w > 550">
        <th mat-header-cell *matHeaderCellDef> Intervención </th>
        <td mat-cell *matCellDef="let element" style="color: var(--t2) !important;"> {{getTask(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="worker">
        <th mat-header-cell *matHeaderCellDef class="start-align" style="width: 95px;"> Operario </th>
        <td mat-cell *matCellDef="let element" class="start-align">
            <app-circular-letter [showFullName]="false" [small]="responsiveS.w <= 514" *ngIf="element.assigned" [showTooltip]="true"
                [user]="element.assigned"></app-circular-letter>
            <p *ngIf="!element.assigned" class="c_t2 nmb">Sin operario</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element">
            <app-or-status-label [showIcon]="false" class="df jcc" [status]="element.status"></app-or-status-label>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goOr(row)"></tr>
</table>

<ng-template #noData>
    <p class="c_t2" style="padding: 0px 10px 0px 10px">No tienes ninguna OR para hoy</p>
</ng-template>

<div style="height: 10px;"></div>