import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { PreviewService } from '../../services/preview.service';
import { M_Invoice } from '../../models/M_Invoice';
import { M_Action } from '../../models/M_Action';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'app-documentation-pdf-menu',
  templateUrl: './documentation-pdf-menu.component.html',
  styleUrls: ['./documentation-pdf-menu.component.css']
})
export class DocumentationPdfMenuComponent {

  @Input() invoice!: M_Invoice | undefined;
  @Input() action!: M_Action | undefined;
  @ViewChildren(MatMenuItem) pdfNumber?: QueryList<MatMenuItem>;
  constructor(public previewS: PreviewService) { }

  get showDocumentationButton() {
    if (this.invoice) { return this.invoice.token; }
    if (this.action) { return this.action.token; }
    return false;
  }

  get numberOfPDF() {
    return this.pdfNumber?.length;
  }
}
