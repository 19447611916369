import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CustomDate } from '@sinigual/angular-lib';
import { CustomData } from '../../interfaces/CustomData';

type keyValue = [string | number | 'invisible' | 'spacer', string | number | undefined, CustomData?];

@Component({
  selector: 'app-card-row-content',
  templateUrl: './card-row-content.component.html',
  styleUrls: ['./card-row-content.component.css']
})
export class CardRowContentComponent {
  @HostBinding('class') c = 'card-row';
  @Input() data!: keyValue[];
  @Input() hiddenOnLoad: boolean = false;
  @Input() noPaddingLeft: boolean | undefined;
  @Input() freeAlign: boolean = false;
  @Output() onClickModify: EventEmitter<any> = new EventEmitter();
  hidden = false;

  ngAfterContentInit() {
    if (!this.data[0][1]) {
      this.hiddenOnLoad = false;
      this.hidden = false;
    }
    else {
      this.hidden = this.hiddenOnLoad;
    }
  }

  toggleVisibility(e : Event) {
    this.hidden = !this.hidden;
    e.stopPropagation();
    e.preventDefault();
  }

  getUser(kv: keyValue) {
    return kv[2]?.consents != undefined ? kv[2].consents : kv[2]?.email != undefined ? kv[2].email! : undefined;
  }

  haveSymbol(kv: keyValue) {
    return kv[2]?.symbol != undefined;
  }

  appendSymbol(kv: keyValue) {
    return kv[2]?.symbol!;
  }

  isMoney(kv: keyValue) {
    return kv[2]?.money != undefined;
  }

  isConsents(kv: keyValue) {
    return kv[2]?.consents != undefined;
  }

  isClientDob(kv: keyValue) {
    return kv[2]?.dob != undefined;
  }

  isBirthday(kv: keyValue) {
    return kv[2]?.dob?.isDayMonthEquals(new CustomDate());
  }

  isEmailAlways(kv: keyValue) {
    return kv[2]?.email != undefined;
  }

  getClass(kv: keyValue) {
    return kv[2] && kv[2].customClass != undefined ? kv[2].customClass : ''
  }

}
