import { TypePaymentEnum } from "../enums/TypePaymentEnum";

export class M_TypePayment {
    id: TypePaymentEnum;
    payment: string;
    company_id: number;
    deleted: number;
    constructor(d: any) {
        this.id = d.id;
        this.payment = d.payment;
        this.company_id = d.company_id;
        this.deleted = d.deleted;

    }

    get isCash() {
        return this.id && this.id == TypePaymentEnum.CASH;
    }
    get isCard() {
        return this.id && this.id == TypePaymentEnum.CARD;
    }
    get isBizum() {
        return this.id && this.id == TypePaymentEnum.BIZUM;
    }
    get isTransference() {
        return this.id && this.id == TypePaymentEnum.TRASNFERENCE;
    }
    get isCustom() {
        return !this.id || this.id > TypePaymentEnum.CUSTOM;
    }

    get icon() {
        switch (this.id) {
            case TypePaymentEnum.CASH:
                return "payments";
            case TypePaymentEnum.CARD:
                return "credit_card";
            case TypePaymentEnum.BIZUM:
                return "bizum";
            case TypePaymentEnum.TRASNFERENCE:
                return "sync_alt";
            default:
                return "label"
        }
    }
}