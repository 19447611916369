<div (click)="parent.setSelected(this.index, true)" [ngClass]="selected ? 'up' : blackWitheFilter ? 'no-up bw' : 'no-up'"
    [style]="'cursor: '+(locked ? 'initial' : 'pointer')">
    <div *ngIf="img; else matIconContent">
        <img *ngIf="dimensions == undefined" src="{{img}}" class="toggle-image img-no-events w100">
        <img *ngIf="dimensions != undefined" src="{{img}}" class="toggle-image img-no-events" [width]="dimensions"
            [height]="dimensions">
    </div>
    <p [ngClass]="selected ? 'selected tac' : 'tac'">
        {{text}}
        <mat-icon class="c_g" *ngIf="locked">lock</mat-icon>
    </p>
    <div class="height4">
        <div [ngClass]="selected ? 'selected-underline' : 'no-selected-underline'"></div>
    </div>
</div>

<ng-template #matIconContent>
    <div class="icon-content">
        <ng-content></ng-content>
    </div>
</ng-template>