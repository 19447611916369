import { Component, OnInit, ViewChild } from '@angular/core';
import { or_status, Filter, DayFilter, SliderFilter, TagFilter, FilterOption, action_type_budget, PageStructureComponent, getAccentColor, getWarnColor } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_Invoice } from 'src/app/core/models/M_Invoice';
import { ParamsService } from 'src/app/core/services/params.service';
import { PreviewService } from 'src/app/core/services/preview.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

export enum BudgetPageFiltesrEnum {
  BUDGET_DELIVEY = 0,
  BUDGET_TOTAL = 1,
  BUDGET_STATUS = 2,
}

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit {

  accent = getAccentColor;
  warn = getWarnColor;

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;
  @ViewChild(PageStructureComponent) ps2!: PageStructureComponent<M_Invoice>;

  v = ViewPath;
  f = filter;
  bills: M_Invoice [] = [];


  sf = new SliderFilter("Total", 500).setId(BudgetPageFiltesrEnum.BUDGET_TOTAL);
  filters: Filter[] = [
    new DayFilter("Fecha creación").setId(BudgetPageFiltesrEnum.BUDGET_DELIVEY),
    this.sf,
    new TagFilter("Estado", or_status, new FilterOption("Abierto"), new FilterOption("Cerrado")).setId(BudgetPageFiltesrEnum.BUDGET_STATUS),
  ]
  
  constructor(public params: ParamsService,public apiS: ApiService, private previewS: PreviewService, public subS : SubscriptionService) {
    apiS.actions(action_type_budget).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        
        this.sf.changeMax(Math.max(...resp.map(o => o.total || 0)))
        let a: any = this.apiS.bills().then((data:any) => {
          // Aquí puedes acceder a los datos
          this.bills = data
          this.ps.initTable(resp);

        }); 
      }
    });
  }
  

  ngOnInit(): void {

  }


  previsualize(bill: M_Action) {
    this.previewS.showPreview("B", bill.token, undefined, bill.title_id);
  }

  reSendBudget() {
    return this.apiS.closeBudget(this.ps.selected!.id)
  }


  client(action : M_Action) { return action.client?.getName()}
  state(action: M_Action) {return action.status.name;}
  type( action  : M_Action){ return action.getStrTypes();}
  stateColor(action: M_Action) { return action.getStateColor()}
  
  handleClick(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) { // Verifica si la tecla Control o Command está presionada
      event.preventDefault(); // Prevenir el comportamiento por defecto
      this.openInNewTab();
    } else {
      this.navigate();
    }
  }
  handleContextMenu(event: MouseEvent,) {
    event.preventDefault(); // Prevenir el menú contextual por defecto
    this.openInNewTab();
  }
  openInNewTab() {
    const url = "createeditbudget?budget="+this.ps.selected!.id;
    window.open(url, '_blank');
  }
  navigate(){
    this.params.go(this.v.createEditBudget, this.ps.selected!.id)
  }
}
