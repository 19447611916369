import { Injectable } from '@angular/core';
import { ConfirmDialogService, RoleService, StorageService } from '@sinigual/angular-lib';
import { Subject } from 'rxjs';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { VehicleType } from 'src/app/core/enums/VehicleType';


export const DEFAULT_IVA = 21;


/**
 * User Service. Controls the following varibales :
 * <br>  
 *  - The logged user id
 * </br>
 * <br>  
 *  - The first user login
 * </br>
 * <br>
 *  - The user profile picture
 * </br>
 * <br>  
 *  - If the user can invoice.
 * </br>
 * <br>  
 *  - The workload tutorial 
 * </br>
 */

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  /** Boolean that determinates if the user can invoice or not */
  missingCompanyInfo = false;
  /** Subject for notify when the user changes the profile image. 
   * All the components than need te user progile image must subscribe this subject events 
  /** String to know if the workload tutorial needs to be showed or not */
  showed_workload_key = "showed_workload";
  /** Determinates if is the user first login */
  first_login_key = "first_login";
  /** Determinates if is the first login of the company */
  first_login_company = "first_login";
  /** The company tax */
  company_tax_key = "company_tax"
  /** Default creation vehicle */
  default_vehicle_key = "default_vehicle";
  /** If the user is the company creator? */
  is_admin_key = "is_admin";

  onMissingCompanyInfo: Subject<boolean> = new Subject();

  constructor(private confirmD: ConfirmDialogService, private storageS: StorageService, private roleS: RoleService) { }

  /** Tracks if the workload dialog (drag tutorial) is already showed */
  get workloadShowed(): boolean {
    var value = this.storageS.getBoolean(this.showed_workload_key);
    return value == null ? false : value;
  }


  /** Return if is the user is the company creator */
  get isAdmin(): boolean {
    return this.roleS.getRole() == RolesEnum.ADMIN;
  }

  /** Return if is the user first login */
  get firstLogin(): boolean {
    var value = this.storageS.getBoolean(this.first_login_key);
    return value == null ? false : value;
  }

  /** Return if is the first company login */
  get firstCompanyLogin(): boolean {
    var value = this.storageS.getBoolean(this.first_login_company);
    return value == null ? false : value;
  }

  /** Returs a boolen indicating if the user can invoice or is missing company info */
  get needToShowWorkloadDialog() {
    return !this.workloadShowed && this.firstLogin;
  }


  /** General company tax on local storage. By default 21 */
  get companyTax() {
    var tax = this.storageS.get(this.company_tax_key);
    if (tax != null && tax.isNumber()) {
      return tax.getNumber();
    }
    return DEFAULT_IVA;
  }

  /** Update the local storage company tax value */
  updateCompanyTax(v: number | undefined) {
    if (v != undefined) {
      this.storageS.save(this.company_tax_key, v);
    }
  }

  updateDefaultVehicle(vt: VehicleType) {
    if (vt) {
      this.storageS.save(this.default_vehicle_key, vt);
    }
  }

  setIsAdmin(v: boolean) {
    this.roleS.setRole(v ? RolesEnum.ADMIN : RolesEnum.BASIC);
  }

  setWorkloadShowed(v: boolean) {
    this.storageS.save(this.showed_workload_key, v);
  }

  setFirstLogin(v: boolean) {
    this.storageS.save(this.first_login_key, v);
  }

  setFirstCompanyLogin(v: boolean) {
    this.storageS.save(this.first_login_company, v);
  }

  setMissingCompany(value : boolean) {
    this.missingCompanyInfo = value;
    this.onMissingCompanyInfo.next(value);
  }

  /** Generic error dialog indicating that user needs to introudce more info company*/
  showMissingCompany() {
    this.confirmD.showError("Todavía no se puede facturar", "Antes de poder facturar, debes rellenar toda la información sobre tu empresa");
  }

}
