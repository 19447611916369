import { TextWithLink } from "./TextWithLink";

export class UserOpinion {
    constructor(public opinion: string, public img: string, public name: string, public reviewLink: string, public position: TextWithLink) { }
}


export const user_opinion_1 = new UserOpinion(
    "Muy buen producto. A destacar el servicio de soporte al cliente, excelente atención! Siempre bien predispuestos, con paciencia y toda la amabilidad te ayudan a resolver cualquier duda que se pueda presentar.",
    "./assets/img/trusted-users/elliot.png",
    "Elliot Motors S.L",
    "https://www.google.com/maps/reviews/@41.5885523,1.6092908,17z/data=!3m1!4b1!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUNPNzVDWGp3RRAB!2m1!1s0x0:0x9ebf8173d049708f?hl=es",
    new TextWithLink(["Usuario de", "Siniwin"], "https://www.sinigual.com/siniwin")
)

export const user_opinion_2 = new UserOpinion(
    "Buenos programas para el sector de la automoción. Y muy buenos professionales",
    "./assets/img/trusted-users/joan.jpg",
    "Joan Carles Cervantes",
    "https://g.co/kgs/i5vT1Q",
    new TextWithLink(["Usuario de la aplicación"], "")
)

export const user_opinion_3 = new UserOpinion(
    "Una decada trabajando con ellos y volveria a repetir una decada mas . Profesionales , serios y resolutivos . Gracias a todo el equipo",
    "./assets/img/trusted-users/arimon.jpg",
    "Motos Arimón",
    "https://g.co/kgs/c8bxjX",
    new TextWithLink(["Usuario de", "Siniwin"], "https://www.sinigual.com/siniwin/")
)