import { Component, OnInit } from '@angular/core';
import { endpoints } from '../../api/Enpoints';
import { feature } from '../../features-controller/FeaturesController';
import { getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.css']
})
export class DataUploadComponent implements OnInit {
  accent = getAccentColor;
  warn = getWarnColor;
  feature = feature;
  endpoint = endpoints;
  constructor() { }
  ngOnInit(): void {}
}
