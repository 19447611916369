import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService, CustomDate, CustomFile, ParameterStateComponent, RouterService } from '@sinigual/angular-lib';
import { MY_STRING } from 'src/app/app.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_ActiveReception } from 'src/app/core/models/M_ActiveReception';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
  template: ``
})

export class ActionCreateEditParentComponent extends ParameterStateComponent {

  actionLoaded = false;
  actionFailedToLoad = false;
  action: M_Action | undefined;
  startDate: CustomDate | undefined;
  v = ViewPath;

  constructor(public apiS: ApiService, routerS: RouterService, route: ActivatedRoute, @Inject(MY_STRING) parameters: string[], public d: MatDialog, public confirmD: ConfirmDialogService) {
    super(routerS, route, parameters);
  }

  override onParam(k: string, v: any) {
    if (k == "or" || k == "budget") {
      this.apiS.getAction(v).then(res => {
        if (res != undefined && res instanceof M_Action) {
          this.action = res;
          console.log(this.action);
          this.actionLoaded = true;
          this.fillViewWithAction(this.action);
        }
        else {
          this.actionFailedToLoad = true;
        }
      })
    }
  }

  deleteAction() {
    if (this.action != undefined) {
      let title_ = this.action.isOr() ? "Eliminar OR" : "Eliminar presupuesto"
      let body_ = this.action.isOr() ? '¿Está seguro de que desea eliminar esta orden de reparación?' : '¿Está seguro de que desea eliminar este presupuesto?'
      this.confirmD.show({ title: title_, body: body_, type: "danger" }).afterClosed().subscribe(res => {
        if (res == true) {
          this.apiS.rmAction(this.action!.id).then(res => {
            if (res == "success") {
              if (this.action) {
                this.action.deleted = true; // avoid showing confirm save dialog on exit the page
              }
              this.routerS.back();
            }
          });
        }
      })
    }
  }

  /** To implement on child */
  fillViewWithAction(_a: M_Action) { }

  /** To implement on child */
  fillSchedule(_a: CustomDate) { }

  uploadImage(or: M_Action | M_ActiveReception, ...cf: CustomFile[] | File[]) {
    if (cf) {
      cf.forEach(cf => {
        this.apiS.uploadImage(cf, "action_image", ["action_id", or.id!], ["client_id", or!.client_id], ["vehicle_id", or!.vehicle_id]).then(res => {
          if (res.image_id && cf instanceof CustomFile) {
            cf.database_id = res.image_id;
          }
        })
      })
    }
  }

}
