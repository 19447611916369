<div class="transition-appear maxw" *ngIf="client; else noData">

    <div class="df fdc aic jcc">

        <div [ngClass]="{'cp' : !client.deleted}" (click)="goClient()"
            [matTooltip]=" client.deleted? '' : 'Ir a detalles avanzados del cliente'">
            <div class="df">
                <lord-icon *ngIf="lord && !client.is_company" src="https://cdn.lordicon.com/fmasbomy.json"
                    trigger="loop" delay="9999" colors="primary:#121331,secondary:{{warn()}},tertiary:#fae6d1"
                    state="hover-looking-around" [ngClass]="small ? 'lord-medium' : 'lord-big'">
                    <app-user-email-always class="email-relative" [client]="client"></app-user-email-always>
                </lord-icon>
                <lord-icon *ngIf="lord && client.is_company" src="https://cdn.lordicon.com/waxtfsst.json" trigger="loop"
                    delay="9999"
                    colors="primary:#121331,secondary:{{accent()}},tertiary:#3a3347,quaternary:#ebe6ef,quinary:#f24c00"
                    [ngClass]="small ? 'lord-medium' : 'lord-big'">
                    <app-user-email-always class="email-relative" [client]="client"></app-user-email-always>
                </lord-icon>
            </div>
        </div>
        <p *ngIf="client.deleted" class="c_r fw600">¡Cliente eliminado!</p>

        <app-card-subtitle class="title tac discount-subtitle" [first]="true" [noMarginBottom]="true">
            {{client.getName()}}<app-selected-and-input
                *ngIf="client.discount"></app-selected-and-input></app-card-subtitle>
        <p class="fsm nmb fw300" style="letter-spacing: 2px">{{client.nif.toUpperCase()}}</p>
        <button color="blue" mat-button [matMenuTriggerFor]="menu">
            <span>Más información</span>
        </button>
        <mat-menu #menu="matMenu" class="card-row-menu">
            <app-card-row-content [data]="[
                ['Email', client.email],
                ['Teléfono', client.phone],
                ['Dirección', client.getLocation()]]">
            </app-card-row-content>
            <!--['Consentimientos', undefined , {consents : client}]!-->
        </mat-menu>
    </div>
</div>

<ng-template #noData>
    <p class="c_t2">Al seleccionar un cliente, en esta sección se mostrarán más detalles sobre él.</p>
</ng-template>