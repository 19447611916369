import { Component } from '@angular/core';
import { TextWithLink } from '../../custom-classes/TextWithLink';
import { CustomDate } from '@sinigual/angular-lib';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  privacity = new TextWithLink("Política de privacidad", "https://www.sinigual.com/politica-de-privacidad");
  advice = new TextWithLink("Aviso legal", "https://www.sinigual.com/aviso-legal");
  d = new CustomDate().value.getFullYear();
  constructor() { }
}
