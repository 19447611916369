import { Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { or_types } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';
import { M_Action } from '../../models/M_Action';
import { M_CustomProduct } from '../../models/M_CustomProduct';
import { M_GroupTask } from '../../models/M_GroupTask';
import { M_Product } from '../../models/M_Product';
import { DragGroupComponent } from './drag-group/drag-group.component';

@Component({
  selector: 'app-drag',
  templateUrl: './drag.component.html',
  styleUrls: ['./drag.component.css']
})
export class DragComponent implements OnInit {
  @Input() action?: M_Action;
  @Input() canModify?: boolean;
  @Input() abono: boolean = false;
  @Input() isBudgetPage: boolean = false;
  @Input() locked: boolean = false;
  @Output() onInvoiceChildTask: EventEmitter<M_GroupTask> = new EventEmitter();
  @Output() onSomeGroupChanges: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveChildGroupTask: EventEmitter<any> = new EventEmitter();

  @ViewChildren(DragGroupComponent) groupsComponents?: QueryList<DragGroupComponent>;
  product_list: M_Product[] = [];
  dragLoaded = false;

  constructor(private apiS: ApiService) {
    this.apiS.products().then(res => {
      this.product_list = res;
      this.dragLoaded = true;
    })
  }

  /** Shortcut CTRL + SPACE 
   * Add a product on some open group
  */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === ' ') {
      //Find the open groups
      let openGroups = this.groupsComponents?.filter(g => g.group.state.open);
      if (openGroups) {
        // If there is only on opened group... add the product
        if (openGroups.length == 1) { 
          openGroups[0].appendProduct(); 
          return;
        }
        // Then check for groups with no tasks
        let noTasksGroup = openGroups.find(g => g.tasksComponent?.length == 0);
        if (noTasksGroup) {
          noTasksGroup.appendProduct(); // Append prodtuct to the group with no tasks
          return;
        }
        // Add a new product on the frist open group
        this.groupsComponents?.find(g => g.group.state.open)?.appendProduct();
      }
    }
  }

  ngOnInit(): void { }


  /** Save all the group components. Returns a promise with a boolean */
  saveAll(): Promise<boolean> {
    return new Promise(resolve => {
      if (!this.groupsComponents == undefined) { resolve(true); }
      this.groupsComponents?.forEach((g, index) => {
        /** On the last item, await the response and resolve the promise */
        if (index == this.groupsComponents!.length - 1) {
          resolve(g.saveChanges(undefined, "by-general"))
        }
        else {
          //Avoid showing 'Cambios guardados' snack multiple times
          g.saveChanges(undefined, "by-general");
        }
      })
    })

  }

  addNew() {
    if (this.action) {
      /** OR */
      if (this.action.action_id) {
        this.apiS.addEditGroup(this.action.id, undefined, "Título de la intervención", new or_types("Normal")).then(res => {
          this.action!.addGroupTask(new M_GroupTask({
            id: res
          }))
        })
      }
      else {
        //this.apiS.createOR
        alert("no implemented")
      }

    }
  }

  /** ????  */
  onDrop(_e: any) {
    this.action?.groups.forEach(_g => {
      //g.onDragAndDropReorder();
    })
  }

  onRemoveGroup(g: M_GroupTask) {
    this.onRemoveChildGroupTask.emit(g);
    this.action?.refreshType();
  }

  onStatusChange(_e: any) {
    this.action?.refreshStatus();
  }

  onTypeChange(_e: any) {
    this.action?.refreshType();
  }

  onInvoiceTask(tipo: M_GroupTask) {
    this.onInvoiceChildTask.emit(tipo);
  }

  canModifyAction() {
    if (this.action?.isBudget()) {
      return !this.action.isClosed();
    }
    else if (this.action?.isOr()) {
      return !this.action.allInvoiced()
    }
    return false;
  }

  hasChanges() {
    if (!this.groupsComponents) { return false }
    return this.groupsComponents.toArray().some(g => {
      return g.hasChanges()
    })
  }

  /** Get the aboned products / custom products */
  get abonedProducts() {
    var abonedProducts: (M_Product | M_CustomProduct)[] = [];
    this.groupsComponents?.forEach(group => {
      group.tasksComponent?.forEach(task => {
        if (task.apc!.p!.aboned) {
          abonedProducts.push(task.apc!.p!);
        }
      })
    });
    return abonedProducts;
  }

  getOthersStockOf(p: M_Product, gt: M_GroupTask) {
    let total = 0;
    this.groupsComponents?.forEach(g => {
      if (g.group.id != gt.id) {
        g.tasksComponent?.forEach(t => {
          if (t.apc && t.apc.p && t.apc.p instanceof M_Product && t.apc.p.product_id == p.product_id) {
            total += t.apc.getStockSubstraction();
          }
        })
      }
    })
    return total;
  }



}
