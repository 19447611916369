import { or_status, Filter, SliderFilter, TextFilter, DayFilter, EnumFilter, TagFilter, UserFilter } from "@sinigual/angular-lib";
import { BillPageFiltesrEnum } from "src/app/views/bills/bills.component";
import { M_Action } from "../models/M_Action";
import { M_Invoice } from "../models/M_Invoice";
import { M_Product } from "../models/M_Product";
import { M_PayCometInvoice } from "../models/M_PayCometInvoice";
import { ORPageFiltesrEnum } from "src/app/views/or/or.component";
import { BudgetPageFiltesrEnum } from "src/app/views/budget/budget.component";


/** This funcion is the 'filterPredicate' of the page table 
 * Iterates the table and check if the table object match with filtetrs
 * Important : We need the rewtirte the default string filter of the MatTable (the searchbar)
 * On this class, the method rewtited is defaultStrFilter();
*/
export function filter(object: any, ...filters: Filter[]) {
    var fitleredFilters = filters.filter(f => f.activated);  //xd
    if (object instanceof M_Invoice) {
        return filterInvoice(fitleredFilters, object)
    }
    else if (object instanceof M_Product) {
        return filterProduct(fitleredFilters, object)
    }
    else if (object instanceof M_Action) {
        return filterAction(fitleredFilters, object)
    }
    else if (object instanceof M_PayCometInvoice) {
        return filterPayCometInvoice(fitleredFilters, object);
    }

    return false;
}

/** Product filter function */
function filterProduct(filters: Filter[], object: M_Product) {
    var slidersOk = true;
    var textFilter = true;
    var tagFilter = true;

    filters.forEach((filter) => {
        if (filter instanceof SliderFilter && slidersOk) {
            slidersOk = filter.checkbyProduct(object);
        }
        if (filter instanceof TextFilter && textFilter) {
            textFilter = filter.checkbyProduct(object);
        }
        if (filter instanceof TagFilter && tagFilter) {
            textFilter = filter.checkFilter([object.category]);
        }
    })

    return slidersOk && textFilter && tagFilter;
}


/** Invoices filter Funcion */
function filterInvoice(filters: Filter[], object: M_Invoice) {
    var isDayFilterOk = true;
    var isDayFilterPayed = true;
    var isSliderFilterOk = true;
    var isEnumFilterOk = true;
    var isTagFilter1Ok = true;
    var isTagFilter2Ok = true;
    var isTagFilter3Ok = true;
    var isTagFilter4Ok = true;

    filters.forEach(filter => {
        if (filter instanceof DayFilter && isDayFilterOk && filter.id == BillPageFiltesrEnum.INVOICE_CREATED_AT) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof DayFilter && isDayFilterPayed && filter.id == BillPageFiltesrEnum.INVOICE_PAYED_AT) {
            isDayFilterPayed = object.last_movement.some(movement => { return filter.checkFilter(movement.created_at) })
        }
        else if (filter instanceof SliderFilter && isSliderFilterOk) {
            isSliderFilterOk = filter.checkFilter(object.total)
        }
        else if (filter instanceof EnumFilter && isEnumFilterOk) {
            isEnumFilterOk = filter.checkFilter(object.state.isPayed ? 0 : object.state.isPartial ? 2 : 1)
        }

        /** Invoice Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_TYPE && isTagFilter1Ok) {
            if (object.type_invoice != undefined) {
                isTagFilter1Ok = filter.checkFilter([object.type_invoice.num])
            }
            else {
                isTagFilter1Ok = false;
            }
        }

        /** OR Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.OR_TYPE && isTagFilter2Ok) {
            if (object.type_or != undefined) {
                isTagFilter2Ok = filter.checkFilter([object.type_or.num])
            }
            else {
                isTagFilter2Ok = false;
            }
        }
        /** TYPE PAYMENT INVOICE Type */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_TYPE_PAYMENT && isTagFilter3Ok) {
            isTagFilter3Ok = object.last_movement.some(x => {
                return filter.checkFilterByString(x.concept);
            });

        }
        /** TYPE  INVOICE STATE */
        else if (filter instanceof TagFilter && filter.id == BillPageFiltesrEnum.INVOICE_STATE && isTagFilter4Ok) {
            isTagFilter4Ok = filter.checkFilter(object.type_filter());
        }
    })

    return isDayFilterPayed && isDayFilterOk && isSliderFilterOk && isEnumFilterOk && isTagFilter1Ok && isTagFilter2Ok && isTagFilter3Ok && isTagFilter4Ok;
}

/** Filter Action */
function filterAction(filters: Filter[], object: M_Action) {
    return object.isBudget() ? filterBudget(filters, object) : filterOR(filters, object);
}


/** Filter OR function */
function filterOR(filters: Filter[], object: M_Action) {
    var isDayFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var isStatusFilterOk = true; //Screen filters
    var isGroupFilterOk = true; //Screen filters
    var isTypeFilterOk = true; //Screen filters
    var isUserFilterOk = true; //Screen filters
    filters.forEach(filter => {
        if (filter instanceof DayFilter && isDayFilterOk) {
            isDayFilterOk = filter.checkFilter(object.schedule)
        }
        else if (filter instanceof EnumFilter && isEnumFilterOk) {
            isEnumFilterOk = filter.checkFilter(object.status.num)
        }
        else if (filter instanceof TagFilter && isStatusFilterOk && filter.id == ORPageFiltesrEnum.OR_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.status.num])
        }
        else if (filter instanceof TagFilter && isGroupFilterOk && filter.id == ORPageFiltesrEnum.OR_GROUP_STATUS) {
            isGroupFilterOk = object.groups.some(g => { return filter.checkFilter([g.state.num]) })
        }
        else if (filter instanceof TagFilter && isTypeFilterOk && filter.id == ORPageFiltesrEnum.OR_TYPE) {
            isTypeFilterOk = filter.checkFilter(object.type.map(obj => obj.num))
        }
        else if (filter instanceof UserFilter && isUserFilterOk) {
            isUserFilterOk = filter.checkFilter(object.assigned?.id)
        }
    })

    return isDayFilterOk && isEnumFilterOk && isStatusFilterOk && isTypeFilterOk && isGroupFilterOk && isUserFilterOk;
}


/** Filter Budget function */
function filterBudget(filters: Filter[], object: M_Action) {
    var isDayFilterOk = true; //Screen filters
    var isSliderFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var isStatusFilterOk = true; //Screen filters

    filters.forEach(filter => {
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof SliderFilter) {
            isSliderFilterOk = filter.checkFilter(object.total)
        }
        else if (filter instanceof EnumFilter) {
            isEnumFilterOk = filter.checkFilter(object.status.num)
        }
        else if (filter instanceof TagFilter && isStatusFilterOk && filter.id == BudgetPageFiltesrEnum.BUDGET_STATUS) {
            isStatusFilterOk = filter.checkFilter([object.status.num])
        }
    })
    return isDayFilterOk && isSliderFilterOk && isEnumFilterOk && isStatusFilterOk;
}


function filterPayCometInvoice(filters: Filter[], object: M_PayCometInvoice) {
    var isDayFilterOk = true; //Screen filters
    var isEnumFilterOk = true; //Screen filters
    var sliderFilterOk = true; //Screen filters
    filters.forEach(filter => {
        if (filter instanceof DayFilter) {
            isDayFilterOk = filter.checkFilter(object.created_at)
        }
        else if (filter instanceof EnumFilter) {
            isEnumFilterOk = filter.checkFilter(object.payed)
        }
        else if (filter instanceof SliderFilter) {
            sliderFilterOk = filter.checkFilter(object.amount);
        }
    })
    return isDayFilterOk && isEnumFilterOk && sliderFilterOk;
}


/** This function chck if the text introduced on the serachBar of Page-Structure-Component
 *  match with the object that we are checking. We need the rewrite this funcion beacouse we
 *  set a custom fitlerPredicate function.
 */
export function defaultSearchFilter(object: any, searchFilter: string) {
    return searchFilter ? object.defaultSearchFilter(searchFilter) : true;
}

