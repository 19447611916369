import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterService, SearchService } from '@sinigual/angular-lib';
import { map, Observable, startWith } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../../api/api.service';
import { allColors, AutocompleteColor } from '../../custom-classes/Colors';
import { M_Vehicle } from '../../models/M_Vehicle';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { BrandModelManager } from './BrandModelManager';
import { VehicleType } from '../../enums/VehicleType';
import { ImageToggleComponent } from '../image-toggle/image-toggle.component';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { UserServiceService } from 'src/app/views/profile/user-service.service';
import { DialogConfirmPlateComponent } from './dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicleform',
  templateUrl: './vehicleform.component.html',
  styleUrls: ['./vehicleform.component.css']
})
export class VehicleformComponent implements OnInit {

  client = MASTER_CLIENT_MINIFIY;

  @Input() ve?:M_Vehicle 
  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  v = ViewPath;
  validators = Validators;
  public form: UntypedFormGroup;
  lastb1 = "";
  lastb2 = "";
  Iscreate : boolean = false;
  isEditing: boolean = false;
  vehicleTypeApi:number = 1;
  options: AutocompleteColor[] = allColors;
  initialChasisValue: string | undefined;
  filteredOptions!: Observable<AutocompleteColor[]>;
  loaded = false;
  brandModelManager: BrandModelManager;
  VT = VehicleType;
  minDate:  Date;
  vehicle: M_Vehicle | undefined
  initialInputValue: string ="";

  constructor(private formBuilder: UntypedFormBuilder, private searchS: SearchService, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS : RouterService, private userS : UserServiceService,private d: MatDialog) {
      
    this.form = this.formBuilder.group({
      /** Defined via api on 'CreateVehicleComponent' */
      type: [this.VT.bike, Validators.required],
      brand: ['', []],
      model: ['', []],
      chassis: [''],
      license: ['', [Validators.required]],
      serie: [],
      color: ['', []],
      insurance: ['', []],
      cc: ['', []],
      engine_type: ['', []],
      license_date: ['', []],
      fabrication_date: ['', []],
      hire_date: ['', []],
      km: ['', []],
      last_itv: ['', []],
      next_itv: ['', []],
      electric: ['', []],
      battery_1_no: ['', []],
      battery_2_no: ['', []],
      clientInvoice_id : ['',[]],

    });

    this.minDate = new Date();
    this.form.get("battery_1_no")?.disable();
    this.form.get("battery_2_no")?.disable();
    

    /** When the engine_type 'select' changes, diable or enable some fields */
    this.form.get('engine_type')?.valueChanges.subscribe(val => {
      let b1 = this.form.get("battery_1_no")!;
      let b2 = this.form.get("battery_2_no")!;
      /** If the motor type is gas, disable the electric vehicle fields */
      if (val == 0) {
        
        this.lastb1 = b1.value;
        b1.setValue('');
        b1.disable();
        this.lastb2 = b2.value;
        b2.disable();
        b2.setValue('');
      }
      else {
        b1.enable();
        b1.patchValue(this.lastb1)
        b2.enable();
        b2.patchValue(this.lastb2)
      }
    })
    

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.form.patchValue({ serie: null });
    })
    /** Brand model init */
    this.brandModelManager = new BrandModelManager(this.form, apiS, searchS, chdRef);
    this.brandModelManager.initByVehicleType(this.form.get("type")?.value).then(res => {
      this.loaded = true;
    })
    
  }
  enableEditing() {
    this.isEditing = true;
    this.form.get('license')?.enable();
    this.initialInputValue = this.form.get('license')?.value || ''; // Guardar el valor inicial
    if(this.Iscreate != true){
      this.d.open(DialogConfirmPlateComponent,{data:{
        title: "¡Atención Edición de matrícula!",
        message: "¿Estas seguro que quieres editar la matrícula?",
      }}).afterClosed().subscribe(res=>{
        if(res != true){
          this.form.get('license')?.disable();
          
        }else{
          this.Iscreate = true;
        }
      }
      );
    }
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  ngOnInit(): void {
    this.filteredOptions = this.form.get('color')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  onBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    console.log('El foco ha salido del input:', inputElement.value);
    let plate = this.form.get('license')!;
    if (plate.value === this.initialInputValue) {
      console.log('El valor del input no ha cambiado.');
      // Aquí puedes agregar la lógica que necesites para manejar el cambio
      
    } else {
      this.apiS.checkPlate(plate.value).then(res => {
        if (res instanceof M_Vehicle) {
          
            this.existsS.show({
              title: "¡Atención!",
              message: "Ya existe un vehículo con la matrícula",
              message2: "Recordamos que no se pueden crear dos vehículos con la misma matrícula",
              value: plate.value,
              view: this.v.vehicleDetails,
              param_id: res.vehicle_id,
              accept_text: "Ver vehículo"
            })
            this.form.get('license')?.hasError('license-exists');
        }
      })
      
      console.log('El valor del input ha cambiado:', plate.value);
    }
  }
  checkPlate() {
    let plate = this.form.get('license')!;
    
      this.apiS.checkPlate(plate.value).then(res => {
        if (res instanceof M_Vehicle) {
          
          this.existsS.show({
            title: "¡Atención!",
            message: "Ya existe un vehículo con la matrícula",
            message2: "Recordamos que no se pueden crear dos vehículos con la misma matrícula",
            value: plate.value,
            view: this.v.vehicleDetails,
            param_id: res.vehicle_id,
            accept_text: "Ver vehículo"
          })
        }
      })
  }

  checkChasis() {
    let chassis = this.form.get('chassis')!;
    if (chassis.valid && chassis.value && (!this.initialChasisValue || (this.initialChasisValue != chassis.value))) {
      this.apiS.checkChasis(chassis.value).then(res => {
        if (res instanceof M_Vehicle) {
          this.existsS.show({
            title: "¡Atención!",
            message: "Ya existe un vehículo con el chasis",
            message2: "Recordamos que no se pueden crear dos vehículos con el mismo chassis",
            value: chassis.value,
            view: this.v.vehicleDetails,
            param_id: res.vehicle_id,
            accept_text: "Ver vehículo"
          })
        }
      })
    }
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createClient);
  }

  private _filter(value: string): AutocompleteColor[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
