import { Component, Input} from '@angular/core';
import { M_Client } from '../../models/M_Client';
import { getAccentColor, getWarnColor } from '@sinigual/angular-lib';

@Component({
  selector: 'app-user-email-always',
  templateUrl: './user-email-always.component.html'
})
export class UserEmailAlwaysComponent {
  @Input() client! : M_Client | undefined;
  accent = getAccentColor;
  warn = getWarnColor;
  ngAfterContentInit(){}
}
