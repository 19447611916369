<div>
    <mat-toolbar color="primary">
        <button #btn (click)="onclick.emit();" onclick="this.blur()" mat-icon-button class="df aic cp br5" [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
            <mat-icon style="color: white;">{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
        </button>
        <img (click)="routerS.goTo(v.dashboard)" class="logoToolbar cp" src="./assets/img/logos/eina_logo.png">
        <!-- <button color="warn" *ngIf="u?.is_admin" mat-flat-button class="buttonCode" matBadge="!" matBadgeSize="small" matBadgeColor="accent" (click)="openDiscount()">+1 mes gratis!</button> -->
        <div class="gradient-parent2 buttonCode" (click)="openDiscount()" [matTooltip]="'Haz click para más información'">
            <div class="gradient-border2">+1 mes gratis!</div>
        </div>
        <app-big-mama *ngIf="f.bigMama" class="gsearcher" (onclick)="onclick.emit();"></app-big-mama>
        <span class="example-spacer"></span>
        
        <button class="help onboarading-help-toolbar" mat-icon-button (click)="onboarding.open()">
            <mat-icon>help</mat-icon>
        </button>

        <app-notifications 
            [class_]="'mr-20'"
            [autoUpdate]="false"
            [getAll]="apiS.getNotifications.bind(apiS)"
            [readAll]="apiS.readAllNotifications.bind(apiS)"
            [setReaded]="apiS.setNotificationReaded.bind(apiS)"
            (onClickNotification)="mc.close(); notificationAction($event)">
        </app-notifications>

        <button mat-icon-button (click)="goProfile()">
            <app-circular-letter class="absolute-center"  *ngIf="u; else loadingProfile" [checkIsMe]="false" [showTooltip]="false"  [user]="u" [showFullName]="false"></app-circular-letter>
            <ng-template #loadingProfile>
                <app-skeleton class="absolute-center" [circle]="true" [transparency]="true" [height]="34" [width]="34"></app-skeleton>
            </ng-template>
        </button>

    </mat-toolbar>
</div>