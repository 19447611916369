import { ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImgToggleItemComponent } from './img-toggle-item/img-toggle-item.component';

@Component({
  selector: 'app-image-toggle',
  templateUrl: './image-toggle.component.html',
  styleUrls: ['./image-toggle.component.css']
})
export class ImageToggleComponent implements OnInit {
  @ContentChildren(ImgToggleItemComponent, { descendants: true }) items!: QueryList<ImgToggleItemComponent>;
  @Input() dimensions?: number;
  @Input() multiple?: boolean = false;
  @Input() blackWitheFilter?: boolean = false;
  @Input() default: boolean = true;
  @Input() form!: UntypedFormGroup;
  @Input() formCName!: string;
  @Input() updateForm: boolean = true;
  @Output() onToggle : EventEmitter<number> = new EventEmitter();
  height = 0;

  constructor(private chdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initComponenet();
    this.form.get(this.formCName)?.valueChanges.subscribe(_v => {
      this.checkFormInput();
    })
  }

  ngAfterViewChecked() {
    this.chdRef.detectChanges();
  }

  private initComponenet() {
    this.setIndex();
    this.initDimensions();
    this.initBlackWitheFilter();
    this.initDefault();
  }

  checkFormInput() {
    let v = this.form.get(this.formCName)!.value;
    if (v) {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items.get(i)!;
        if (item!.text == v || item.index == v) {
          this.items.get(i)!.startSelected = true;
          this.chdRef.detectChanges();
        }
      }
    }
    this.initDefault();
    this.chdRef.detectChanges();
  }


  private initDefault() {

    let val = this.form.get(this.formCName)?.value;

    if (val != null && val != undefined && typeof val == "number") {
      this.setSelected(val);
    }
    else {
      let found = false;
      this.items.forEach(i => {
        if (i.startSelected) {
          found = true;
          this.setSelected(i.index)
        }
      })

      if (!found && this.default) {
        this.setSelected(0);
      }
    }


  }


  private initDimensions() {
    if (this.dimensions != undefined) {
      this.items.forEach(i => {
        i.setDimensions(this.dimensions!);
      })
    }
  }

  private initBlackWitheFilter() {
    if (this.blackWitheFilter) {
      this.items.forEach(i => {
        i.blackWitheFilter = this.blackWitheFilter!;
      })
    }
  }

  private setIndex() {
    this.items.forEach((i, index) => {
      i.setIndex(index) // --> Set index
    })
  }

  setSelected(item: number, byUser = false) {
    if (!this.multiple) {
      this.items.forEach(i => {
        i.setSelected(false);
      })
    }
    if (this.items.get(item) != undefined) {
      if (this.multiple) {
        this.items.get(item)?.setSelected(!this.items.get(item)?.selected, byUser);
      }
      else {
        this.items.get(item)?.setSelected(true, byUser);
      }
    }
  }

  value() {
    if (this.multiple) {
      let selected = [];
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items.get(i)!;
        if (item.selected) {
          if (item.formValue != undefined) {
            selected.push(item.formValue);
          }
          else {
            return item.index;
          }
        }
      }
      return selected;
    }
    else {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items.get(i)!;
        if (item.selected) {
          if (item.formValue != undefined) {
            return item.formValue;
          }
          else {
            return item.index;
          }
        }
      }
    }
    return undefined;
  }
}
