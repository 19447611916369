import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Invoice } from 'src/app/core/models/M_Invoice';

@Component({
  selector: 'app-pending-payment-dialog',
  templateUrl: './pending-payment-dialog.component.html',
  styleUrls: ['./pending-payment-dialog.component.css']
})
export class PendingPaymentDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Client, public dialogRef: MatDialogRef<PendingPaymentDialogComponent>, private routerS: RouterService) { }
  goInvoice(invoice: M_Invoice) {
    this.routerS.goToWithState(ViewPath.facturas, invoice.id);
    this.dialogRef.close();
  }
}
