import { Component, OnInit, ViewChild } from '@angular/core';
import { action_type_or, DayFilter, Filter, FilterOption, getPrimaryColor, getWarnColor, or_status, or_types, PageStructureComponent, TagFilter } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_Company } from 'src/app/core/models/M_Company';
import { M_Smpt } from 'src/app/core/models/M_Smpt';
import { ParamsService } from 'src/app/core/services/params.service';
import { PreviewService } from 'src/app/core/services/preview.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

export enum ORPageFiltesrEnum {
  OR_DELIVEY = 0,
  OR_STATUS = 1,
  OR_GROUP_STATUS = 2,
  OR_TYPE = 3
}

@Component({
  selector: 'app-or',
  templateUrl: './or.component.html',
  styleUrls: ['./or.component.css']
})
export class OrComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;
  v = ViewPath;
  f = filter

  filters: Filter[] = [
    new DayFilter("Fecha entrega").setId(ORPageFiltesrEnum.OR_DELIVEY),
    new TagFilter("Estado general OR", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada")).setId(ORPageFiltesrEnum.OR_STATUS),
    new TagFilter("Estado intervenciones", or_status, new FilterOption("Abierta"), new FilterOption("Cerrada"), new FilterOption("Facturada")).setId(ORPageFiltesrEnum.OR_GROUP_STATUS),
    new TagFilter("Tipo", or_types, new FilterOption("Normal"), new FilterOption("Interno", undefined, !feature.cargoInterno), new FilterOption("Garantia", undefined, !feature.garantia), new FilterOption("Siniestro"), new FilterOption("Sin tipo")).setId(ORPageFiltesrEnum.OR_TYPE)
  ]

  constructor(public params: ParamsService, public apiS: ApiService, public subS: SubscriptionService, private previewS: PreviewService) {
    apiS.actions(action_type_or).then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  
  }

  previsualize(or: M_Action) {
    this.previewS.showPreview("RA", or.token, undefined, or.id);
  }


  ngOnInit(): void { }
  license(action: M_Action) { return action.vehicle?.license; }
  brand(action: M_Action) { return action.vehicle ? action.vehicle.getName(false) : ''; }
  state(action: M_Action) { return action.status.name; }
  //type(action: M_Action) { return action.getStrTypes(); }
  //actionType(action: M_Action) { return action.getTypeColor() }
  delivery(action: M_Action) { return action.delivery ? action.delivery.dayMonthYearFormat : "" }
  stateColor(action: M_Action) { return action.getStateColor() }

  async pickUpMail() {
    if (this.ps.selected && this.ps.selected.client) {
      const { id, client: { name, email }, company_id, vehicle } = this.ps.selected;
      if (id && name && email && company_id && vehicle) {
        this.apiS.pickUpMail(id, name, email, company_id, vehicle);
      }
    }
  }
  reSendOR = () => {
    if (this.ps.selected && this.ps.selected.client) {
      let client = this.ps.selected.client;
      let group_ids = this.ps.selected.groups.map(group => group.id);
      let comments = '';
      let date = this.ps.selected.created_at;
      let last_movement = this.ps.selected.client.last_movement

      return this.apiS.invoiceOR(group_ids, client, comments, last_movement, date);
    }
    return Promise.resolve(null);
  }
  handleClick(event: MouseEvent,type?:string) {
    if (event.ctrlKey || event.metaKey) { // Verifica si la tecla Control o Command está presionada
      event.preventDefault(); // Prevenir el comportamiento por defecto
      this.openInNewTab();
    } else {
      this.navigate();
    }
  }
  handleContextMenu(event: MouseEvent,) {
    event.preventDefault(); // Prevenir el menú contextual por defecto
    this.openInNewTab();
  }
  openInNewTab() {
    const url = "editor?or="+this.ps.selected!.id;
    window.open(url, '_blank');
  }
  navigate(){
    this.params.go(this.v.editOr, this.ps.selected!.id)
  }
}
