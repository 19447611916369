import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RoleService, RouterService, SessionService, Views } from '@sinigual/angular-lib';
import { Observable } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { PreviousRouteService } from 'src/app/previous-route.service';
import { TryProDialogComponent } from '../components/try-pro-dialog/try-pro-dialog.component';
import { SubscriptionService } from '../services/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscribedUserGuard  {
  constructor(private subS: SubscriptionService, private d: MatDialog, private roleS: RoleService,
    private routerS: RouterService, private sessionS: SessionService, private prevRouteS: PreviousRouteService) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    /** If ther is no session, go to login */
    if (!this.sessionS.hasSession()) {
      this.routerS.goTo(ViewPath.login);
      return false;
    }

    /** Make call */
    if (!this.subS.subscriptionDefined) {
      return this.subS.hasProPermissionsAsync();
    }
    else {
      /** Already maked call */
      var hasProPermisisons = this.subS.hasProPermissions;
      if (!hasProPermisisons) {
        var v: Views | undefined = this.roleS.getViewFromRoute("/" + route.url.toString());
        this.d.open(TryProDialogComponent, { autoFocus: false, data: v ? v.title : undefined })
        if (this.prevRouteS.getPreviousUrl() == undefined) {
          this.routerS.goTo(ViewPath.dashboard);
        }
      }
      return hasProPermisisons;
    }

  }

}
