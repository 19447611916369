import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlreadyExistInterface } from 'src/app/core/services/already-exists.service';

export interface DialogConfirmPlatetInterface {
  title: string;
  message: string;
  message2: string;
  error:boolean;
  param:string;
}
@Component({
  selector: 'app-dialog-confirm-plate',
  templateUrl: './dialog-confirm-plate.component.html',
  styleUrls: ['./dialog-confirm-plate.component.css']
})
export class DialogConfirmPlateComponent {
constructor(public dialog: MatDialogRef<boolean>, @Inject(MAT_DIALOG_DATA) public data: DialogConfirmPlatetInterface){

}

ngOnInit(): void { 
}
edit(){
  this.dialog.close(true);
}
cancel(){
  this.dialog.close(false);
}
}
