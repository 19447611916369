
<app-close-dialog-button ></app-close-dialog-button>
<!-- <div class="bc_r c_w jcc tac fsl title_discount" *ngIf="data[0]?.discount" matTooltip="Descuento General">{{data[0]?.discount}}% DTO</div> -->
<div class="coupon-container" style="margin-top: 3rem; padding: 20px !important;" >
    <div class="coupon-card" matTooltip="Descuento general" *ngIf="data[0]?.discount !=0">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <p class="text-coupon"> {{data[0]?.discount}}%</p>
        <p  class="mt10 c_w dto">DTO</p>
    </div>
<div class="df jcc aic fww">
    <app-client-info [client]="data[0]"></app-client-info>
    <app-vehicle-info [vehicle]="data[1]"></app-vehicle-info>
</div>