import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[DecimalInput]'
})
export class DecimalInput {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let value = inputElement.value;

    // Reemplazar cualquier coma por un punto
    value = value.replace(/,/g, '.');

    // Aplicar la expresión regular para permitir solo números con hasta dos decimales
    const regex = /^-?\d*([,.]?\d{0,2})?$/;
    
    if (!regex.test(value)) {
      // Si la entrada no cumple con el patrón, restaurar el valor anterior
      inputElement.value = inputElement.value.substring(0, inputElement.value.length - 1);
      return;
    }
  }
}