<div class="business-cards">
    <app-card [contentLoaded]="loaded">
        <app-card-title>Configuración de correo electrónico</app-card-title>
        <p>En este apartado puedes configurar tu servidor de correo para que podamos enviar los correos electrónicos a
            partir de los datos que nos proporciones.</p>
        <p>También, podrás configurar el pie de página de los correos.</p>
        <app-layout-send-email [temaplte]="'smtp'"
            [endpoint]="sendTest.bind(this)"></app-layout-send-email>
        <form [formGroup]="form" (ngSubmit)="makeCall()">
            <div class="form onboarding-company-genera-tab-email">
                <app-card-subtitle [first]="true">Información del servidor</app-card-subtitle>
                <div class="w33p">
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Correo</mat-label>
                        <input type="string" matInput placeholder="ej. : usuario@ejemplo.com"
                            formControlName="username">
                    </mat-form-field>
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Contraseña</mat-label>
                        <input type="password" matInput placeholder="Introduce la constraseña"
                            formControlName="password">
                    </mat-form-field>
                    <mat-form-field class="w33" appearance="outline">
                        <mat-label>Puerto</mat-label>
                        <input type="number" matInput placeholder="Puerto" formControlName="port">
                    </mat-form-field>
                </div>
                <div class="df">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Protocolo</mat-label>
                        <mat-select formControlName="protocol">
                            <mat-option [value]="null">Sin protocolo</mat-option>
                            <mat-option value="ssl">SSL</mat-option>
                            <mat-option value="tls">TLS</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Host</mat-label>
                        <input type="string" matInput placeholder="SMTP Host" formControlName="host">
                    </mat-form-field>
                </div>
            </div>
            <div>
                <app-card-subtitle>Contenido correo básico</app-card-subtitle>
                <mat-form-field class="w100" appearance="outline">
                    <mat-label>Pie de página</mat-label>
                    <input type="string" matInput placeholder="Pie de página" formControlName="footer">
                </mat-form-field>
            </div>
            <p class="hint" (click)="goDocuments()">Los colores del correo básico serán los que se definan en el apartado de <span class="underline_hover c_r">Documentos</span></p>
            <div class="df jcc" style="margin-top: 20px;">
                <button appTrialbutton color="warn" class="onboarding-company-genera-tab-email-finish" mat-flat-button
                    type="submit">Guardar</button>
            </div>
        </form>
    </app-card>
</div>