import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RouterService, ResponsiveService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_HistoryAdvances } from 'src/app/core/models/M_HistoryAdvances';
import { M_LastMovements } from 'src/app/core/models/M_LastMovement';
import { M_TypePayment } from 'src/app/core/models/M_TypePayment';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-dialog-history-advances',
  templateUrl: './dialog-history-advances.component.html',
  styleUrls: ['./dialog-history-advances.component.css']
})
export class DialogHistoryAdvancesComponent implements OnInit{
  
  dataSrc: MatTableDataSource<M_HistoryAdvances> = new MatTableDataSource<M_HistoryAdvances>([]);
  dataSrc_copy: MatTableDataSource<M_HistoryAdvances> = new MatTableDataSource<M_HistoryAdvances>([]);
  textoBoton: string = 'Ver más';
  
  v = ViewPath;
  constructor(private chdRef: ChangeDetectorRef,
    public params: ParamsService,
    private routerS: RouterService,
    public responsiveS: ResponsiveService,@Inject(MAT_DIALOG_DATA) public data: { historAdvances:M_HistoryAdvances[]},public dialogRef: MatDialogRef<DialogHistoryAdvancesComponent>){

  }
  ngOnInit(): void {
    this.initTable(this.data.historAdvances);
  }
  get displayedColumns() {
      return this.responsiveS.w > 514
        ? ['Ornumber', 'status', 'factura','delivery']
        : ['Ornumber', 'delivery'];
    }
    initTable(hadv:M_HistoryAdvances[]){
      this.dataSrc = new MatTableDataSource<M_HistoryAdvances>(hadv);
    }
    isHistoryAdvances(val : M_HistoryAdvances):val is M_HistoryAdvances {
      return val instanceof M_HistoryAdvances; 
    }

  goInvoices(element:M_HistoryAdvances){
    if(element.invoices){
      this.routerS.goToWithState(this.v.facturas, element.invoices.id);
      this.dialogRef.close();
    }
    
  }
}
