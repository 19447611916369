import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modify-observations',
  templateUrl: './modify-observations.component.html',
  styleUrls: ['./modify-observations.component.css']
})
export class ModifyObservationsComponent implements OnInit {
  public form: UntypedFormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public ob: string, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      observaciones: [''],
    });
  }
  
  ngOnInit(): void {
    this.form.patchValue({'observaciones' : this.ob})
  }
}
